import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BvlTabContentComponent } from './tab-content/tab-content.component';
import { BvlTabHeaderComponent } from './tab-header/tab-header.component';

@NgModule({
  declarations: [
    BvlTabHeaderComponent,
    BvlTabContentComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    BvlTabHeaderComponent,
    BvlTabContentComponent
  ]
})
export class BvlTabsRouterModule { }
