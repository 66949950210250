import { HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

export class GeneralResponse<T> {
  message: string;
  data: T;

  constructor(data?: T, message?: string) {
    this.message = message;
    this.data = data;
  }

}

export interface IPaginationResponse<T> {
  content: Array<T>;
  first: boolean;
  last: boolean;
  totalElements: number;
  totalPages: number;
}

export interface IObservableArray<T> extends Observable<Array<T>> { }
export interface IObservableVoid extends Observable<any> { }
export interface IBvlObservable<T> extends Observable<GeneralResponse<T>> { }
export interface IBvlObservableEvent<T> extends Observable<HttpEvent<GeneralResponse<T>>> { }
