export interface IGaPropertie {
  control?: string;
  controlAction?: string;
  event?: string;
  category?: string;
  action?: string;
  label?: string;
  value?: string;
  dimentions?: string;
}

export interface IEventCustomGoogleAnalytics {
  ga: IGaPropertie;
  event: Event;
  ctrl: HTMLElement;
  comp: HTMLElement;
  data: any;
}

export interface IEventGoogleAnalytics {
  event?: string;
  category?: string;
  action?: string;
  label?: string;
  value?: string;
  dimension?: any;
}

export class EventGoogleAnalytics {
  public event?: string;
  public category?: string;
  public action?: string;
  public label?: string;
  public value?: string;
  [key: string]: string;

  constructor(_eventGa?: IEventGoogleAnalytics) {
    this.event = _eventGa && _eventGa.event || 'ga_event';
    this.category = _eventGa && _eventGa.category;
    this.action = _eventGa && _eventGa.action;
    this.label = _eventGa && _eventGa.label;
    this.value = _eventGa && _eventGa.value;
  }
}
