import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormControlsBase, providerFormControlsBase } from '@bvl-core/ui/common';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bvl-datepicker',
  templateUrl: './datepicker.component.html',
  providers: [providerFormControlsBase(BvlDatepickerComponent)]
})
export class BvlDatepickerComponent extends FormControlsBase {

  @Output() dateSelect: EventEmitter<NgbDate>;

  constructor(public element: ElementRef<HTMLElement>) {
    super();
    this.dateSelect = new EventEmitter<NgbDate>();
  }

  onDateSelect(date: NgbDate): void {
    this.dateSelect.next(date);
  }

}
