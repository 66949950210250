import { IMenu } from '@bvl-admin/statemanagement/models/general.interface';

export const MENU_CODE = {
  home: 'P001',
  menuTray: 'P002',
  approvalTray: 'P003',
  pageTray: 'P004',
  usersRoles: 'P005',
  componentTray: 'P007',
  categories: 'P008',
  seoConfiguration: 'P009',
  popupTray: 'P010'
};

export const MENU: Array<IMenu> = [
  {
    code: MENU_CODE.home,
    name: 'Home',
    route: '/home',
    icon: 'la-home'
  },
  {
    code: MENU_CODE.menuTray,
    name: 'Secciones',
    route: '/menu-tray',
    icon: 'la-file-text'
  },
  {
    code: MENU_CODE.approvalTray,
    name: 'Aprobaciones',
    route: '/approval-tray',
    icon: 'la-check-circle'
  },
  {
    code: MENU_CODE.pageTray,
    name: 'Bandeja de páginas',
    route: '/page-tray',
    icon: 'la-inbox'
  },
  {
    code: MENU_CODE.usersRoles,
    name: 'Administrar usuarios y roles',
    route: '/users-roles',
    icon: 'la-laptop'
  },
  {
    code: MENU_CODE.componentTray,
    name: 'Componentes comunes',
    route: '/component-tray/common',
    icon: 'la-puzzle-piece'
  },
  {
    code: MENU_CODE.popupTray,
    name: 'Componentes popup',
    route: '/component-tray/popup',
    icon: 'la-puzzle-piece'
  },
  {
    code: MENU_CODE.categories,
    name: 'Categorías',
    route: '/categories',
    icon: 'la-align-left'
  },
  {
    code: MENU_CODE.seoConfiguration,
    name: 'SEO - Configuración',
    route: '/seo-configuration',
    icon: 'la-puzzle-piece'
  }
];
