import { Component, Input, OnInit } from '@angular/core';
import { AuthService, ConfigurationService } from '@bvl-admin/services';
import { IMAGES } from '@bvl-admin/settings/constants/images.constant';
import { GeneralLang } from '@bvl-admin/settings/lang';

@Component({
  selector: 'admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() customClass: string;

  IMAGES = IMAGES;
  GENERAL_LANG = GeneralLang;

  fullname: string;
  roleName: string;

  constructor(
    private _configurationService: ConfigurationService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    const userPermissions = this._configurationService.getUserPermissions();
    this.fullname = userPermissions.fullname;
    this.roleName = userPermissions.roleName;
  }

  logout(): void {
    this._authService.logout();
  }

}
