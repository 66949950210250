import { Component, OnInit, Renderer2 } from '@angular/core';
import { AngularUtil } from '../extra';

const BIANNUAL_PERIOD = [
  {
    code: '2',
    description: 'Primer Semestre'
  },
  {
    code: '4',
    description: 'Segundo Semestre'
  }
];

const YEAR_PERIOD = [
  {
    code: '1',
    description: 'Primer Trimestre'
  },
  {
    code: '2',
    description: 'Segundo Trimestre'
  },
  {
    code: '3',
    description: 'Tercer Trimestre'
  },
  {
    code: '4',
    description: 'Cuarto Trimestre'
  },
  {
    code: 'A',
    description: 'Auditados'
  }
];
@Component({
  selector: 'bvl-dynamic-table',
  templateUrl: './dynamic-table.component.html'
})
export class DynamicTableComponent implements OnInit {

  modalPayload: any;
  tableData: any;

  get title(): string {
    const { document, documentInformation } = this.modalPayload;

    return documentInformation ? documentInformation.dscPatrimony : document[0].mainTitle;
  }

  get fileHead(): string {
    const { documentInformation } = this.modalPayload;

    if (documentInformation) {
      const { dscPatrimony, currency } = documentInformation;

      return `${dscPatrimony} ${currency ? `(${currency})` : '' }`;
    }

    return '';
  }

  constructor(
    private _renderer2: Renderer2
  ) {
    this.tableData = null;
  }

  ngOnInit(): void {
    this._createTable();
  }

  replacePeriod = str => {
    if (str && this.modalPayload.period) {
      const allPeriods = [...BIANNUAL_PERIOD, ...YEAR_PERIOD];

      allPeriods.forEach(p => {
        if (str.indexOf(p.description) !== -1) {
          str = str.replace(p.description, this.modalPayload.yearPeriod);
        }
      });
    }

    return str;
  }

  private _checkNumberColumns(row): number {
    if (row) {
      return Object.keys(row)
        .filter(r => r.indexOf('column') !== -1).length;
    }

    return null;
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private _createTable(): void {
    this.tableData = null;

    const { document, documentInformation, documentType } = this.modalPayload;
    const doc = documentInformation ? documentInformation.detail : document;

    const table = this._renderer2.createElement('table');
    const isCP = (documentType === 'CP'),
          SUB_TITLE_REGEX = (isCP)
                              ? /^Saldos/gi
                              : /^[0-9]{1}(d)[0-9]+(t|st)$|^[0-9]{1}(dt)[0-9]+$/gi;

    for (const r of doc) {
      const row = this._renderer2.createElement('tr'),
            cellsNumber = +r.numberColumns || this._checkNumberColumns(r);

      for (let c = -1; c <= cellsNumber; c++) {
        const cell = this._renderer2.createElement('td');
        let t = '', rowClassName = '', cellClassName = '';

        switch (c) {
          case -1:
            t = r.caccount || r.cAccount || r.count || '';
            rowClassName = (t)
                          ? (!isCP && SUB_TITLE_REGEX.test(t))
                            ? 'g-site-table-file--subtitle'
                            : ''
                          : 'g-site-table-file--title';
            break;
          case 0:
            t = r.title || r.description || '';
            rowClassName = (isCP && t.search(SUB_TITLE_REGEX) > -1)
                          ? 'g-site-table-file--subtitle'
                          : '';
            break;
          default:
            const valString = documentInformation ? 'column' : 'value';
            t = r[`${valString}${c}`] || '';
            cellClassName = (AngularUtil.isNumber(t))
                            ? 'text-right'
                            : '';
            break;
        }

        const str = t ? (typeof t === 'string') && t.trim() : t;
        const text = this._renderer2.createText(str === '0' ? '' : str);
        this._renderer2.appendChild(cell, text);
        if (cellClassName) { this._renderer2.addClass(cell, cellClassName); }
        this._renderer2.appendChild(row, cell);
        if (rowClassName) { this._renderer2.addClass(row, rowClassName); }
      }

      this._renderer2.appendChild(table, row);
    }
    this.tableData = table.innerHTML;
  }

}
