export const addElementToRef = (webComponent: any, clear?: boolean, element?: any): void => {
  if (clear) {
    document.getElementById(webComponent.parent).innerHTML = '';
  }
  const content = (element || document).querySelector(`#${webComponent.parent}`);
  content.insertAdjacentHTML(webComponent.position, webComponent.html);
};

export const addScriptTag = (names: Array<string>, wcBundlePath: string): void => {
  (names || []).forEach((webComponent: string) => {
    const scriptId = `${webComponent}_script`;
    const tmpScript = document.getElementById(scriptId);
    if (!tmpScript) {
      const scriptTag = document.createElement(`script`);

      scriptTag.setAttribute('src', `${wcBundlePath}/${webComponent}/main.js`);
      scriptTag.setAttribute('type', 'text/javascript');
      scriptTag.setAttribute('id', `${webComponent}_script`);

      document.body.appendChild(scriptTag);
    }
  });
};

export const getMonthsBetweenTwoDates = (startDate: Date, endDate: Date): Array<Date> => {
  endDate = new Date(endDate.getFullYear(), endDate.getMonth());
  let monthsArray = [];
  let currentDate = new Date(startDate.getFullYear(), startDate.getMonth());
  while (currentDate <= endDate) {
    monthsArray = [...monthsArray, currentDate];
    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
  }

  return monthsArray;
};
