import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthEndpoint } from '@bvl-admin/endpoints';
import { IAuthRequest, IAuthResponse } from '@bvl-admin/statemanagement/models/auth.interface';
import { IUserProfile } from '@bvl-admin/statemanagement/models/configuration.interface';
import { ApiService } from '@bvl-core/shared/helpers/api';
import { AuthenticationService } from '@bvl-core/shared/helpers/authentication';
import { StorageService } from '@bvl-core/shared/helpers/util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _apiService: ApiService,
    private _authenticationService: AuthenticationService,
    private _configurationService: ConfigurationService,
    private _storageService: StorageService,
    private _router: Router
  ) { }

  login(params: IAuthRequest, showSpin: boolean = false): Observable<boolean> {
    return this._apiService.post(AuthEndpoint.login, params, { preloader: showSpin })
      .pipe(
        map((response: IAuthResponse) => {
          const userProfile = {
            username: params.username,
            rememberMe: params.rememberMe
          } as IUserProfile;
          this._authenticationService.setUserToken(response.id_token);
          this._configurationService.setUserProfile(userProfile);

          return true;
        })
      );
  }

  logout(): void {
    this._storageService.clear();
    this._router.navigate(['/login']);
  }

}
