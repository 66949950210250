import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'bvl-card-content',
  templateUrl: './card-content.component.html'
})
export class BvlCardContentComponent {

  protected _class: string;
  @HostBinding('attr.class')
  @Input()
  get class(): string {
    return this._class;
  }
  set class(value: string) {
    this._class = (value) ? `${this._class} ${value}` : 'g-admin-card-body';
  }

  constructor() {
    this._class = 'g-admin-card-body';
  }

}
