import { Injectable } from '@angular/core';
import { CommentEndpoint } from '@bvl-admin/endpoints';
import { ICommentResponse, ISaveCommentRequest } from '@bvl-admin/statemanagement/models/comment.interface';
import { ApiService } from '@bvl-core/shared/helpers/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  private _commentsEditor: Array<ICommentResponse>;

  constructor(
    private _apiService: ApiService
  ) { }

  saveComment(parmas: ISaveCommentRequest, showSpin: boolean = false): Observable<ICommentResponse> {
    return this._apiService.post(CommentEndpoint.saveComment, parmas, { preloader: showSpin });
  }

  commentsByIdPage(idPage: string, showSpin: boolean = false): Observable<Array<ICommentResponse>> {
    const params = { idPage };

    return this._apiService.get(CommentEndpoint.commentsByIdPage, { params, preloader: showSpin });
  }

  setCommentsEditor(comments: Array<ICommentResponse>): void {
    this._commentsEditor = comments || [];
  }

  getCommentsEditor(): Array<ICommentResponse> {
    return this._commentsEditor || [];
  }

}
