import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DomChangeDirectiveModule } from '../extra';
import { MediaPlayerComponent } from './media-player.component';

@NgModule({
  imports: [CommonModule, DomChangeDirectiveModule],
  declarations: [MediaPlayerComponent],
  exports: [MediaPlayerComponent]
})
export class MediaPlayerModule { }
