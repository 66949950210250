import { IScreenResolution } from './resize.interface';

export const SCREEN_BREAKPOINTS = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl'
};

export const SCREEN_RESOLUTIONS: Array<IScreenResolution> = [
  { type: SCREEN_BREAKPOINTS.xs, min: 0, max: 576 },
  { type: SCREEN_BREAKPOINTS.sm, min: 576, max: 767 },
  { type: SCREEN_BREAKPOINTS.md, min: 768, max: 991 },
  { type: SCREEN_BREAKPOINTS.lg, min: 992, max: 1199 },
  { type: SCREEN_BREAKPOINTS.xl, min: 1200, max: 5120 }
];
