import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WithoutDataModule } from '../without-data';
import { TableDownloadFileComponent } from './table-download-file.component';

@NgModule({
  declarations: [TableDownloadFileComponent],
  imports: [
    CommonModule,
    WithoutDataModule
  ],
  exports: [TableDownloadFileComponent]
})
export class TableDownloadFileModule { }
