import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
// import { BvlNotifyModule } from '@bvl-core/ui/lib/components';
import { ModalComponent } from './modal/modal.component';
import { ModalConfig } from './models/modal-config';
import { ModalService } from './services/modal.service';

@NgModule({
  declarations: [ModalComponent],
  imports: [
    CommonModule
    // BvlNotifyModule
  ],
  entryComponents: [ModalComponent],
  providers: [
    ModalService
  ]
})
export class ModalModule {

  static forRoot(modalConfig: ModalConfig): ModuleWithProviders {
    return {
      ngModule: ModalModule,
      providers: [
        { provide: ModalConfig, useValue: modalConfig }
      ]
    };
  }

}
