import { ElementRef } from '@angular/core';

import { coerceBooleanProp } from '../helpers';
import { Constructor } from './constructor';

export interface ICanDisable {
  disabled: Disabled;
}
export interface IHasElementRef {
  _elementRef: ElementRef;
}
export type Disabled = boolean;

export const mixinDisabled = <T extends Constructor<IHasElementRef>>(base: T): Constructor<ICanDisable> & T => {
  return class extends base {
    private _disabled: Disabled;

    get disabled(): any {
      return this._disabled;
    }
    set disabled(value: any) {
      /* this._disabled = coerceBooleanProp(value); */
      const disabled = coerceBooleanProp(value);

      if (this._disabled !== disabled) {
        if (disabled) {
          this._elementRef.nativeElement.classList.add('disabled');
        } else {
          this._elementRef.nativeElement.classList.remove('disabled');
        }

        this._disabled = disabled;
      }
    }

    constructor(...args: Array<any>) {
      super(...args);
    }
  };
};
