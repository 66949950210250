import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { IScriptModel, SCRIPT_TYPE } from './../../settings/constants/script.interface';

// @dynamic
@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  constructor(@Inject(DOCUMENT) private _document: Document) { }

  public async load(scripts: Array<IScriptModel>): Promise<Array<any>> {
    const promises: Array<any> = [];
    scripts.forEach(script => promises.push(this._loadScript(script)));

    return Promise.all(promises);
  }

  public remove(scripts: Array<IScriptModel>): void {
    scripts.forEach(script => {
      const elements: any = this._document.querySelector(this._getSelector(script));
      if (elements) {
        elements.remove();
      }
    });
  }

  private async _loadScript(newScript: IScriptModel): Promise<any> {
    return new Promise(resolve => {
      // resolve if already loaded
      const elements: any = this._document.querySelector(this._getSelector(newScript));

      if (elements) {
        resolve({ script: newScript, loaded: true, status: 'Already Loaded' });

        return;
      }

      // load script
      const element: any = this._document.createElement(this._getElementTag(newScript));
      this._setUrl(element, newScript);

      if (newScript.attributes) {
        Object.keys(newScript.attributes)
          .forEach(key => element.setAttribute(key, newScript.attributes[key]));
      }

      if (element.readyState) {
        // IE
        element.onreadystatechange = () => {
          if (element.readyState === 'loaded' || element.readyState === 'complete') {
            element.onreadystatechange = undefined;
            resolve({ script: newScript, loaded: true, status: 'Loaded' });
          }
        };
      } else {
        // Others
        element.onload = () => {
          resolve({ script: newScript, loaded: true, status: 'Loaded' });
        };
      }

      element.onerror = () => {
        resolve({ script: newScript, loaded: false, status: 'Loaded' });
      };

      this._document.getElementsByTagName('head')[0]
        .appendChild(element);
    });
  }

  private readonly _getSelector: (script: IScriptModel) => string = (script: IScriptModel) => {
    return script.type === SCRIPT_TYPE.JS ? `script[src='${script.src}']` : `link[href='${script.src}']`;
  }

  private readonly _getElementTag: (script: IScriptModel) => string = (script: IScriptModel) => {
    return script.type === SCRIPT_TYPE.JS ? 'script' : 'link';
  }

  private readonly _setUrl: (element: any, script: IScriptModel) => void = (element: any, script: IScriptModel) => {
    switch (script.type) {
      case SCRIPT_TYPE.JS:
        element.src = script.src;
        break;
      case SCRIPT_TYPE.CSS:
        element.href = script.src;
        element.rel = 'stylesheet';
        break;

      default:
        break;
    }
  }
}
