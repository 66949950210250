import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

import { FormControlsBase, providerFormControlsBase } from '../form-controls.base';

@Component({
  selector: 'bvl-form-datepicker',
  templateUrl: './form-datepicker.component.html',
  providers: [providerFormControlsBase(BvlFormDatepickerComponent)]
})
export class BvlFormDatepickerComponent extends FormControlsBase {

  @HostBinding('attr.class') attrClass = 'w-100';

  protected _format: string;
  @Input()
  get format(): string {
    return this._format;
  }
  set format(value: string) {
    this._format = value || 'MMM y';
  }

  protected _disableWeekends: any;
  @Input()
  get disableWeekends(): any {
    return this._disableWeekends;
  }
  set disableWeekends(value: any) {
    this._disableWeekends = value;
  }

  protected _minDate: NgbDate | any;
  @Input()
  get minDate(): any {
    return this._minDate;
  }
  set minDate(value: any) {
    this._minDate = (value)
                      ? this._dateToNgbDate(value)
                      : value;
  }

  protected _maxDate: NgbDate | any;
  @Input()
  get maxDate(): any {
    return this._maxDate;
  }
  set maxDate(value: any) {
    this._maxDate = (value)
                      ? this._dateToNgbDate(value)
                      : value;
  }

  @Output() dateSelect: EventEmitter<Date>;

  constructor(private calendar: NgbCalendar) {
    super();
    this._format = 'MMM y';
    this.dateSelect = new EventEmitter<Date>();
  }

  onDateSelect(date: NgbDate): void {
    this.dateSelect.next(new Date(date.year, date.month - 1, date.day));
  }

  isDisabled = (date: NgbDate, current: {month: number}) => {
    if (this.disableWeekends) {  return this.calendar.getWeekday(date) >= 6; }

    return false;
  }

  private _dateToNgbDate(date: Date): NgbDate {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    } as NgbDate;
  }

}
