import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VgBufferingModule } from 'videogular2/buffering';
import { VgControlsModule } from 'videogular2/controls';
import { VgCoreModule } from 'videogular2/core';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { BvlVideoComponent } from './video.component';

@NgModule({
  imports: [
    CommonModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  declarations: [BvlVideoComponent],
  exports: [BvlVideoComponent]
})
export class BvlVideoModule { }
