import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ITestimony } from './testimony';

@Component({
  selector: 'bvl-testimony',
  templateUrl: './testimony.component.html',
  styleUrls: ['./testimony.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TestimonyComponent {
  @Input() testimony: ITestimony;
}
