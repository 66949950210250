import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { BvlFormSelectComponent } from './form-select.component';

@NgModule({
  declarations: [BvlFormSelectComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [BvlFormSelectComponent]
})
export class BvlFormSelectModule { }
