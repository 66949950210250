import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BackgroundImgUrlDirective } from './background-img-url.directive';

@NgModule({
  declarations: [BackgroundImgUrlDirective],
  imports: [
    CommonModule
  ],
  exports: [BackgroundImgUrlDirective]
})
export class BackgroundImgUrlModule { }
