import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  QueryList} from '@angular/core';

import { GaUnsubscribeBase } from '../extra/utils/google';
import { CarouselSlideDirective } from './carousel-slide.directive';

@Component({
  selector: 'bvl-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent extends GaUnsubscribeBase implements AfterContentInit, AfterViewInit, AfterViewChecked {
  @HostBinding('attr.class') attrClass = 'w-100 d-block';

  private activeId: string;
  private element: HTMLElement;
  private tracker: HTMLElement;
  private initialized = false;
  private xOffset = 0;
  private xValue = 0;
  private slidesOnScreen: number;
  private transitionTime = 200;
  private isMoving = false;

  nextButtonActive = true;
  prevButtonActive = true;

  active = true;

  @ContentChildren(CarouselSlideDirective) slides: QueryList<CarouselSlideDirective>;
  @Input() canBeActivated = true;
  @Input() itemsGutter = '1.153rem';
  @Input() urlLink: string;
  @Input() textLink: string;

  constructor(
    protected _elementRef: ElementRef,
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    super(_elementRef);
  }

  ngAfterContentInit(): void {
    this.element = this.elementRef.nativeElement.querySelector('.carousel');
    this.tracker = this.element.querySelector('.tracker');
  }

  ngAfterViewInit(): void {
    if (this.itemsGutter) {
      this._setTrackerMargins();
      this._setItemGutters();
    }

    if (this._checkActivation()) {
      this.cdr.detectChanges();
      this._init();
      this._setup();
    } else {
      this._destroy();
    }
  }

  ngAfterViewChecked(): void {
    this._onViewChanged();
  }

  @HostListener('window:resize') onResize(): void {
    this.ngZone.run(() => {
      if (!this.active && this._checkActivation()) {
        this._setup();
      }
    });
  }

  next(): void {
    if (this.isMoving) {
      return;
    }
    const nextSlide = this._getNextSlide(this.activeId);
    const xPos = this._getXPosSlideById(nextSlide);
    this._setTranslation(xPos);
    this.activeId = nextSlide;
  }

  prev(): void {
    if (this.isMoving) {
      return;
    }
    const prevSlide = this._getPrevSlide(this.activeId);
    const xPos = this._getXPosSlideById(prevSlide);
    this._setTranslation(xPos);
    this.activeId = prevSlide;
  }

  private _init(): void {
    const activeSlide = this._getSlideById(this.activeId);
    this.activeId = activeSlide ? activeSlide.id : (this.slides.length ? this.slides.first.id : null);
    this.tracker.style.transition = `transform ${this.transitionTime}ms`;
    this.initialized = true;
  }

  private _setup(): void {
    try {
      const rect = this.element.querySelector(`#${this.activeId}`)
        .getBoundingClientRect() as DOMRect;
      this.xOffset = rect.left;
      this.slidesOnScreen = this._getSlidesOnScreen();
      this._setTranslation(this._getXPosSlideById(this.activeId));
    } catch (error) {
    }
  }

  private _onViewChanged(): void {
    if (!this.active && this._checkActivation()) {
      if (!this.initialized) {
        this._init();
      }
      this._setup();
    } else if (!this._checkActivation()) {
      this._destroy();
    }
    this.cdr.detectChanges();
  }

  private _destroy(): void {
    this.tracker.style.transform = '';
  }

  private _checkActivation(): boolean {
    let willBeActive = false;
    this.element.classList.add('carousel-active');
    if (this.itemsGutter) {
      this._removeTrackerMargins();
    }
    const trackerWidth = this.tracker.clientWidth;
    if (this.itemsGutter) {
      this._setTrackerMargins();
    }
    if (this.canBeActivated && trackerWidth > this.element.clientWidth) {
      willBeActive = true;
    } else {
      this.element.classList.remove('carousel-active');
    }

    return this.active = willBeActive;
  }

  private _getSlideById(slideId: string): CarouselSlideDirective {
    return this.slides.find(slide => slide.id === slideId);
  }

  private _getXPosSlideById(slideId: string): number {
    const slideElement = this.element.querySelector(`#${slideId}`);
    const slideRect = slideElement.getBoundingClientRect() as DOMRect;

    return this.xValue - slideRect.left + this.xOffset;
  }

  private _setTranslation(xValue: number): void {
    this.xValue = xValue;
    this.isMoving = true;
    this.tracker.style.transform = `translateX(${this.xValue}px)`;
    setTimeout(() => {
      this.isMoving = false;
    }, this.transitionTime);
  }

  private _getSlideIdxById(slideId: string): number {
    return this.slides.toArray()
      .indexOf(this._getSlideById(slideId));
  }

  private _getNextSlide(currentSlideId: string): string {
    const slideArr = this.slides.toArray();
    const currentSlideIdx = this._getSlideIdxById(currentSlideId);
    const isLastSlide = currentSlideIdx + this.slidesOnScreen === slideArr.length;

    return isLastSlide ? slideArr[currentSlideIdx].id : slideArr[currentSlideIdx + 1].id;
  }

  private _getPrevSlide(currentSlideId: string): string {
    const slideArr = this.slides.toArray();
    const currentSlideIdx = this._getSlideIdxById(currentSlideId);
    const isFirstSlide = currentSlideIdx === 0;

    return isFirstSlide ? slideArr[0].id : slideArr[currentSlideIdx - 1].id;
  }

  private _getSlidesOnScreen(): number {
    const carouselWidth = this.element.clientWidth;
    const slidesOnScreen = Array.from(this.element.querySelectorAll('.carousel-item'))
      .filter(slide => {
        const slideRect = slide.getBoundingClientRect() as DOMRect;

        return slideRect.left >= 0 && slideRect.left < (carouselWidth + this.xOffset);
      });

    return slidesOnScreen.length;
  }

  private _setTrackerMargins(): void {
    Object.assign(this.tracker.style, {
      marginLeft: `-${this.itemsGutter}`,
      marginRight: `-${this.itemsGutter}`
    });
  }

  private _removeTrackerMargins(): void {
    Object.assign(this.tracker.style, {
      marginLeft: 0,
      marginRight: 0
    });
  }

  private _setItemGutters(): void {
    Array.from(this.element.querySelectorAll('.carousel-item'))
      .forEach((slide: HTMLElement) => {
        slide.style.paddingLeft = this.itemsGutter;
        slide.style.paddingRight = this.itemsGutter;
      });
  }
}
