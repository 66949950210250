import { Injectable } from '@angular/core';
import { UserEndpoint } from '@bvl-admin/endpoints';
import {
  IBvlUserResponse,
  IDeleteUserRequest,
  ISaveUserRequest,
  IUserPaginationRequest,
  IUserPaginationResponse,
  IUserResponse,
  IUserView
} from '@bvl-admin/statemanagement/models/user.interface';
import { ApiService } from '@bvl-core/shared/helpers/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userBehaviorSubject: BehaviorSubject<IUserView>;

  constructor(
    private _apiService: ApiService
  ) {
    this.userBehaviorSubject = new BehaviorSubject<IUserView>(null);
  }

  usersPagination(params: IUserPaginationRequest, showSpin: boolean = false): Observable<IUserPaginationResponse> {
    return this._apiService.post(UserEndpoint.usersList, params, { preloader: showSpin });
  }

  updateUser(params: ISaveUserRequest, showSpin: boolean = false): Observable<any> {
    return this._apiService.put(UserEndpoint.updateUser, params, { preloader: showSpin });
  }

  deleteUser(params: IDeleteUserRequest, showSpin: boolean = false): Observable<any> {
    return this._apiService.post(UserEndpoint.deleteUser, params, { preloader: showSpin });
  }

  setUsersList(actionType: string, user: IUserView): void {
    user.actionType = actionType;
    this.userBehaviorSubject.next(user);
  }

  updateUsersList(): BehaviorSubject<IUserView> {
    return this.userBehaviorSubject;
  }

  bvlUsersList(showSpin: boolean = false): Observable<Array<IBvlUserResponse>> {
    return this._apiService.get(UserEndpoint.bvlUsersList, { preloader: showSpin });
  }

  saveUser(params: ISaveUserRequest, showSpin: boolean = false): Observable<any> {
    return this._apiService.post(UserEndpoint.saveUser, params, { preloader: showSpin });
  }

  usersList(showSpin: boolean = false): Observable<Array<IUserResponse>> {
    return this._apiService.get(UserEndpoint.usersList, { preloader: showSpin });
  }

}
