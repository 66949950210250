import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  Injector,
  Optional,
  Renderer2,
  RendererFactory2,
  TemplateRef
} from '@angular/core';

import { ContentRef } from '../../../../helpers/popup';
import { ModalComponent } from '../modal/modal.component';
import { ActiveModal } from '../models/active-modal';
import { ModalConfig } from '../models/modal-config';
import { ModalRef } from '../models/modal-ref';
import { IModalConfig } from '../models/modal.interface';
import { ModalUtil } from '../util/modal.util';

// @dynamic
@Injectable()
export class ModalService {

  private _renderer2: Renderer2;
  private _modalConfig: ModalConfig;
  private _modalRefs: Array<ModalRef> = [];

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Optional() modalConfig: ModalConfig,
    private _applicationRef: ApplicationRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _rendererFactory2: RendererFactory2
  ) {
    this._renderer2 = this._rendererFactory2.createRenderer(null, null);
    const defaultModalConfig = new ModalConfig();
    this._modalConfig = { ...defaultModalConfig, ...(modalConfig || {}) };
  }

  open(content: any, modalConfig?: IModalConfig): ModalRef {
    const containerElement = this._document.body;

    const activeModal = new ActiveModal();
    const contentRef = this._getContentRef(this._componentFactoryResolver, this._injector, content, activeModal);
    const modalCmptRef: ComponentRef<ModalComponent> = this._attachModalComponent(
      this._componentFactoryResolver, containerElement, contentRef
    );

    const modalRef: ModalRef = new ModalRef(modalCmptRef, contentRef);
    this._modalRefs.push(modalRef);

    const removeModalElements = () => {
      const index = this._modalRefs.indexOf(modalRef);
      this._modalRefs.splice(index, 1);

      let time = 0;
      if (!this._modalRefs.length) {
        this._renderer2.removeClass(this._document.body, 'shared-modal-open');
        time = 700;
      }
      // removeModalElements after 700ms for the effect css
      setTimeout(() => modalRef.removeModalElements(), time);
    };
    modalRef.result.then(removeModalElements, removeModalElements);

    // setModalConfig
    modalConfig = { ...this._modalConfig, ...(modalConfig || {}) };
    modalRef.setConfig(modalConfig);

    activeModal.close = (result: any) => { modalRef.close(result); };
    activeModal.dismiss = (reason: any) => { modalRef.dismiss(reason); };
    activeModal.confirm = () => modalRef.confirm();

    // Added class to the body after 300ms for the effect css
    setTimeout(() => {
      this._renderer2.addClass(this._document.body, 'shared-modal-open');
    }, 300);

    return modalRef;
  }

  isActiveModal(): boolean {
    return this._modalRefs.length > 0;
  }

  private _getContentRef(
    componentFactoryResolver: ComponentFactoryResolver,
    contentInjector: Injector,
    content: any,
    activeModal: ActiveModal): ContentRef {
    if (!content) {
      return new ContentRef([]);
    } else if (content instanceof HTMLElement) {
      return new ContentRef([[content]]);
    } else if (content instanceof TemplateRef) {
      return this._createFromTemplateRef(content, activeModal);
    } else if (ModalUtil.isString(content)) {
      return this._createFromString(content);
    } else {
      return this._createFromComponent(componentFactoryResolver, contentInjector, content, activeModal);
    }
  }

  private _createFromTemplateRef(content: TemplateRef<any>, activeModal: ActiveModal): ContentRef {
    const context = {
      $implicit: activeModal,
      close(result): void { activeModal.close(result); },
      dismiss(reason): void { activeModal.dismiss(reason); },
      confirm(): void { activeModal.confirm(); }
    };
    const viewRef = content.createEmbeddedView(context);
    this._applicationRef.attachView(viewRef);

    return new ContentRef([viewRef.rootNodes], viewRef);
  }

  private _createFromString(content: string): ContentRef {
    const component = this._document.createTextNode(`${content}`);

    return new ContentRef([[component]]);
  }

  private _createFromComponent(
    componentFactoryResolver: ComponentFactoryResolver,
    contentInjector: Injector,
    content: any,
    activeModal: ActiveModal): ContentRef {
    // Create a component reference from the component
    // The resolveComponentFactory() method takes a component and returns the recipe for how to create a component.
    const contentFactory = componentFactoryResolver.resolveComponentFactory(content);
    const newContentInjector = Injector.create({
      providers: [
        {
          provide: ActiveModal,
          useValue: activeModal
        }
      ],
      parent: contentInjector
    });
    const componentRef = contentFactory.create(newContentInjector); // En este punto se crea el content por tanto ingresa a la clase
    // Attach component to the appRef so that it's inside the ng component tree
    this._applicationRef.attachView(componentRef.hostView);

    // Instance my class ContentRef for have in stock my 3 values: nodes(nativeElement), refView, componentRef
    return new ContentRef([[componentRef.location.nativeElement]], componentRef.hostView, componentRef);
  }

  private _attachModalComponent(
    componentFactoryResolver: ComponentFactoryResolver,
    containerElement: any,
    contentRef: any): ComponentRef<ModalComponent> {
    // Create a component reference from the component
    // The resolveComponentFactory() method takes a component and returns the recipe for how to create a component.
    const modalFactory = componentFactoryResolver.resolveComponentFactory(ModalComponent);
    // Added el content in my ModalComponent(<ng-content></ng-content>)
    const modalRef = modalFactory.create(this._injector, contentRef.nodes);
    // Attach modal to the appRef so that it's inside the ng component tree
    this._applicationRef.attachView(modalRef.hostView);
    // Append DOM element to the body or another specific section
    containerElement.appendChild(modalRef.location.nativeElement);

    // Return modalRef
    return modalRef;
  }

}
