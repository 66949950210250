import { ElementRef, Input } from '@angular/core';

import { translateKey } from '../extra';
import { GaUnsubscribeBase } from '../extra/utils/google';
import {
  IComponentPropertiesCode,
  IComponentValueLanguage,
  IComponentValueProperty,
  IComponentValueResponse
} from './component.interface';

export abstract class CommonComponentBase extends GaUnsubscribeBase {

  @Input() language: string;

  componentCode: string;

  constructor(
    protected _elementRef: ElementRef
  ) {
    super(_elementRef);
    this.language = 'es';
  }

  componentValueByLanguage(componentValue: IComponentValueResponse, language: string): IComponentValueLanguage {
    return componentValue.languages.find(fv => fv.language === language);
  }

  getComponentValuePropertiesCode(
    componentValue: IComponentValueLanguage | { properties: Array<IComponentValueProperty> },
    ignoreKeys?: Array<string>,
    camel: boolean = true
  ): IComponentPropertiesCode {
    return componentValue.properties.reduce((previous, current) => {
      const code: string = camel ? translateKey(current.code) : current.code;

      if (ignoreKeys && ignoreKeys.includes(current.code)) {
        previous[code] = current.value;

        return previous;
      }

      switch (current.code) {
        case 'videos':
        case 'carousel':
        case 'sliders':
          previous[code] = current.value.map(fv => this.getComponentValuePropertiesCode(fv, ignoreKeys));
          break;
        case 'ga':
          previous[code] = current.value.map(fv => this.getComponentValuePropertiesCode(fv, ['category']));
          break;
        case 'category':
          previous[code] = { id: 0, label: current.value };
          break;
        case 'background-image':
          previous[code] = current.value ? current.value[0].large.imageUri : '';
          break;
        default:
          previous[code] = current.value;
          break;
      }

      return previous;
    }, {} as IComponentPropertiesCode);
  }

}
