import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

import { DynamicTableComponent } from '../dynamic-table';
import { AngularUtil, FILES_PATH, IModalConfig, MODAL_BREAKPOINT, ModalRef, ModalService } from '../extra';
import { WITHOUT_DATA_TYPE } from '../without-data';
import { ITableFileLinkItem } from './table-file-link.interface';

@Component({
  selector: 'bvl-table-file-link',
  templateUrl: './table-file-link.component.html'
})
export class TableFileLinkComponent implements OnInit {

  WITHOUT_DATA_TYPE = WITHOUT_DATA_TYPE;
  TABLE_FILE = 'filesLink';

  protected _class: string;
  @HostBinding('attr.class')
  @Input()
  get class(): string {
    return this._class;
  }
  set class(value: string) {
    this._class = `${this._class} ${(value || '')}`.trim();
  }

  @Input() header: Array<string>;

  protected _tableData: Array<any>;
  @Input()
  get tableData(): Array<any> {
    return this._tableData;
  }
  set tableData(value: Array<any>) {
    this._tableData = this.header && (value || []).map((fv, fk) => {
      return Object.keys(fv)
        .reduce((previous, current, index) => {
          const keyCurrent = this.header[index];
          previous[keyCurrent || current] = fv[current];

          return previous;
        }, {});
    });
  }

  @Input() message: string;
  @Input() outData = [];

  private _period: string;
  @Input()
  get period(): string {
    return this._period;
  }
  set period(value: string) {
    this._period = value;
  }

  @Output() selectedItem = new EventEmitter<any>();

  constructor(
    private _modalService: ModalService
  ) {
    this._class = 'd-block g-site-table-download g-site-table-download--normal';
    this.message = 'En este momento, no hay datos disponibles';
  }

  ngOnInit(): void { }

  isObject(item: any): boolean {
    return AngularUtil.isObject(item);
  }

  emitItem = (value): void => this.selectedItem.emit(value);

  isToOutput = str => str && this.outData.includes(str);

  isPart = str => str && str.indexOf('Parte') !== -1 && str.split(' ').length === 2;

  openPopup(value: ITableFileLinkItem): void {
    value.link.yearPeriod = this.period;
    const config = {
      titleText: value.link.businessName,
      size: MODAL_BREAKPOINT.xl,
      showFullScreen: true,
      showFooter: false
    } as IModalConfig;
    const modalRef: ModalRef = this._modalService.open(DynamicTableComponent, config);
    modalRef.setPayload(value.link);
  }

  getPathfile(value: ITableFileLinkItem): string {
    return `${FILES_PATH}${value.link}`;
  }

}
