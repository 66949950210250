import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SpinnerConfig } from './models/spinner-config';
import { SpinnerService } from './services/spinner.service';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  declarations: [SpinnerComponent],
  imports: [
    CommonModule
  ],
  entryComponents: [SpinnerComponent],
  providers: [SpinnerService]
})
export class SpinnerModule {

  static forRoot(spinnerConfig: SpinnerConfig): ModuleWithProviders {
    return {
      ngModule: SpinnerModule,
      providers: [
        { provide: SpinnerConfig, useValue: spinnerConfig }
      ]
    };
  }

}
