import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DomChangeDirectiveModule } from '../extra';
import { LinksListComponent } from './links-list.component';

@NgModule({
  imports: [CommonModule, DomChangeDirectiveModule],
  declarations: [LinksListComponent],
  exports: [LinksListComponent]
})
export class LinksListModule { }
