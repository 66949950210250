
export interface IMediaPlayer {
  url?: any;
  title?: any;
}

// tslint:disable-next-line: max-line-length
export const YOUTUBE_REGEX = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const AUDIO_REGEX = /\.(?:wav|mp3)$/;

export const checkIfAudio = value => AUDIO_REGEX.test(value) || value.indexOf('mp3') !== -1;

export const checkValueIsMedia = value => YOUTUBE_REGEX.test(value) || checkIfAudio(value);
