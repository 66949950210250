import { Injectable } from '@angular/core';

export const PLATFORMS = {
  IE: 'msie',
  OPERA: 'opr',
  CHROME: 'chrome',
  SAFARI: 'safari',
  IOSMOBILE: 'iosmobile',
  ANDROID: 'android',
  EDGE: 'edge',
  FIREFOX: 'firefox'
};

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  get isDesktop(): boolean {
    const width = window.innerWidth;

    return width > 991;
  }

  get isMobile(): boolean {
    return !this.isDesktop;
  }

  is(platform: string): boolean {
    return platform === this.getPlarform();
  }

  getPlarform(): string {
    const ua = navigator.userAgent;
    const isiOsMobile = navigator.platform.match(/iPhone|iPod|iPad/);
    let m = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let tem;

    if (isiOsMobile) { return PLATFORMS.IOSMOBILE; }

    if (/trident/i.test(m[1])) { return PLATFORMS.IE; }

    if (m[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem) { return tem[1].toLowerCase(); }
    }

    m = m[2] ? [m[1], m[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);

    if (tem) { m.splice(1, 1, tem[1]); }

    return m[0].toLowerCase();
  }

  getDevice(): string {
    const tmp = navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i);
    if (tmp) { return tmp[0].toLowerCase(); }

    return '';
  }

}
