export const GeneralLang = {
  titles: {
    helloUsernameWelcome: '<strong>¡Hola {{ fullname }}</strong>, bienvenido!',
    comments: 'Comentarios',
    gallery: 'Galería'
  },
  labels: {
    search: 'Buscar',
    searchPage: 'Buscar páginas',
    searchComponent: 'Buscar componente',
    searchUser: 'Buscar usuario',
    users: 'Usuarios',
    user: 'Usuario',
    states: 'Estados',
    notEditable: 'No editable',
    lastUpdate: 'Última actualización',
    state: 'Estado',
    language: 'Idioma',
    role: 'Rol',
    dayMonthYear: 'Día-Mes-Año',
    startDate: 'Fecha inicio',
    endDate: 'Fecha fin',
    category: 'Categoría',
    userAgent: 'User Agent',
    date: 'Fecha',
    page: 'Página',
    path: 'Ruta'
  },
  buttons: {
    save: 'Guardar',
    cancel: 'Cancelar',
    search: 'Buscar',
    back: 'Volver',
    select: 'Seleccionar',
    historyMode: 'Modo historial',
    observe: 'Observar',
    preview: 'Vista previa',
    delete: 'Eliminar',
    publish: 'Publicar',
    send: 'Enviar',
    reply: 'Responder',
    filter: 'Filtrar',
    clear: 'Limpiar',
    acept: 'Aceptar',
    see: 'Ver',
    restore: 'Restaurar',
    comment: 'Comentar',
    edit: 'Editar'
  },
  modalConfirm: {
    titleDelete: '¿Seguro que deseas eliminar {{ customField }}?',
    titleCancelChange: '¿Esta seguro que desea perder todos los cambios realizados?'
  },
  notifications: {
    success: 'Se realizó correctamente',
    notSaveCorrectly: 'No se guardo correctamente',
    completeFields: 'Completa los campos',
    existIncorrectFields: 'Existen campos incorrectos',
    thereAreChangesWithoutSaving: 'Existen cambios sin guardar'
  },
  links: {
    signOff: 'Cerrar sesión',
    seeAll: 'Ver todos'
  },
  messages: {
    notExistUpdates: 'No existen actualizaciones'
  }
};
