import { Component, Input } from '@angular/core';

import { GaUnsubscribeBase, ILink } from '../extra';

@Component({
  selector: 'bvl-links-list',
  templateUrl: './links-list.component.html',
  styleUrls: ['./links-list.component.scss']
})
export class LinksListComponent extends GaUnsubscribeBase {
  @Input() links: Array<ILink>;
}
