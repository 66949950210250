import { ISpinnerConfig } from './spinner.interface';

export class SpinnerConfig implements ISpinnerConfig {

  spinnerClass?: string;
  spinnerIconClass?: string;

  constructor() {
    this.spinnerClass = '';
    this.spinnerIconClass = '';
  }

}
