import { IFancyTableColumn, IFancyTableData, IFancyTableMetada, IFancyTableSortedBy } from './../models/fancy-table.model';
import { convertToDollars } from './currency';

export const sortByString = (a: string, b: string, ascendingOrder: boolean): number => {
  return ascendingOrder ? a.localeCompare(b) : b.localeCompare(a);
};

export const sortByDate = (a: any, b: any, ascendingOrder: boolean): number => {
  a = new Date(a || '01-01-1990');
  b = new Date(b || '01-01-1990');

  return ascendingOrder ? a - b : b - a;
};

export const sortByNumber = (a: number, b: number, ascendingOrder: boolean): number => {
  return ascendingOrder ? a - b : b - a;
};

export const sortByArray = (a: Array<any>, b: Array<any>, ascendingOrder: boolean): number => {
  return ascendingOrder ? a.length - b.length : b.length - a.length;
};

export const sortByBoolean = (a: boolean, b: boolean, ascendingOrder: boolean): number => {
  return ascendingOrder
    ? a === b ? 0 : a ? -1 : 1 : a === b ? 0 : a ? 1 : -1;
};

export const sortByAmount = (a: any, b: any, columnName: string, ascendingOrder: boolean): number => {
  const aInDollars = a.currency && a.currency.code === 'PEN' ? convertToDollars(a[columnName]) : a[columnName];
  const bInDollars = b.currency && b.currency.code === 'PEN' ? convertToDollars(b[columnName]) : b[columnName];

  return ascendingOrder ? aInDollars - bInDollars : bInDollars - aInDollars;
};

export const getFancyTableSortBy = (column: IFancyTableColumn, metadata: IFancyTableMetada): IFancyTableSortedBy => {
  const ascendingOrder = JSON.stringify(metadata.sortedBy.column) === JSON.stringify(column) ? !metadata.sortedBy.ascendingOrder : true;

  return {
    column,
    ascendingOrder
  };
};

export const sortFancyTable = <T>(column: IFancyTableColumn, table: IFancyTableData, data: Array<T>): Array<T> => {
  const ascendingOrder = JSON.stringify(table.metadata.sortedBy.column) === JSON.stringify(column)
    ? !table.metadata.sortedBy.ascendingOrder : true;

  const orderedData = data.sort((a, b) => {
    const aValue = a[column.name] || '';
    const bValue = b[column.name] || '';

    switch (column.valueType) {
      case 'string':
        return sortByString(aValue, bValue, ascendingOrder);
      case 'number':
      case 'price':
      case 'percent':
        return sortByNumber(aValue, bValue, ascendingOrder);
      case 'date':
        return sortByDate(aValue, bValue, ascendingOrder);
      case 'amount':
        return sortByAmount(a, b, column.name, ascendingOrder);
      case 'list':
      case 'facts':
        return sortByArray(aValue, bValue, ascendingOrder);
      case 'boolean':
        return sortByBoolean(aValue, bValue, ascendingOrder);
      default:
        break;
    }
  });

  return orderedData;
};
