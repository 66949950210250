import { Observable } from 'rxjs/Observable';

export class ScrollUtil {
  static goTo(top: number): Observable<any> {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top
    });

    return Observable.create(observer => {
      window.onscroll = () => {
        const scrollY = window.scrollY || window.pageYOffset;
        if (scrollY === top) {
          observer.next();
          observer.complete();
          window.onscroll = null;
        }
      };
    });
  }
}
