import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button/public-api';
import { DomChangeDirectiveModule } from '../extra';
import { MenuComponent } from './menu.component';

@NgModule({
  imports: [CommonModule, ButtonModule, DomChangeDirectiveModule],
  declarations: [MenuComponent],
  exports: [MenuComponent],
  entryComponents: [MenuComponent]
})
export class MenuModule { }
