import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IListImage } from '../extra';

@Component({
  selector: 'bvl-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageComponent implements OnInit {
  private _backgroundImage: Array<IListImage>;
  @Input()
  get backgroundImage(): any {
    return this._backgroundImage;
  }
  set backgroundImage(value: any) {
    this._backgroundImage = JSON.parse(value) as Array<IListImage>;
  }

  image: string;
  alt: string;
  title: string;

  ngOnInit(): void {
    if (Array.isArray(this.backgroundImage) && this.backgroundImage.length) {
      this.image = this.backgroundImage[0].large.imageUri;
      this.alt = this.backgroundImage[0].properties.alt;
      this.title = this.backgroundImage[0].properties.title;
    }
  }
}
