import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CollapseModule } from '../../directives';
import { BvlAccordionContentComponent } from './accordion-content/accordion-content.component';
import { BvlAccordionGroupComponent } from './accordion-group/accordion-group.component';
import { BvlAccordionHeaderComponent } from './accordion-header/accordion-header.component';
import { BvlAccordionComponent } from './accordion/accordion.component';

@NgModule({
  declarations: [
    BvlAccordionComponent,
    BvlAccordionGroupComponent,
    BvlAccordionHeaderComponent,
    BvlAccordionContentComponent
  ],
  imports: [
    CommonModule,
    CollapseModule
  ],
  exports: [
    BvlAccordionComponent,
    BvlAccordionGroupComponent,
    BvlAccordionHeaderComponent,
    BvlAccordionContentComponent
  ],
  providers: [ ]
})
export class BvlAccordionModule { }
