import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ToggleByPositionDirective } from './toggle-by-position.directive';

@NgModule({
  declarations: [ToggleByPositionDirective],
  imports: [
    CommonModule
  ],
  exports: [ToggleByPositionDirective]
})
export class ToggleByPositionModule { }
