import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// Abstract Class
export abstract class UnsubscribeOnDestroy implements OnDestroy {

  unsubscribeDestroy$: Subject<boolean> = new Subject();

  constructor() {}

  ngOnDestroy(): void {
    this.unsubscribeDestroy$.next(true);
    this.unsubscribeDestroy$.complete();
  }

}

// Mixin
type Constructor<T> = new(...args: Array<any>) => T;

export const mixinUnsubscribeOnDestroy = <T extends Constructor<any>>(Base: T) => {
  return class extends Base implements OnDestroy {

    unsubscribeDestroy$: Subject<boolean> = new Subject();

    constructor(...args: Array<any>) {
      super(...args);
    }

    ngOnDestroy(): void {
      this.unsubscribeDestroy$.next(true);
      this.unsubscribeDestroy$.complete();
    }

  };
};
