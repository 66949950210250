import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[bvlToggleByPosition]'
})
export class ToggleByPositionDirective implements OnInit {

  @Input() bvlToggleByPosition: Array<string>;

  constructor(private _elementRef: ElementRef) {}

  ngOnInit(): void {
    const classToggle = this.bvlToggleByPosition;
    const element: HTMLElement = this._elementRef.nativeElement;
    const parent = element.parentElement;
    const parentPostX = parent.offsetLeft;
    const parentWidth = parent.offsetWidth;

    element.classList.add(parentWidth > parentPostX ? classToggle[0] : classToggle[1]);
  }

}
