export const positioning = (tooltip?: HTMLElement, parent?: HTMLElement): void => {
  if (tooltip) {
    if (tooltip.style.display !== 'block') {
      const position = parent.getBoundingClientRect();
      tooltip.style.display = 'block';
      tooltip.style.top = `${parent.offsetTop - tooltip.offsetHeight + 50}px`;
      tooltip.style.left = `${(position.left + parent.offsetWidth / 2 + 10) - tooltip.offsetWidth / 2}px`;
    } else {
      tooltip.style.display = 'none';
    }
  }
};

export const hideToolTips = (tooltip?: HTMLElement): void => {
  document.querySelectorAll('.tooltip')
  .forEach((el: HTMLElement) => {
    if (tooltip) {
      if (el !== tooltip) {
        el.style.display = 'none';
      }
    } else {
      el.style.display = 'none';
    }
  });
};
