import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LANGUAGES, getStorageLanguage } from '../extra';

@Component({
  selector: 'bvl-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrls: ['./cookies-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CookiesBannerComponent implements OnInit {

  TEXT = LANGUAGES[getStorageLanguage()];
  showBanner = false;

  constructor() {}

  ngOnInit(): void {
    const privacyAgreement = localStorage.getItem('hideCookies');
    this.showBanner = !privacyAgreement;
  }

  hideBanner(): void {
    localStorage.setItem('hideCookies', 'true');
    this.showBanner = false;
  }
}
