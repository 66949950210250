export const translateKey = (key: string) => {
  return key.split('-')
    .map((k, idx) => {
      return idx > 0 ? k.substr(0, 1)
        .toUpperCase() + k.substr(1) : k;
    })
    .join('');
};

export const camelcaseKeys = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map(ob => (camelcaseKeys(ob)));
  } else if ((typeof obj === 'object') && (obj !== null)) {
    return Object.keys(obj)
      .reduce((prev, k) => {
        prev[translateKey(k)] = camelcaseKeys(obj[k]);

        return prev;
      }, {});
  }

  return obj;
};
