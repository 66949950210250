import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DomChangeDirectiveModule } from '../extra/utils/google';
import { CarouselSlideDirective } from './carousel-slide.directive';
import { CarouselComponent } from './carousel.component';

@NgModule({
  imports: [CommonModule, DomChangeDirectiveModule],
  declarations: [CarouselSlideDirective, CarouselComponent],
  exports: [CarouselSlideDirective, CarouselComponent]
})
export class CarouselModule { }
