import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DynamicTableModule } from '../dynamic-table';
import { MODAL_CONFIG, ModalModule } from '../extra';
import { WithoutDataModule } from '../without-data';
import { TableFileLinkComponent } from './table-file-link.component';

@NgModule({
  declarations: [TableFileLinkComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(MODAL_CONFIG),
    DynamicTableModule,
    WithoutDataModule
  ],
  exports: [TableFileLinkComponent]
})
export class TableFileLinkModule { }
