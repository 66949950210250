import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DomChangeDirectiveModule } from '../extra';
import { CookiesBannerComponent } from './cookies-banner.component';

@NgModule({
  imports: [CommonModule, DomChangeDirectiveModule],
  declarations: [CookiesBannerComponent],
  exports: [CookiesBannerComponent]
})
export class CookiesBannerModule { }
