import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GAService } from './google.analytics.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [GAService]
})
export class GoogleModule { }
