import { Pipe, PipeTransform } from '@angular/core';

import { IEntry } from '../entry';
import { IFilter } from '../filters';

@Pipe({
  name: 'matchesEntryType'
})
export class MatchesEntryTypePipe implements PipeTransform {
  transform(entries: Array<IEntry>, entryType: IFilter): Array<IEntry> {
    return (entryType && entryType.id) ? entries.filter(entry => entry.type.id === entryType.id) : entries;
  }
}
