import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BvlSwitchValueComponent } from './switch-value.component';

@NgModule({
  declarations: [BvlSwitchValueComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [BvlSwitchValueComponent]
})
export class BvlSwitchValueModule { }
