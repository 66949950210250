import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AmountCellComponent } from './amount-cell.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AmountCellComponent],
  exports: [AmountCellComponent]
})
export class AmountCellModule { }
