import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UnsubscribeOnDestroy } from '@bvl-core/shared/helpers/util';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { coerceBooleanProp } from '../../../common';

@Component({
  selector: 'bvl-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line: no-forward-ref
      useExisting: forwardRef(() => BvlAutocompleteComponent),
      multi: true
    }
  ]
})
export class BvlAutocompleteComponent extends UnsubscribeOnDestroy implements OnInit, ControlValueAccessor {

  @ViewChild('autoComplete') autoComplete: ElementRef;

  protected _placeholder: string;
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value || '';
  }

  protected _value: string;
  @Input()
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
    this.propagateChange(this._value);
  }

  protected _readonly: boolean;
  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProp(value);
  }

  protected _error: boolean;
  @Input()
  get error(): boolean {
    return this._error;
  }
  set error(value: boolean) {
    this._error = coerceBooleanProp(value);
  }

  @Input() canFilter = true;
  @Input() dataList: Array<any>;
  @Input() matchField: string;
  @Input() startLength: number;
  @Input() textField: string;

  @Output() selectItem: EventEmitter<any>;

  list: Array<any>;
  iconSearch: boolean;
  disabled: boolean;

  constructor() {
    super();
    this._placeholder = '';
    this._activeText(true);
    this._readonly = false;
    this._error = false;
    this.dataList = [];
    this.startLength = 2;
    this.selectItem = new EventEmitter<any>();
  }

  ngOnInit(): void { }

  private _activeText(active: boolean): void {
    this.disabled = !active;
    this.iconSearch = active;
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      takeUntil(this.unsubscribeDestroy$),
      distinctUntilChanged(),
      map(term => {
        if (!this.canFilter) {
          return this.dataList;
        }

        if (term.length >= this.startLength) {
          return this.dataList.filter(item => {
            const matchField = item[this.matchField].toLowerCase();

            return matchField.includes(term.toLowerCase());
          });
        }

        return [];
      })
    )

  formatter = (item: any) => item[this.textField];

  oSelectItem(event): void {
    this._activeText(false);
    this.selectItem.next(event);
  }

  delete(): void {
    this._value = null;
    this.propagateChange(null);
    this._activeText(true);
    setTimeout(() => {
      this.autoComplete.nativeElement.focus();
    }, 0);
  }

  /* Takes the value  */
  writeValue(value: any): void {
    if (value !== undefined) {
      this._value = value;
      const hasValue = value && value[this.textField];
      this._activeText(!hasValue);
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
