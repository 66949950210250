import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// import { IUpdateWatchListRequest } from 'modules/fancy-table/src/app/fancy-table';
import { IPlayListRequest } from '../entry/entry';
import { ApiService } from '../extra';
import { WatchListEndPoints } from './endpoints';
import { IIndexWatchListResponse, IStockWatchListResponse } from './watchlist.model';

@Injectable()
export class WatchListService {
  private _enviroment: string;

  constructor(private _apiService: ApiService) { }

  setEnviroment(env): void {
    this._enviroment = env;
  }
  getPlayList(idUser: string): Observable<any> {
    return this._apiService.get(`${this._enviroment}${WatchListEndPoints.playList}/${idUser}`);
  }
  updatePlayList(params: IPlayListRequest): Observable<any> {
    return this._apiService.post(`${this._enviroment}${WatchListEndPoints.updatePlayList}`, params);
  }
  getStockWatchList(idUser: string): Observable<IStockWatchListResponse> {
    return this._apiService.get(`${this._enviroment}${WatchListEndPoints.listStockWatchList}`, { params: { idUser } });
  }
  updateStockWatchList(params: any): Observable<any> {
    return this._apiService.post(`${this._enviroment}${WatchListEndPoints.stockWatchList}`, params);
  }
  getIndexWatchList(idUser: string): Observable<IIndexWatchListResponse> {
    return this._apiService.get(`${this._enviroment}${WatchListEndPoints.listIndexWatchList}`, { params: { idUser } });
  }
  updateIndexWatchList(params: any): Observable<any> {
    return this._apiService.post(`${this._enviroment}${WatchListEndPoints.indexWatchList}`, params);
  }
}
