import { NOTIFICATION_TYPES } from './notification-types.constants';

export interface INotify {
  type: string;
  title: string;
  description: string;
  date: Number;
  data?: Object;
}

export class SuccessNotify implements INotify {
  type: string;
  title: string;
  description: string;
  date: Number;
  data?: Object;

  constructor(description: string) {
    this.title = 'Success';
    this.description = description;
    this.type = NOTIFICATION_TYPES.success;
    this.date = Date.now();
  }
}

export class AlertNotify implements INotify {
  type: string;
  title: string;
  description: string;
  date: Number;

  constructor(...args: Array<string>) {
    const title = args.length > 1 ? args[0] : 'Alert';
    this.title = title;
    this.description = args[args.length - 1];
    this.type = NOTIFICATION_TYPES.alert;
    this.date = Date.now();
  }
}

export class MessageNotify implements INotify {
  type: string;
  title: string;
  description: string;
  date: Number;

  constructor(...args: Array<string>) {
    const title = args.length > 1 ? args[0] : 'Message';
    this.title = title;
    this.description = args[args.length - 1];
    this.type = NOTIFICATION_TYPES.message;
    this.date = Date.now();
  }
}

export class WarningNotify implements INotify {
  type: string;
  title: string;
  description: string;
  date: Number;
  data?: Object;

  constructor(description: string, data?: Object) {
    this.title = 'Warning';
    this.description = description;
    this.type = NOTIFICATION_TYPES.warning;
    this.date = Date.now();
    this.data = data;
  }
}

export class ErrorNotify implements INotify {
  type: string;
  title: string;
  description: string;
  date: Number;
  data?: Object;

  constructor(description: string, data?: Object) {
    this.title = 'Error';
    this.description = description;
    this.type = NOTIFICATION_TYPES.error;
    this.date = Date.now();
    this.data = data;
  }
}
