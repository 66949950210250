import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImgSrcsetDirective } from './img-srcset.directive';

@NgModule({
  declarations: [ImgSrcsetDirective],
  imports: [
    CommonModule
  ],
  exports: [ImgSrcsetDirective]
})
export class ImgSrcsetModule { }
