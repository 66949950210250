import { environment } from '@bvl-admin/environments/environment';

export class ComponentEndpoint {
  public static componentsPagination = `${environment.API_URL}/trays/component/valuesCommon/list`;
  public static componentByCode = `${environment.API_URL}/trays/component/get/{componentCode}`;
  public static componentValue = `${environment.API_URL}/trays/component/commonValue/get/{componentCode}`;
  public static componentValueById = `${environment.API_URL}/trays/component/valueCommon/{componentCode}`;
  public static componentListByType = `${environment.API_URL}/trays/component/list/type/{type}`;
  public static saveComponentValue = `${environment.API_URL}/trays/component/save/valuecommon`;
  public static saveComponentValueById = `${environment.API_URL}/trays/component/save/valuecommon/id`;
}
