import { MODAL_BREAKPOINT } from '../constants/modal.constants';
import { IModalConfig, size } from './modal.interface';

export class ModalConfig implements IModalConfig {

  modalClass?: string;
  size?: size;
  showHeader?: boolean;
  headerClass?: string;
  hideHeader?: boolean;
  titleText?: string;
  showCloseButton?: boolean;
  closeButtonClass?: string;
  closeIconClass?: string;
  bodyClass?: string;
  showFooter?: boolean;
  footerClass?: string;
  showCancelButton?: boolean;
  cancelButtonClass?: string;
  cancelButtonText?: string;
  showConfirmButton?: boolean;
  confirmButtonClass?: string;
  confirmButtonText?: string;

  constructor() {
    this.modalClass = '';
    this.size = MODAL_BREAKPOINT.md;
    this.headerClass = '';
    this.showHeader = true;
    this.titleText = '';
    this.showCloseButton = true;
    this.closeButtonClass = '';
    this.closeIconClass = '';
    this.bodyClass = '';
    this.showFooter = true;
    this.footerClass = '';
    this.showCancelButton = true;
    this.cancelButtonClass = '';
    this.cancelButtonText = 'Cancelar';
    this.showConfirmButton = true;
    this.confirmButtonClass = '';
    this.confirmButtonText = 'Aceptar';
  }

}
