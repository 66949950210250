export const UserLang = {
  titles: {
    addUser: 'Añadir usuario',
    assignRole: 'Ahora asignale un rol'
  },
  descriptions: {
    addUserCms: 'Añade los usuarios que desees para que participen del CMS.'
  },
  labels: {
    role: 'Rol',
    user: 'Usuario'
  },
  buttons: {
    addUser: 'Agregar usuario'
  },
  links: {
    addUser: 'Agregar usuario',
    createRole: 'Crear rol'
  }
};
