import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BvlFormAlertComponent } from './form-alert.component';

@NgModule({
  declarations: [BvlFormAlertComponent],
  imports: [
    CommonModule
  ],
  exports: [BvlFormAlertComponent]
})
export class BvlFormAlertModule { }
