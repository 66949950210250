import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CollapseDirective } from './collapse.directive';

@NgModule({
  declarations: [CollapseDirective],
  imports: [
    CommonModule
  ],
  exports: [CollapseDirective]
})
export class CollapseModule { }
