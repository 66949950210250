import { Injectable } from '@angular/core';
import { SiteMenuEndpoint } from '@bvl-admin/endpoints';
import { ApiService } from '@bvl-core/shared/helpers/api';
import { ISiteMenu } from '@bvl-core/shared/helpers/util/menu';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteMenuService {

  constructor(
    private _apiService: ApiService
  ) { }

  getMenu(language: string, showSpin: boolean = true): Observable<Array<ISiteMenu>> {
    return this._apiService.get(SiteMenuEndpoint.getMenu, { params: { language }, preloader: showSpin });
  }

  addMenu(params: ISiteMenu, showSpin: boolean = true): Observable<ISiteMenu> {
    return this._apiService.post(SiteMenuEndpoint.addMenu, params, { preloader: showSpin });
  }

  updateMenu(params: ISiteMenu, showSpin: boolean = true): Observable<any> {
    return this._apiService.post(SiteMenuEndpoint.updateMenu, params, { preloader: showSpin });
  }

  deleteMenu(id: string, showSpin: boolean = true): Observable<any> {
    const params = { id };

    return this._apiService.post(SiteMenuEndpoint.deleteMenu, params, { preloader: showSpin });
  }

}
