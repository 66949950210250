import { Injectable } from '@angular/core';
import { FileEndpoint } from '@bvl-admin/endpoints';
import {
  FILE_TYPE,
  IFilePaginationRequest,
  IFilePaginationResponse,
  IMultimediaProperty,
  IMultimediaResponse,
  IRangeDateFile
} from '@bvl-admin/statemanagement/models/file.interface';
import { ApiService } from '@bvl-core/shared/helpers/api';
import { IBvlObservable, IBvlObservableEvent } from '@bvl-core/shared/helpers/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private rangeImage: IRangeDateFile;
  private rangeVideo: IRangeDateFile;
  private rangeDocument: IRangeDateFile;
  private rangeIcon: IRangeDateFile;

  constructor(
    private _apiService: ApiService
  ) { }

  getFilesByType(type: FILE_TYPE, params: IFilePaginationRequest): Observable<IFilePaginationResponse<IMultimediaResponse>> {
    switch (type) {
      case FILE_TYPE.document:
        return this.getDocuments(params);
      case FILE_TYPE.video:
        return this.getVideo(params);
      case FILE_TYPE.badge:
        return this.getIcons(params);
      default:
        return this.getImages(params);
    }
  }

  uploadFileByType(type: FILE_TYPE, file: File, properties?: IMultimediaProperty): IBvlObservableEvent<IMultimediaResponse> {
    switch (type) {
      case FILE_TYPE.document:
        return this.uploadDocuments(file);
      case FILE_TYPE.video:
        return this.uploadVideo(file);
      case FILE_TYPE.badge:
        return this.uploadIcons(file);
      default:
        return this.uploadImage(file, properties);
    }
  }

  rangeDateByType(type: string): Observable<IRangeDateFile> {
    switch (type) {
      case FILE_TYPE.document:
        return this.getRangeDocuments();
      case FILE_TYPE.video:
        return this.getRangeVideo();
      case FILE_TYPE.badge:
        return this.getRangeIcons();
      default:
        return this.getRangeImage();
    }
  }

  removeFileByType(type: string, id: string, showSpin: boolean = false): IBvlObservable<boolean> {
    switch (type) {
      case FILE_TYPE.document:
        return this.removeDocuments(id, showSpin);
      case FILE_TYPE.video:
        return this.removeVideo(id, showSpin);
      case FILE_TYPE.badge:
        return this.removeIcons(id, showSpin);
      default:
        return this.removeImage(id, showSpin);
    }
  }

  // image
  getImages(params: IFilePaginationRequest, showSpin: boolean = false): Observable<IFilePaginationResponse<IMultimediaResponse>> {
    return this._apiService
      .get<IFilePaginationResponse<IMultimediaResponse>>(FileEndpoint.imageFilters, { preloader: showSpin, params });
  }

  uploadImage(file: File, properties: IMultimediaProperty): IBvlObservableEvent<IMultimediaResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', file.type);

    Object.keys(properties || {})
      .forEach(key => {
        if (properties[key]) {
          formData.append(key, properties[key]);
        }
      });

    return this._apiService.postProgress<IBvlObservableEvent<IMultimediaResponse>>(FileEndpoint.image, formData, { reportProgress: true });
  }

  removeImage(id: string, showSpin: boolean = false): IBvlObservable<boolean> {
    return this._apiService.del<IBvlObservable<boolean>>(FileEndpoint.imageMutable, { preloader: showSpin, params: { id } });
  }

  getRangeImage(): Observable<IRangeDateFile> {
    if (this.rangeImage) { return of(this.rangeImage); }

    return this._apiService.get<IRangeDateFile>(FileEndpoint.imageDates)
      .pipe(map(preResponse => {
        this.rangeImage = preResponse;

        return preResponse;
      }));
  }

  updateImage(body: IMultimediaResponse, showSpin: boolean = false): IBvlObservable<boolean> {
    return this._apiService.put<IBvlObservable<IMultimediaResponse>>(
      FileEndpoint.image,
      body,
      { preloader: showSpin });
  }

  // Video
  getVideo(params: IFilePaginationRequest, showSpin: boolean = false): Observable<IFilePaginationResponse<IMultimediaResponse>> {
    return this._apiService
      .get<IFilePaginationResponse<IMultimediaResponse>>(FileEndpoint.videoFilters, { preloader: showSpin, params });
  }

  uploadVideo(file: File): IBvlObservableEvent<IMultimediaResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', file.type);

    return this._apiService.postProgress<IBvlObservableEvent<IMultimediaResponse>>(FileEndpoint.video, formData, { reportProgress: true });
  }

  removeVideo(id: string, showSpin: boolean = false): IBvlObservable<boolean> {
    return this._apiService.del<IBvlObservable<boolean>>(FileEndpoint.videoMutable, { preloader: showSpin, params: { id } });
  }

  getRangeVideo(): Observable<IRangeDateFile> {
    if (this.rangeVideo) { return of(this.rangeVideo); }

    return this._apiService.get<IRangeDateFile>(FileEndpoint.videoDates)
      .pipe(map(preResponse => {
        this.rangeVideo = preResponse;

        return preResponse;
      }));
  }

  // Documents
  getDocuments(params: IFilePaginationRequest, showSpin: boolean = false): Observable<IFilePaginationResponse<IMultimediaResponse>> {
    return this._apiService
      .get<IFilePaginationResponse<IMultimediaResponse>>(FileEndpoint.documentsFilters, { preloader: showSpin, params });
  }

  uploadDocuments(file: File): IBvlObservableEvent<IMultimediaResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', file.type);

    return this._apiService
      .postProgress<IBvlObservableEvent<IMultimediaResponse>>(FileEndpoint.documents, formData, { reportProgress: true });
  }

  removeDocuments(id: string, showSpin: boolean = false): IBvlObservable<boolean> {
    return this._apiService.del<IBvlObservable<boolean>>(FileEndpoint.documentsMutable, { preloader: showSpin, params: { id } });
  }

  getRangeDocuments(): Observable<IRangeDateFile> {
    if (this.rangeDocument) { return of(this.rangeDocument); }

    return this._apiService.get<IRangeDateFile>(FileEndpoint.documentsDates)
      .pipe(map(preResponse => {
        this.rangeDocument = preResponse;

        return preResponse;
      }));
  }

  // Icons
  getIcons(params: IFilePaginationRequest, showSpin: boolean = false): Observable<IFilePaginationResponse<IMultimediaResponse>> {
    return this._apiService
      .get<IFilePaginationResponse<IMultimediaResponse>>(FileEndpoint.iconsFilters, { preloader: showSpin, params });
  }

  uploadIcons(file: File): IBvlObservableEvent<IMultimediaResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', file.type);

    return this._apiService
      .postProgress<IBvlObservableEvent<IMultimediaResponse>>(FileEndpoint.icons, formData, { reportProgress: true });
  }

  removeIcons(id: string, showSpin: boolean = false): IBvlObservable<boolean> {
    return this._apiService.del<IBvlObservable<boolean>>(FileEndpoint.iconsMutable, { preloader: showSpin, params: { id } });
  }

  getRangeIcons(): Observable<IRangeDateFile> {
    if (this.rangeIcon) { return of(this.rangeIcon); }

    return this._apiService.get<IRangeDateFile>(FileEndpoint.iconsDates)
      .pipe(map(preResponse => {
        this.rangeIcon = preResponse;

        return preResponse;
      }));
  }

}
