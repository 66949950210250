import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AmountCellModule } from '../amount-cell/amount-cell.module';
import { ImageGalleryComponent } from './image-gallery.component';

@NgModule({
  imports: [
    AmountCellModule,
    CommonModule
  ],
  declarations: [ImageGalleryComponent],
  entryComponents: [ImageGalleryComponent],
  exports: [ImageGalleryComponent]
})
export class ImageGalleryModule { }
