import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'afterDate'
})
export class AfterDatePipe implements PipeTransform {
  transform(dates: Array<Date>, limitDate: Date): Array<Date> {
    return dates.filter(date => date.getTime() >= limitDate.getTime());
  }
}
