import { Directive, TemplateRef } from '@angular/core';

export let nextCarouselSlideId = 0;

@Directive({
  selector: 'ng-template[bvlCarouselSlide]'
})
export class CarouselSlideDirective {
  id = `bvl-carousel-slide-${nextCarouselSlideId++}`;
  constructor(public tplRef: TemplateRef<any>) { }
}
