import { Component, Input } from '@angular/core';

@Component({
  selector: 'bvl-tab-header',
  templateUrl: './tab-header.component.html'
})
export class BvlTabHeaderComponent {

  @Input() tabItemsList: Array<any>;

  constructor() { }

}
