export class ActiveModal {
  /**
   * Closes the modal with an optional 'result' value.
   * The 'ModalRef.result' promise will be resolved with provided value.
   */
  close(result?: any): void { }

  /**
   * Dismisses the modal with an optional 'reason' value.
   * The 'ModalRef.result' promise will be rejected with provided value.
   */
  dismiss(reason?: any): void { }

  /** Footer confirmButton */
  confirm(): any { }
}
