import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { UnsubscribeOnDestroy } from '@bvl-core/shared/helpers/util';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BvlAccordionGroupComponent } from '../accordion-group/accordion-group.component';

@Component({
  selector: 'bvl-accordion',
  templateUrl: './accordion.component.html'
})
export class BvlAccordionComponent extends UnsubscribeOnDestroy implements AfterContentInit {

  @ContentChildren(BvlAccordionGroupComponent) accordionGroups: QueryList<BvlAccordionGroupComponent>;

  @Input() customClass: string;

  protected _togglesSubscription: Array<Subscription>;

  constructor() {
    super();
    this._togglesSubscription = [];
  }

  ngAfterContentInit(): void {
    this.accordionGroups.changes
      .pipe(
        takeUntil(this.unsubscribeDestroy$)
      )
      .subscribe(response => {
        this._createAccordionGroups(response);
      });

    this._createAccordionGroups(this.accordionGroups);
  }

  private _createAccordionGroups(accordionGroups: QueryList<BvlAccordionGroupComponent>): void {
    this._togglesSubscription.forEach(toggleSubscription => toggleSubscription.unsubscribe());
    this._togglesSubscription = [];

    accordionGroups.toArray()
      .forEach((fv, fk) => {
        if (fv.accordionHeader) {
          if (fv.active) { fv.accordionContent.collapse = !fv.active; }
          const toggleSubscription = fv.accordionHeader.oToggle
                                      .pipe(
                                        takeUntil(this.unsubscribeDestroy$)
                                      )
                                      .subscribe(() => {
                                        this._toggle(fk);
                                      });
          this._togglesSubscription.push(toggleSubscription);
        }
      });

  }

  private _toggle(currentIndexGroup: number): void {
    this.accordionGroups.toArray()
      .forEach((fv, fk) => {
        if (fv.accordionContent) {
          const active = (fk !== currentIndexGroup)
                            ? false
                            : !fv.active;
          fv.active = active;
          fv.accordionContent.collapse = !active;
        }
      });
  }

}
