import { forwardRef, Input, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { coerceBooleanProp, UnsubscribeOnDestroy } from '../extra';

export abstract class FormControlsBase extends UnsubscribeOnDestroy implements ControlValueAccessor {

  protected _label: string;
  @Input()
  get label(): string {
    return this._label;
  }
  set label(value: string) {
    this._label = value || '';
  }

  protected _placeholder: string;
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value || '';
  }

  protected _model: any;
  @Input()
  get model(): any {
    return this._model;
  }
  set model(value: any) {
    this._model = value;
    this.propagateChange(this._model);
  }

  protected _error: boolean;
  @Input()
  get error(): boolean {
    return this._error;
  }
  set error(value: boolean) {
    this._error = coerceBooleanProp(value);
  }

  protected _readonly: boolean;
  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProp(value);
  }

  disabled: boolean;

  constructor() {
    super();
    this._label = '';
    this._placeholder = '';
    this.disabled = false;
    this._error = false;
  }

  /* Takes the value  */
  writeValue(value: any): void {
    if (value !== undefined) {
      this._model = value;
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void { }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}

// tslint:disable-next-line: only-arrow-functions
export function providerFormControlsBase(type: any): Provider {
  return {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line: no-forward-ref
    useExisting: forwardRef(() => type),
    multi: true
  };
}
