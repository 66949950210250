export const setFilterRequest = (body, filter, keep?) => {
  Object.keys(body)
  .forEach(key => {
    if (filter.type === key) {
      body[filter.type] = filter.value;
    } else if (!keep) {
      body[key] = typeof body[key] === 'string' ? '' : false;
    }
  });

  return body;
};
