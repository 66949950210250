import { NgModule } from '@angular/core';

import { EllipsisDirective } from './ellipsis.directive';

@NgModule({
    imports: [],
    declarations: [ EllipsisDirective ],
    exports: [ EllipsisDirective ]
})
export class EllipsisModule { }
