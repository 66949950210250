import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '../pipes/pipes.module';
import { DynamicTableComponent } from './dynamic-table.component';

@NgModule({
  declarations: [DynamicTableComponent],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [DynamicTableComponent],
  entryComponents: [DynamicTableComponent]
})
export class DynamicTableModule { }
