import { Injectable } from '@angular/core';
import { AngularUtil } from '@bvl-core/shared/helpers/util';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ParseFormatService extends NgbDateParserFormatter {

  constructor() {
    super();
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim()
      .split('-');
      if (dateParts.length === 1 && AngularUtil.isNumber(dateParts[0])) {
        return {year: AngularUtil.toInteger(dateParts[0]), month: null, day: null};
      } else if (dateParts.length === 2 && AngularUtil.isNumber(dateParts[0]) && AngularUtil.isNumber(dateParts[1])) {
        return {year: AngularUtil.toInteger(dateParts[0]), month: AngularUtil.toInteger(dateParts[1]), day: null};
      } else if (dateParts.length === 3 && AngularUtil.isNumber(dateParts[0]) && AngularUtil.isNumber(dateParts[1])
      && AngularUtil.isNumber(dateParts[2])) {
        return {
          year: AngularUtil.toInteger(dateParts[0]),
          month: AngularUtil.toInteger(dateParts[1]),
          day: AngularUtil.toInteger(dateParts[2])
        };
      }
    }

    return null;
  }

  format(date: NgbDateStruct): string {
    return date ?
        // tslint:disable-next-line:max-line-length
        `${AngularUtil.isNumber(date.day) ? AngularUtil.padNumber(date.day) : ''}-${AngularUtil.isNumber(date.month) ? AngularUtil.padNumber(date.month) : ''}-${date.year}` :
        '';
  }

}

export const PARSE_FORMAT_FACTORY = () => {
  return new ParseFormatService();
};
