import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, ViewEncapsulation } from '@angular/core';

import { AngularUtil, coerceBooleanProp, GaUnsubscribeBase, StringUtil } from '../extra';
import { IMenuLink } from './menu';

// @dynamic
@Component({
  selector: 'bvl-side-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
/** @dynamic */
export class MenuComponent extends GaUnsubscribeBase implements AfterViewInit {
  _inPreview: boolean;

  private _paddingTop = +window
    .getComputedStyle(this._document.querySelector('main > .page-section'), null)
    .getPropertyValue('padding-top')
    .replace('px', '');

  private _title: string;
  @Input()
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
    this._setItems();
  }

  links: Array<IMenuLink> = [];

  private _isDetail: boolean;
  @Input()
  get isDetail(): boolean {
    return this._isDetail;
  }
  set isDetail(value: boolean) {
    this._isDetail = coerceBooleanProp(value);
  }
  private _isButton: boolean;
  @Input()
  get isButton(): boolean {
    return this._isButton;
  }
  set isButton(value: boolean) {
    this._isButton = coerceBooleanProp(value);
  }
  @Input() textButton: string;
  @Input() urlButton: string;
  @Input() icon: string;

  @HostListener('document:section_header') documentSectionHeader(): void {
    setTimeout(this._setItems.bind(this), 10);
  }

  @HostListener('window:scroll', ['$event']) windowScroll(event: Event): void {
    if (this._inPreview) {
      return void 0;
    }

    const windowScroll = event.target['scrollingElement']
      ? event.target['scrollingElement'].scrollTop
      : (event.target as any).documentElement.scrollTop;

    this._setActive(windowScroll);

    if (window.innerWidth > 767) {
      this._toggleFixed(windowScroll);
    }

  }

  @HostListener('window:resize') windowResize(): void {
    if (window.innerWidth > 767) {
      this._setWidth();
    }
  }

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    protected _element: ElementRef
  ) {
    super(_element);
    this._inPreview = AngularUtil.hasClassName(this._document.body, 'admin');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._addNetworkComponent();
      if (window.innerWidth <= 767) {
        return void 0;
      }

      this._setItems();
      this._setWidth();
      setTimeout(this._setActive.bind(this), 10);

      if (!location.hash) {
        return void 0;
      }

      setTimeout(this.goToAnchor.bind(this, location.hash), 10);
    }, 200);
  }

  private _addNetworkComponent(): void {
    const networkElement = document.querySelector('bvl-networks');

    if (!networkElement) {
      const bvlNetworks = document.createElement('bvl-networks');
      const detailsElement = document.querySelector('.details-main');

      detailsElement.prepend(bvlNetworks);
    }
  }

  goToAnchor(anchor?: string): void {
    const title: HTMLElement = this._document.querySelector(anchor);
    window.scrollTo(0, title.offsetTop + 20);
  }

  goBack(): void {
    location.href = (() => {
      let splitedLocation = location.href.split('/');
      splitedLocation = splitedLocation.slice(0, splitedLocation.length - 1);

      return splitedLocation.join('/');
    })();
  }

  private _setItems(): void {
    const selector = `
      #page-content > bvl-section-header,
      #page-content > bvl-title,
      #page-content > .col-md-12 > bvl-title,
      #page-content admin-new-publication-popup > bvl-title,
      #page-content > .col-md-12 > admin-new-publication-popup > bvl-title
    `;
    const titles = this._document.querySelectorAll(selector);
    this.links = [];
    titles.forEach((element, index) => {
      const el = (element as HTMLElement).querySelector('.section-title');
      const title = el && el.textContent || '';
      const ancla = StringUtil.slugify(StringUtil.withoutDiacritics(title));
      element.setAttribute('id', ancla);
      this.links.push({
        title,
        url: `${location.origin}${location.pathname}${location.search || ''}#${ancla}`,
        id: `link-${index}`,
        anchor: `#${ancla}`
      });
    });
  }

  private _setActive(windowScroll: number = this._document.documentElement.scrollTop): void {
    if (this.links.length && !this._inPreview) {
      this.links.forEach((item, index) => {
        const menuItem = this._document.querySelector(`#${item.id}`);
        const title: HTMLElement = this._document.querySelector(item.anchor);
        const nextItem: HTMLElement = this.links[index + 1] ? this._document.querySelector(this.links[index + 1].anchor) : null;
        menuItem.classList[
          item &&
            windowScroll >= (index === 0 ? 0 : (title.offsetTop + 18)) &&
            (nextItem ? windowScroll <= (nextItem.offsetTop + 18) : true)
            ? 'add'
            : 'remove'
        ]('active');
      });
    }
  }

  private _toggleFixed(windowScroll: number = this._document.documentElement.scrollTop): void {
    const menu: HTMLElement = this._document.querySelector('bvl-menu > .menu, bvl-side-menu > .menu');
    const scrollByContent = windowScroll - this._paddingTop;
    const isMenuAtBottom = scrollByContent >= (this._getContentHeight() - menu.offsetHeight) - (menu.offsetHeight / 4);
    menu.classList[isMenuAtBottom ? 'add' : 'remove']('bottom');
  }

  private _getHeaderHeight(): number {
    const header: HTMLElement = this._document.querySelector(`
      ${this._inPreview
        ? '.g-admin-editor-header'
        : '#header'}`
    );
    const subMenu: HTMLElement = this._document.querySelector('bvl-navigation-submenu');
    const headerHeight = header.offsetHeight + (this._inPreview
      ? 100
      : subMenu.offsetHeight);

    if (header) {
      if (subMenu) {
        return headerHeight + this._paddingTop;
      }

      return headerHeight + this._paddingTop;
    } else {
      return this._paddingTop;
    }
  }

  private _getContentHeight(): number {
    let contentHeight = this._document.getElementById('page-content').offsetHeight - this._getHeaderHeight() + 80;
    if (this.isButton) {
      contentHeight = contentHeight - 50;
    }

    return contentHeight;
  }
  private _setWidth(): void {
    const menu = this._document.querySelector('bvl-menu, bvl-side-menu');
    if (menu) {
      const parent = menu.parentElement;
      const parentWidth = parent.getBoundingClientRect().width;
      const parentStyles = window.getComputedStyle(parent);
      const childMenu = this._document.querySelector('bvl-menu .menu, bvl-side-menu > .menu');

      (childMenu as HTMLElement).style.width = `${parentWidth - parseInt(parentStyles.paddingRight, null)}px`;
    }
  }
}
