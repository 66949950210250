import { Component, Input } from '@angular/core';

import { coerceBooleanProp } from '../extra/utils/angular';

@Component({
  selector: 'bvl-amount-cell',
  templateUrl: './amount-cell.component.html',
  styleUrls: ['./amount-cell.component.scss']
})
export class AmountCellComponent {
  private _noIcons: boolean;
  @Input() variation: number;
  @Input()
  get noIcons(): boolean { return this._noIcons; }
  set noIcons(value: boolean) { this._noIcons = coerceBooleanProp(value); }
}
