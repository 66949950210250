import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, Renderer2 } from '@angular/core';

import { coerceBooleanProp } from '../../extra';
import { FormControlsBase, providerFormControlsBase } from '../form-controls.base';

@Component({
  selector: 'bvl-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  providers: [providerFormControlsBase(BvlFormSelectComponent)]
})
export class BvlFormSelectComponent extends FormControlsBase implements OnInit {

  protected _class: string;
  @HostBinding('attr.class')
  @Input()
  get class(): string {
    return this._class;
  }
  set class(value: string) {
    this._class = `${this._class} ${(value || '')}`.trim();
  }

  protected _error: boolean;
  @Input()
  get error(): boolean {
    return this._error;
  }
  set error(value: boolean) {
    this._error = coerceBooleanProp(value);
    this._toggleClass(this._error, 's-form-error');
  }

  protected _dataList: Array<any>;
  @Input()
  get dataList(): Array<any> {
    return this._dataList;
  }
  set dataList(value: Array<any>) {
    this._dataList = value || [];
    if (this._dataList.length && this._model) {
      const model = this._dataList.find(fv => fv[this.valueField] === this._model[this.valueField]);
      if (!model) {
        setTimeout(() => {
          this._setModel('');
        }, 0);
      }
    }
  }

  @Input() valueField: string;
  @Input() textField: string;

  // tslint:disable-next-line: no-output-named-after-standard-event
  @Output() change: EventEmitter<any>;

  private _baseClass: string;
  private _oldModel: any;
  private _toggleList: boolean;

  constructor(
    private _elementRef: ElementRef,
    private _renderer2: Renderer2,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.change = new EventEmitter<any>();
    this._baseClass = 's-form-select';
    this._class = this._baseClass;
    this._toggleList = false;
    this._model = '';
  }

  private _setModel(value: any): void {
    this._model = value;
    this.propagateChange(this._model);
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit(): void { }

  private _toggleClass(b: boolean, className: string): void {
    const actionType = (b)
                        ? 'addClass'
                        : 'removeClass';
    this._renderer2[actionType](this._elementRef.nativeElement, className);
  }

  toggleList(): void {
    this._toggleList = !this._toggleList;
    this._toggleClass(this._toggleList, `${this._baseClass}--click`);
  }

  autoClose(): void {
    this._toggleList = false;
    this._toggleClass(this._toggleList, `${this._baseClass}--click`);
  }

  selectItem(event: any, value: any): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this._setModel(value);
    this.autoClose();
    this.change.emit();
  }

}
