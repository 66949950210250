import { Component, Input } from '@angular/core';

import { FormControlsBase, providerFormControlsBase } from '../form-controls.base';

@Component({
  selector: 'bvl-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [providerFormControlsBase(BvlFormInputComponent)]
})
export class BvlFormInputComponent extends FormControlsBase {

  protected _type: string;
  @Input()
  get type(): string {
    return this._type;
  }
  set type(type: string) {
    this._type = type || 'text';
  }

  constructor() {
    super();
    this._type = 'text';
  }

}
