export const BUTTON_ACTIONS = {
  cancel: 'cancel',
  acept: 'acept'
};

export enum MODAL_BREAKPOINT {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl'
}
