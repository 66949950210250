import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '@bvl-admin/services';
import { ModalService } from '@bvl-core/shared/helpers/modal';
import { INotify, NotificationService } from '@bvl-core/shared/helpers/notification';
import { UnsubscribeOnDestroy } from '@bvl-core/shared/helpers/util';
import { takeUntil } from 'rxjs/operators';

export abstract class LayoutBase extends UnsubscribeOnDestroy implements OnInit {

  static lastNotification = 0;

  notification: INotify;
  activeNotify: boolean;

  constructor(
    protected _notificationService: NotificationService,
    protected _route: ActivatedRoute,
    protected _configurationService: ConfigurationService,
    protected _modalService: ModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this._setConfiguration();
    this._getNotifies();
  }

  private _setConfiguration(): void {
    const userPermissions = this._route.snapshot.data.userPermissions,
          userProfile = this._configurationService.getUserProfile();

    userProfile.fullname = userPermissions.fullname;
    this._configurationService.setUserProfile(userProfile);
    this._configurationService.setUserPermissions(userPermissions);
  }

  private _getNotifies(): void {
    this._notificationService.getNotifies()
      .pipe(
        takeUntil(this.unsubscribeDestroy$)
      )
      .subscribe((response: Array<INotify>) => {
        const isActiveModal = this._modalService.isActiveModal();
        if (response.length > LayoutBase.lastNotification && !isActiveModal) {
          LayoutBase.lastNotification = response.length;
          this.notification = response[response.length - 1];
          this.activeNotify = true;
          setTimeout(() => {
            this.activeNotify = false;
          }, 2000);
        }
      });
  }

}
