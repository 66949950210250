import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { INotify, NotificationService } from './../../../helpers/notification';
import { UnsubscribeOnDestroy } from './../../../helpers/util';
import { BUTTON_ACTIONS, MODAL_BREAKPOINT } from './../constants/modal.constants';
import { IModalConfig } from './../models/modal.interface';

@Component({
  selector: 'shared-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent extends UnsubscribeOnDestroy implements OnInit {

  @Output() buttonActionEvent = new EventEmitter();

  activeModal: Boolean;
  notification: INotify;
  config: IModalConfig;

  notifyActive: boolean;

  BUTTON_ACTIONS = BUTTON_ACTIONS;
  MODAL_BREAKPOINT = MODAL_BREAKPOINT;

  constructor(
    private _notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit(): void {
    this._getNotifies();
  }

  /**
   * Emit output buttonActionEvent to subscribe it in the modalRef (models/modal-ref)
   */
  buttonAction(actionType: string): void {
    this.buttonActionEvent.emit(actionType);
  }

  private _getNotifies(): void {
    this._notificationService.getNotifies()
      .pipe(
        takeUntil(this.unsubscribeDestroy$)
      )
      .subscribe((response: Array<INotify>) => {
        if (response.length && this.activeModal) {
          this.notification = response[response.length - 1];
          this.notifyActive = true;
          setTimeout(() => {
            this.notifyActive = false;
          }, 2000);
        }
        this.activeModal = true;
      });
  }

}
