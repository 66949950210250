import { CommonModule, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';

import localeEsPe from './es-PE';

registerLocaleData(localeEsPe, 'es-PE');

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-PE'
    }
  ]
})
export class I18nEsPeModule { }
