import { ElementRef } from '@angular/core';

import { coerceBooleanProp } from '../helpers';
import { Constructor } from './constructor';

export interface ICanBlock {
  block: Block;
}

export interface IHasElementRef {
  _elementRef: ElementRef;
}

export type Block = boolean;

export const mixinBlock = <T extends Constructor<IHasElementRef>>(base: T): Constructor<ICanBlock> & T => {
  return class extends base {
    private _block: Block;

    get block(): Block {
      return this._block;
    }
    set block(value: Block) {
      const block = coerceBooleanProp(value);
      if (this._block !== block) {
        if (block) {
          this._elementRef.nativeElement.classList.add('g-block');
        } else {
          this._elementRef.nativeElement.classList.remove('g-block');
        }

        this._block = block;
      }
    }

    constructor(...args: Array<any>) {
      super(...args);
    }
  };
};
