import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Renderer2
} from '@angular/core';

/**
 * Directive to truncate the contained text, if it exceeds the element's boundaries
 * and append characters '…'.
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ellipsis]'
})
export class EllipsisDirective implements OnChanges, OnDestroy, AfterViewInit {

  private originalText: string;
  private elem: HTMLElement;
  private innerElem: HTMLElement;
  private ellipsisWordBoundaries = '[ \n,.]';
  private ellipsisCharacters = '…';
  private applyOnWindowResize = true;

  @Input() ellipsisContent: string;

  /**
   * Utility method to quickly find the largest number for
   * which `callback(number)` still returns true.
   * @param  max      Highest possible number
   * @param  callback Should return true as long as the passed number is valid
   * @return          Largest possible number
   */
  private static numericBinarySearch(max: number, callback: (n: number) => boolean): number {
    let low = 0;
    let high = max;
    let best = -1;
    let mid: number;

    while (low <= high) {
      // tslint:disable-next-line:no-bitwise
      mid = ~~((low + high) / 2);
      const result = callback(mid);
      if (!result) {
        high = mid - 1;
      } else {
        best = mid;
        low = mid + 1;
      }
    }

    return best;
  }

  /**
   * Escape html special characters
   * @param unsafe string potentially containing special characters
   * @return       escaped string
   */
  private static escapeHtml(unsafe: string): string {
    unsafe = unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');

    return unsafe;
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone
  ) { }

  /**
   * Angular's init view life cycle hook.
   * Initializes the element for displaying the ellipsis.
   */
  ngAfterViewInit(): void {

    // store the original contents of the element:
    this.elem = this.elementRef.nativeElement;
    if (this.ellipsisContent) {
      this.originalText = EllipsisDirective.escapeHtml(this.ellipsisContent);
    } else if (!this.originalText) {
      this.originalText = this.elem.innerText;
    }

    // add a wrapper div (required for resize events to work properly):
    this.renderer.setProperty(this.elem, 'innerHTML', '');
    this.innerElem = this.renderer.createElement('div');
    this.renderer.addClass(this.innerElem, 'ngx-ellipsis-inner');
    const text = this.renderer.createText(this.originalText);
    this.renderer.appendChild(this.innerElem, text);
    this.renderer.appendChild(this.elem, this.innerElem);

    // start listening for resize events:
    this.addResizeListener(true);
  }

  /**
   * Angular's change life cycle hook.
   * Change original text (if the ellipsis-content has been passed)
   * and re-render
   */
  ngOnChanges(): void {
    if (!this.elem
      || typeof this.ellipsisContent === 'undefined'
      || this.originalText === EllipsisDirective.escapeHtml(this.ellipsisContent)) {
      return;
    }

    this.originalText = EllipsisDirective.escapeHtml(this.ellipsisContent);
    this.applyEllipsis();
  }

  /**
   * Angular's destroy life cycle hook.
   * Remove event listeners
   */
  ngOnDestroy(): void {
    this.removeResizeListener();
  }

  /**
   * Set up an event listener to call applyEllipsis() whenever a resize has been registered.
   * The type of the listener (window/element) depends on the resizeDetectionStrategy.
   * @param triggerNow=false if true, the ellipsis is applied immediately
   */
  private addResizeListener(triggerNow = false): void {
    this.applyOnWindowResize = true;
    if (triggerNow) {
      this.applyEllipsis();
    }
  }

  @HostListener('window:resize') onResize(): void {
    this.ngZone.run(() => {
      if (this.applyOnWindowResize) {
        this.applyEllipsis();
      }
    });
  }

  /**
   * Stop listening for any resize event.
   */
  private removeResizeListener(): void {
    this.applyOnWindowResize = false;
  }

  /**
   * Get the original text's truncated version. If the text really needed to
   * be truncated, this.ellipsisCharacters will be appended.
   * @param max the maximum length the text may have
   * @return string       the truncated string
   */
  private getTruncatedText(max: number): string {
    if (!this.originalText || this.originalText.length <= max) {
      return this.originalText;
    }

    const truncatedText = this.originalText.substr(0, max);
    if (this.originalText.charAt(max)
      .match(this.ellipsisWordBoundaries)) {
      return truncatedText + this.ellipsisCharacters;
    }

    let i = max - 1;
    while (i > 0 && !truncatedText.charAt(i)
      .match(this.ellipsisWordBoundaries)) {
      i--;
    }

    return truncatedText.substr(0, i) + this.ellipsisCharacters;
  }

  /**
   * Set the truncated text to be displayed in the inner div
   * @param max the maximum length the text may have
   * @param addMoreListener=false listen for click on the ellipsisCharacters if the text has been truncated
   */
  private truncateText(max: number): void {
    const text = this.getTruncatedText(max);
    this.renderer.setProperty(this.innerElem, 'innerHTML', text);
  }

  /**
   * Display ellipsis in the inner div if the text would exceed the boundaries
   */
  private applyEllipsis(): any {
    // Remove the resize listener as changing the contained text would trigger events:
    this.removeResizeListener();

    // Find the best length by trial and error:
    const maxLength = EllipsisDirective.numericBinarySearch(this.originalText.length, curLength => {
      this.truncateText(curLength);

      return !this.isOverflowing;
    });

    // Apply the best length:
    this.truncateText(maxLength);

    // Re-attach the resize listener:
    this.addResizeListener();
  }

  /**
   * Whether the text is exceeding the element's boundaries or not
   */
  private get isOverflowing(): boolean {
    // Enforce hidden overflow (required to compare client width/height with scroll width/height)
    const currentOverflow = this.elem.style.overflow;
    if (!currentOverflow || currentOverflow === 'visible') {
      this.elem.style.overflow = 'hidden';
    }

    const isOverflowing = this.elem.clientWidth < this.elem.scrollWidth - 1 || this.elem.clientHeight < this.elem.scrollHeight - 1;

    // Reset overflow to the original configuration:
    this.elem.style.overflow = currentOverflow;

    return isOverflowing;
  }
}
