import { DatePipe } from '@angular/common';

interface IDateFormat {
  format: string;
  regex: any;
  separator?: string;
  position?: IDateFormatPosition;
}

interface IDateFormatPosition {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
  marker: number;
}

const DATE_FORMATS: Array<IDateFormat> = [
  {
    format: 'yyyy-MM-dd HH:mm:ss',
    regex: /^(\d{4})-(\d{1,2})-(\d{1,2})(\s|T)(\d{2}):(\d{2}):(\d{2})/,
    position: {
      year: 1,
      month: 2,
      day: 3,
      hour: 5,
      minute: 6,
      second: 7,
      marker: -1
    }
  },
  {
    format: 'dd-MM-yyyy HH:mm:ss',
    regex: /^(\d{1,2})-(\d{1,2})-(\d{4})(\s|T)(\d{2}):(\d{2}):(\d{2})/,
    position: {
      year: 3,
      month: 2,
      day: 1,
      hour: 5,
      minute: 6,
      second: 7,
      marker: -1
    }
  },
  {
    format: 'yyyy-MM-dd.hh.mm. ss',
    regex: /^(\d{4})-(\d{1,2})-(\d{1,2}).(\d{1,2}).(\d{1,2}).\s(\d{1,2})/,
    position: {
      year: 1,
      month: 2,
      day: 3,
      hour: 4,
      minute: 5,
      second: 6,
      marker: -1
    }
  },
  {
    format: 'yyyy-MM-dd',
    regex: /^(\d{4})-(\d{1,2})-(\d{1,2})/,
    position: {
      year: 1,
      month: 2,
      day: 3,
      hour: -1,
      minute: -1,
      second: -1,
      marker: -1
    }
  },
  {
    format: 'dd/MM/yyyy HH:mm:ss',
    regex: /^(\d{1,2})\/(\d{1,2})\/(\d{4})(\s|T)(\d{2}):(\d{2}):(\d{2})/,
    position: {
      year: 3,
      month: 2,
      day: 1,
      hour: 5,
      minute: 6,
      second: 7,
      marker: -1
    }
  },
  {
    format: 'dd/MM/yyyy HH:mm AM/PM',
    regex: /^(\d{1,2})\/(\d{1,2})\/(\d{4})(\s|T)(\d{2}):(\d{2})\s(am|pm|AM|PM)/,
    position: {
      year: 3,
      month: 2,
      day: 1,
      hour: 5,
      minute: 6,
      second: -1,
      marker: 7
    }
  },
  {
    format: 'dd/MM/yyyy HH:mm',
    regex: /^(\d{1,2})\/(\d{1,2})\/(\d{4})(\s|T)(\d{2}):(\d{2})/,
    position: {
      year: 3,
      month: 2,
      day: 1,
      hour: 5,
      minute: 6,
      second: -1,
      marker: -1
    }
  },
  {
    format: 'dd/MM/yyyy',
    regex: /^(\d{1,2})\/(\d{1,2})\/(\d{4})/,
    position: {
      year: 3,
      month: 2,
      day: 1,
      hour: -1,
      minute: -1,
      second: -1,
      marker: -1
    }
  }
];

// @dynamic
export class DateUtil {

  constructor(public datePipe: DatePipe) { }

  public static DATE_FORMAT = {
    ddMMyyyy: {
      format: 'dd-MM-yyyy',
      regex: /^([0-2][0-9]|3[0-1])(\/|-)(0[1-9]|1[0-2])\2(\d{4})$/,
      separator: '-'
    }
  };

  public static dateFormat(format: string): IDateFormat {
    const dateFormat = Object.keys(DateUtil.DATE_FORMAT)
      .find((fv, fk) => {
        return DateUtil.DATE_FORMAT[fv].format === format;
      });

    return DateUtil.DATE_FORMAT[dateFormat];
  }

  public static dateToString(date: Date, format: string): string {
    const datePipe = new DatePipe('es-PE');

    return datePipe.transform(date, format) || '';
  }

  public static validateFormat(date: Date | string, format: string): boolean {
    const dateFormat = DateUtil.dateFormat(format),
      stringDate = (typeof date === 'string')
        ? date
        : DateUtil.dateToString(date, format);

    return (dateFormat && dateFormat.regex)
      ? dateFormat.regex.test(stringDate)
      : false;
  }

  public static setDefaulDate(dates?: Array<any>): Array<any> {
    const datePipe = new DatePipe('en-US');
    const mapDates = dates || ['', ''];
    const reversed = [1, 0];

    mapDates.forEach((item, index) => {
      mapDates[index] = datePipe.transform(item
        ? new Date(item)
        : new Date().setMonth(new Date().getMonth() - reversed[index]), 'yyyy-MM-dd');
    });

    return mapDates;
  }

  public static dateRange(startDate: string, endDate: string): Array<string> {
    const start = startDate.split('-');
    const end = endDate.split('-');
    const startYear = parseInt(start[0], 10);
    const endYear = parseInt(end[0], 10);
    const dates = [];

    for (let i = startYear; i <= endYear; i++) {
      const endMonth = i !== endYear ? 11 : parseInt(end[1], 10) - 1;
      const startMon = i === startYear ? parseInt(start[1], 10) - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        const month = j + 1;
        const displayMonth = month < 10 ? `0${month}` : month;
        dates.push([i, displayMonth, '01'].join('-'));
      }
    }

    return dates;
  }

  public static splitDate(date: string): object {
    return date.includes('/') ? date.split('/') : date.split('-');
  }

  public static stringDDMMYYYYToDate(date: string | any): Date {
    if (!date) { return null; }
    const buff = this.splitDate(date);

    return new Date([buff[1], buff[0], buff[2]].join('/'));

  }

  public static stringYYYYMMDDToDate(date: string | any): Date {
    if (!date) { return null; }
    const buff = this.splitDate(date);

    return new Date(parseInt(buff[0], 10), parseInt(buff[1], 10) - 1, parseInt(buff[2], 10));
  }

  public static toISOStringToDate(date: string | any): Date {
    return new Date(date);
  }

  public static subtractMonth(date: Date, months: number): Date {
    const newDate = new Date(date);
    const newMonth = newDate.getMonth() - months;
    const lastDayNewMonth = new Date(newDate.getFullYear(), newMonth + 1, 0);

    if (newDate.getDate() > lastDayNewMonth.getDate()) {
      newDate.setDate(lastDayNewMonth.getDate());
    }
    newDate.setMonth(newMonth);

    return newDate;
  }

  public static convertStringToDate(date: string): Date {
    if (date) {
      const format = DATE_FORMATS.find(f => !!date.match(f.regex));
      if (format) {
        const values = date.match(format.regex);

        return this.getDate(values, format.position, true);
      }
    }

    return null;
  }

  public static convertStringToDateTime(date: string): Date {
    if (date) {
      const format = DATE_FORMATS.find(f => !!date.match(f.regex));
      if (format) {
        const values = date.match(format.regex);

        return this.getDate(values, format.position);
      }
    }

    return null;
  }

  public static getTimeOfString(date: string): number {
    const format = DATE_FORMATS.find(f => !!date.match(f.regex));
    if (format) {
      const values = date.match(format.regex);

      return this.getDate(values, format.position)
      .getTime();
    }

    return 0;
  }

  public static getDateTimeStringFormat(date: string, separator = '/'): string {
    if (date) {
      const format = DATE_FORMATS.find(f => !!date.match(f.regex));
      if (format) {
        const values = date.match(format.regex);

        const dateS = [values[format.position.day], values[format.position.month], values[format.position.year]].join(separator);
        const timeS = [
          format.position.hour === -1 ? '00' : values[format.position.hour],
          format.position.minute === -1 ? '00' : values[format.position.minute],
          format.position.second === -1 ? '00' : values[format.position.second]
        ].join(':');

        return `${dateS} ${timeS}`;
      }
    }

    return null;
  }

  public static getDateStringFormat(date: string, separator = '/'): string {
    if (date) {
      const format = DATE_FORMATS.find(f => !!date.match(f.regex));
      if (format) {
        const values = date.match(format.regex);

        return [values[format.position.day], values[format.position.month], values[format.position.year]].join(separator);
      }
    }

    return null;
  }

  public static getTimeStringFormat(date: string, separator = ':'): string {
    if (date) {
      const format = DATE_FORMATS.find(f => !!date.match(f.regex));
      if (format) {
        const values = date.match(format.regex);

        return [
          format.position.hour === -1 ? '00' : values[format.position.hour],
          format.position.minute === -1 ? '00' : values[format.position.minute],
          format.position.second === -1 ? '00' : values[format.position.second]
        ].join(separator);
      }
    }

    return null;
  }

  public static getDate(values: Array<any>, position: IDateFormatPosition, onlyDate = false): Date {
    return new Date(
      +values[position.year],
      +values[position.month] - 1,
      +values[position.day],
      onlyDate ? 0 : getValueTime(position.hour, values, position.marker),
      onlyDate ? 0 : getValueTime(position.minute, values),
      onlyDate ? 0 : getValueTime(position.second, values)
    );
  }

}

// tslint:disable-next-line:max-line-length
export const getValueTime = (time: number, values: Array<any>, marker = -1): number => (time < 0 ? 0 : (+values[time] + (marker !== -1 && /pm/gi.test(values[marker]) ? 12 : 0)));
export const getCurrentDateISO = (): string => (((new Date()).toISOString()).substr(0, 10));
export const getDateISO = (date: Date): string => ((date.toISOString()).substr(0, 10));
export const getLastDayOfMonth = (): Date => {
  const now = new Date();

  return new Date(now.getFullYear(), now.getMonth() + 1, 0);
};

export const getMonthsAgo = (month: number): Date => {
  const now = new Date();

  return new Date(now.getFullYear(), now.getMonth() - month);
};

export const getDefaultStartDate = (): Date => (new Date(1999, 6));

export const monthNames =
  ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'];

export const formatMMM = (month: any): string => {
    if (!month) {
        return '';
    }

    return monthNames[Number(month) - 1];
};
