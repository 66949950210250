import { Component, OnInit } from '@angular/core';
import { FormControlsBase, providerFormControlsBase } from '@bvl-core/ui/common';

@Component({
  selector: 'bvl-switch',
  templateUrl: './switch.component.html',
  providers: [providerFormControlsBase(BvlSwitchComponent)]
})
export class BvlSwitchComponent extends FormControlsBase implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void { }

}
