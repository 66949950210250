import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { AngularUtil, FILES_PATH, getStorageLanguage, LANGUAGES } from '../extra';
import { WITHOUT_DATA_TYPE } from '../without-data';

@Component({
  selector: 'bvl-table-download-file',
  templateUrl: './table-download-file.component.html'
})
export class TableDownloadFileComponent implements OnInit {

  WITHOUT_DATA_TYPE = WITHOUT_DATA_TYPE;

  TABLE_FILES = 'downloadFiles';
  TEXT = LANGUAGES[getStorageLanguage()];

  protected _class: string;
  @HostBinding('attr.class')
  @Input()
  get class(): string {
    return this._class;
  }
  set class(value: string) {
    this._class = `${this._class} ${(value || '')}`.trim();
  }

  @Input() header: Array<string>;

  protected _tableData: Array<any>;
  @Input()
  get tableData(): Array<any> {
    return this._tableData;
  }
  set tableData(value: Array<any>) {
    this._tableData = (value || []).map((fv, fk) => {
      return Object.keys(fv)
        .reduce((previous, current, index) => {
          const item = (this.header[index] && current !== this.TABLE_FILES)
                        ? { index: 0, current: this.header[index]}
                        : { index: 1, current};
          previous[item.index][item.current] = fv[current];

          return previous;
        }, [{}, {}]);
    });
  }

  @Input() message: string;

  constructor() {
    this._class = 'd-block g-site-table-download';
    this.message = 'En este momento, no hay datos disponibles';
  }

  ngOnInit(): void { }

  isArray(item: any): boolean {
    return AngularUtil.isArray(item);
  }

  isObject(item: any): boolean {
    return AngularUtil.isObject(item);
  }

  getLinkAction(value: string): string {
    return `${FILES_PATH}${value}`;
  }

}
