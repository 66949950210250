import { Component } from '@angular/core';

import { FormControlsBase, providerFormControlsBase } from '../form-controls.base';

@Component({
  selector: 'bvl-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  providers: [providerFormControlsBase(BvlFormCheckboxComponent)]
})
export class BvlFormCheckboxComponent extends FormControlsBase {

  constructor() {
    super();
  }

}
