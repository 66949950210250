import { ElementRef, Input } from '@angular/core';
import { IGaPropertie } from '../../models/seo-ga.model';
import { EVENTS_CONTROLS, GA_EVENT_NAME } from '../../settings/constants';
import { UnsubscribeOnDestroy } from '../unsubscribe-on-destroy';

export abstract class GaUnsubscribeBase extends UnsubscribeOnDestroy {
  private _ga: Array<IGaPropertie> = null;
  @Input()
  get ga(): Array<IGaPropertie> {
    return this._ga;
  }

  set ga(value: Array<IGaPropertie>) {
    this._ga = typeof value === 'string' ? JSON.parse(value) : value || [];
  }

  constructor(protected _elementRef: ElementRef) {
    super();
  }

  onDomChange(_elementChange: ElementRef, data?: any): void {
    if (this.ga && this.ga.length) {
      const ctrl = _elementChange.nativeElement as HTMLElement;
      const ctrlName = _elementChange.nativeElement.getAttribute('ctrl');
      const gaCtrl = this.ga.filter(p => (p.control === ctrlName && !!EVENTS_CONTROLS.find(ec => ec === p.controlAction)));
      if (gaCtrl.length) {
        const isSelect = ctrl.constructor === HTMLSelectElement;
        gaCtrl.forEach(ga => {
          const action = isSelect ? 'change' : ga.controlAction;
          ctrl.addEventListener(action, (event: Event) => {
            const comp = (event.target as HTMLElement).closest('[keyc]');
            if (event && event.target && event.target['nodeName'] === 'A' && event.target['target'] !== '_blank') {
              const el = event.target as HTMLAnchorElement;
              if (el.href) {
                event.preventDefault();
                setTimeout(() => {
                  window.location.href = el.href;
                }, 500);
              }
            }
            let dataTarget = {};
            if (event && event.target && ['INPUT', 'SELECT'].filter(e => e === event.target['nodeName']).length) {
              dataTarget = {
                value: event.target['value'],
                checked: event.target['checked']
              };

              if (event.target['nodeName'] === 'SELECT') {
                dataTarget['label'] = event.target['options'][event.target['selectedIndex']]['label'];
              }
            }

            let dataAttr = {};
            if (event && event.target && event.target['attributes'] && event.target['attributes'].length) {
              dataAttr = Object.values<{ name: string, value: string }>(event.target['attributes'])
                .filter(attr => attr.name.match(/^data-/))
                .reduce((pre, attr, index) => {
                  pre[attr.name.split('-')[1]] = attr.value;

                  return pre;
                }, {});
            }

            setTimeout(() => {
              const custom = new CustomEvent(GA_EVENT_NAME, {
                detail: {
                  ga,
                  event,
                  ctrl,
                  comp,
                  data: { ...dataTarget, ...dataAttr, ...(data || {}) }
                }
              });
              document.dispatchEvent(custom);
            }, 0);
          });
        });
      }
    }
  }

  addEvent(ga: IGaPropertie, data?: any): void {
    const compKey = this._elementRef.nativeElement.closest('[keyc]');
    const comp = compKey || this._elementRef.nativeElement as HTMLElement;

    if (ga.control) {
      const ctrl = comp.querySelector(`[ctrl=${ga.control}]`);
      const custom = new CustomEvent(GA_EVENT_NAME, {
        detail: {
          ga,
          ctrl,
          comp,
          data
        }
      });
      document.dispatchEvent(custom);
    }
  }
}

export abstract class GaCustomUnsubscribeBase extends GaUnsubscribeBase {
  constructor(protected _elementRef: ElementRef) {
    super(_elementRef);
  }

  abstract setEventsGA(): void;
}

export abstract class GaBase {
  private _ga: Array<IGaPropertie> = null;
  @Input()
  get ga(): Array<IGaPropertie> {
    return this._ga;
  }

  set ga(value: Array<IGaPropertie>) {
    this._ga = value && JSON.parse(value.toString()) || [];
    this.setEvents();
  }

  @Input() keyc: string;

  constructor() {

  }

  setEvents(): void {
    if (this.ga && this.ga.length) {
      this.ga.forEach(ga => {
        // console.log(ga)
      });
    }
  }
}
