import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { IBackgroundImage } from '../../component';
import { PLATFORMS, PlatformService } from '../../extra';

@Directive({
  selector: '[bvlImgSrcset]'
})
export class ImgSrcsetDirective implements OnInit {

  @Input() bvlImgSrcset: IBackgroundImage;

  constructor(
    private _elementRef: ElementRef,
    private _platformService: PlatformService,
    private _renderer2: Renderer2
  ) { }

  ngOnInit(): void {
    this._renderer2.setAttribute(
      this._elementRef.nativeElement,
      'srcset',
      `${this.bvlImgSrcset.large.imageUri}, ${this.bvlImgSrcset.medium.imageUri}, ${this.bvlImgSrcset.small.imageUri}`
    );

    const sizeImage = (this._platformService.is(PLATFORMS.IE))
                        ? 'large'
                        : 'small';
    this._renderer2.setAttribute(this._elementRef.nativeElement, 'src', this.bvlImgSrcset[sizeImage].imageUri);
  }

}
