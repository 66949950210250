import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

  transform(value: Array<any>, field?: string): Array<any> {
    if (!value) {
      return null;
    }

    const group = value.reduce((prev, cur) => {
      if (!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }

      return prev;
    }, {});

    const groupBy = Object.keys(group)
    .map(fv => {
      return {
        groupCode: fv,
        groupItems: group[fv]
      };
    });

    return groupBy;
  }

}
