import { IGeneralSearchRequest, IGeneralSearchResponse } from './general.interface';

export interface IListImageResponse {
  name: string;
  small: IImageDetailResponse;
  medium: IImageDetailResponse;
  large: IImageDetailResponse;
  selected?: boolean;
}

export interface IImageDetailResponse {
  imageUri: string;
  size: number;
  height: number;
  width: number;
}

export interface IListFileResponse {
  dateCreate: string;
  dateUpdate: string;
  id: string;
  mimeType: string;
  name: string;
  status: string;
  username: string;
}

export interface IMultimediaResponse extends IBadgeImage {
  id: string;
  name: string;
  properties: IMultimediaProperty;
  small: IMultimediaDetailResponse;
  medium: IMultimediaDetailResponse;
  large: IMultimediaDetailResponse;
  selected?: boolean;
  mimeType?: string;
  url?: string;
  uri?: string;
  size?: string;
}

export interface IMultimediaDetailResponse {
  imageUri: string;
  size: number;
  height: number;
  width: number;
}

export interface IBadgeImage {
  height: number;
  width: number;
}

export interface IMultimediaProperty {
  title: string;
  alt: string;
  description: string;
}

export interface ITmpImage {
  small?: { imageUri?: string };
  medium?: { imageUri?: string };
  large?: { imageUri?: string };
  progress?: number;
  name?: string;
  size?: number;
  width?: number;
  height?: number;
  mimeType?: string;
}

export interface IFilePaginationRequest extends IGeneralSearchRequest {
  value?: string;
  date?: string;
}

export interface IFilePaginationResponse<T> extends IGeneralSearchResponse {
  content: Array<T>;
}

export interface IRangeDateFile {
  dateMin: string;
  dateMax: string;
}

export enum FILE_TYPE {
  document = 'document',
  image = 'image',
  video = 'video',
  badge = 'badge',
  icon = 'badge'
}

export enum FILE_RESPONSE {
  string = 'string',
  object = 'object'
}
