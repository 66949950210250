export { SiteMenuEndpoint } from './site-menu.endpoint';
export { CommentEndpoint } from './comment.endpoint';
export { ComponentEndpoint } from './component.endpoint';
export { GeneralEndpoint } from './general.endpoint';
export { PageEndpoint } from './page.endpoint';
export { PermissionEndpoint } from './permission.endpoint';
export { RoleEndpoint } from './role.endpoint';
export { UserEndpoint } from './user.endpoint';
export { AuthEndpoint } from './auth.endpoint';
export { FileEndpoint } from './file.endpoint';
export { RedirectEndpoint } from './redirect.endpoint';
