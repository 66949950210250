import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { GaUnsubscribeBase } from '../extra';
import { TabComponent } from './tab.component';

@Component({
  selector: 'bvl-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent extends GaUnsubscribeBase implements AfterContentInit {

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  protected _class: string;
  @HostBinding('attr.class')
  @Input()
  get class(): string {
    return this._class;
  }
  set class(value: string) {
    this._class = value;
  }

  @Output() selectTabChange: EventEmitter<string>;

  mSelectTab: any;

  constructor(
    protected _element: ElementRef
  ) {
    super(_element);
    this._class = '';
    this.selectTabChange = new EventEmitter<string>();
  }

  setEventsGA(): void { }

  ngAfterContentInit(): void {
    this.tabs.changes
      .pipe(
        takeUntil(this.unsubscribeDestroy$)
      )
      .subscribe(response => {
        response.first.active = false;
        this._createTabs();
      });

    this._createTabs();
  }

  private _createTabs(): void {
    const activeTabs = this.tabs.find(fv => fv.active);

    this.selectTab((activeTabs)
                    ? activeTabs
                    : this.tabs.first,
                  false);
  }

  selectTab(tab: any, isSelectTabChange = true): void {
    this.tabs.toArray()
      .forEach(fv => fv.active = false);

    tab.active = true;
    this.mSelectTab = tab;
    if (isSelectTabChange) {
      this.selectTabChange.emit(tab.title);
    }
  }

}
