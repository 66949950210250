import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { FILES_PATH, GaUnsubscribeBase } from '../extra';

@Component({
  selector: 'bvl-tooltip',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolTipComponent extends GaUnsubscribeBase implements OnInit {
  constructor(protected _element: ElementRef) {
    super(_element);
  }
  @Input() data: any;
  @Input() typetip: string;
  @Input() placement: string;

  ngOnInit(): void {
    this._resetTooltip();
  }
  checkType(value): string {
    return typeof value;
  }
  private _resetTooltip(): void {
    const placeSelector = document.querySelector(this.placement);
    placeSelector.insertAdjacentElement('afterbegin', this._element.nativeElement);
    this._element.nativeElement.querySelector('.tooltip').style.display = 'none';
  }

  getPathFile(item: any): string {
    return `${FILES_PATH}${item.path}`;
  }
}
