import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PopoverModule } from '../popover/popover.module';
import { LabelComponent } from './label.component';

@NgModule({
    imports: [ CommonModule, PopoverModule ],
    declarations: [ LabelComponent ],
    exports: [ LabelComponent ]
})
export class LabelModule { }
