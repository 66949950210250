import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EllipsisModule } from '../ellipsis/ellipsis.module';
import { ApiModule, DomChangeDirectiveModule, I18nEsPeModule } from '../extra';
import { MediaPlayerModule } from '../media-player';
import { PipesModule } from '../pipes';
import { ProfileProvidersModule } from '../profile/profile-providers.module';
import { WatchlistProvidersModule } from '../watchlist-providers/public-api';
import { EntryComponent } from './entry.component';

@NgModule({
  imports: [
    CommonModule,
    EllipsisModule,
    PipesModule,
    ApiModule,
    I18nEsPeModule,
    WatchlistProvidersModule.forRoot(),
    ProfileProvidersModule.forRoot(),
    DomChangeDirectiveModule,
    MediaPlayerModule
  ],
  declarations: [EntryComponent],
  exports: [EntryComponent]
})
export class EntryModule { }
