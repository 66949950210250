import { Component, OnInit } from '@angular/core';
import { FormControlsBase, providerFormControlsBase } from '../form-controls.base';

@Component({
  selector: 'bvl-form-textarea',
  templateUrl: './form-textarea.component.html',
  providers: [providerFormControlsBase(BvlFormTextareaComponent)]
})
export class BvlFormTextareaComponent extends FormControlsBase implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void { }

}
