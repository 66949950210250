import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input
} from '@angular/core';

import {
  Block,
  ColorPalette,
  Disabled,
  ICanBlock,
  ICanDisable,
  ICanTheme,
  mixinBlock,
  mixinDisabled,
  mixinTheme,
  ThemePalette
} from '@bvl-core/ui/common';

export class ButtonBase {
  constructor(public _elementRef: ElementRef) {
    this.init();
  }

  init(): void {
    this._elementRef.nativeElement.classList.add('g-admin-btn');
  }
}

export const _ButtonMixinBase = mixinBlock(mixinDisabled(mixinTheme(ButtonBase)));

@Component({
  selector: 'button[bvl-button], a[bvl-button]',
  templateUrl: './button.component.html',
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BvlButtonComponent extends _ButtonMixinBase implements ICanTheme, ICanDisable, ICanBlock {

  @Input() theme: ThemePalette = 'primary';
  @Input() color: ColorPalette;
  @Input() block: Block;
  @Input() disabled: Disabled;

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }

}
