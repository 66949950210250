import { Component, Input } from '@angular/core';

@Component({
  selector: 'bvl-form-alert',
  templateUrl: './form-alert.component.html'
})
export class BvlFormAlertComponent {
  @Input() type: 'success' | 'error' = 'error';

  constructor() { }

}
