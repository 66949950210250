import { Injectable } from '@angular/core';
import { RoleEndpoint } from '@bvl-admin/endpoints';
import {
  IDeleteRoleRequest,
  IRolePaginationRequest,
  IRolePaginationResponse,
  IRolePermissionsDetailResponse,
  IRolePermissionsDetailView,
  IRoleResponse,
  ISaveRoleRequest,
  IUpdateRoleRequest
} from '@bvl-admin/statemanagement/models/role.interface';
import { ApiService } from '@bvl-core/shared/helpers/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  roleBehaviorSubject: BehaviorSubject<IRolePermissionsDetailView>;

  constructor(
    private _apiService: ApiService
  ) {
    this.roleBehaviorSubject = new BehaviorSubject<IRolePermissionsDetailView>(null);
  }

  rolesList(showSpin: boolean = false): Observable<Array<IRoleResponse>> {
    return this._apiService.get(RoleEndpoint.rolesList, { preloader: showSpin });
  }

  rolesListPermissionsDetail(showSpin: boolean = false): Observable<Array<IRolePermissionsDetailResponse>> {
    return this._apiService.get(RoleEndpoint.rolesListPermissionsDetail, { preloader: showSpin });
  }

  rolesPagination(params: IRolePaginationRequest, showSpin: boolean = false): Observable<IRolePaginationResponse> {
    return this._apiService.post(RoleEndpoint.rolesListPermissionsDetail, params, { preloader: showSpin });
  }

  updateRole(params: IUpdateRoleRequest, showSpin: boolean = false): Observable<any> {
    return this._apiService.put(RoleEndpoint.updateRole, params, { preloader: showSpin });
  }

  deleteRole(params: IDeleteRoleRequest, showSpin: boolean = false): Observable<any> {
    return this._apiService.post(RoleEndpoint.deleteRole, params, { preloader: showSpin });
  }

  setRolesList(actionType: string, role: IRolePermissionsDetailView): void {
    role.actionType = actionType;
    this.roleBehaviorSubject.next(role);
  }

  updateRolesList(): BehaviorSubject<IRolePermissionsDetailView> {
    return this.roleBehaviorSubject;
  }

  saveRole(params: ISaveRoleRequest, showSpin: boolean = false): Observable<any> {
    return this._apiService.post(RoleEndpoint.saveRole, params, { preloader: showSpin });
  }

}
