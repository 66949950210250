import { ElementRef } from '@angular/core';

import { Constructor } from './constructor';

export interface ICanColor {
  color: ColorPalette;
}

export interface IHasElementRef {
  _elementRef: ElementRef;
}

export type ColorPalette = 'primary' | undefined;

export const mixinColor = <T extends Constructor<IHasElementRef>>(
  base: T,
  defaultColor?: ColorPalette
): Constructor<ICanColor> & T => {

  return class extends base {

    private _color: ColorPalette;

    get color(): ColorPalette { return this._color; }
    set color(value: ColorPalette) {
      const colorPalette = value || defaultColor;

      if (colorPalette !== this._color) {

        if (this._color) {
          this._elementRef.nativeElement.classList.remove(`g-admin-btn-${this._color}`);
        }
        if (colorPalette) {
          this._elementRef.nativeElement.classList.add(`g-admin-btn-${colorPalette}`);
        }

        this._color = colorPalette;
      }
    }

    constructor(...args: Array<any>) {
      super(...args);

      this.color = defaultColor;
    }
  };
};
