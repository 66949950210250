import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, PermissionGuard } from '@bvl-admin/guards';
import { EditorLayoutComponent } from '@bvl-admin/layouts/editor-layout/editor-layout.component';
import { LoginLayoutComponent } from '@bvl-admin/layouts/login-layout/login-layout.component';
import { MainLayoutComponent } from '@bvl-admin/layouts/main-layout/main-layout.component';
import { TYPES_CODE } from '@bvl-admin/settings/constants/general.constant';
import { MENU_CODE } from '@bvl-admin/settings/constants/menu.constant';
import { UserPermissionsResolve } from '../providers/resolve';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'view-editor-test-dev',
    loadChildren: '../main/views/view-editor/view-editor.module#ViewEditorModule'
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: '../main/views/login/login.module#LoginModule'
      }
    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    resolve: {
      userPermissions: UserPermissionsResolve
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: '../main/views/home/home.module#HomeModule'
      },
      {
        path: 'menu-tray',
        data: {
          menuCode: MENU_CODE.menuTray
        },
        canActivate: [PermissionGuard],
        loadChildren: '../main/views/menu-tray/menu-tray.module#MenuTrayModule'
      },
      {
        path: 'approval-tray',
        data: {
          menuCode: MENU_CODE.approvalTray
        },
        canActivate: [PermissionGuard],
        loadChildren: '../main/views/approval-tray/approval-tray.module#ApprovalTrayModule'
      },
      {
        path: 'page-tray',
        data: {
          menuCode: MENU_CODE.pageTray
        },
        canActivate: [PermissionGuard],
        loadChildren: '../main/views/page-tray/page-tray.module#PageTrayModule'
      },
      {
        path: 'users-roles',
        data: {
          menuCode: MENU_CODE.usersRoles
        },
        canActivate: [PermissionGuard],
        loadChildren: '../main/views/users-roles/users-roles.module#UsersRolesModule'
      },
      {
        path: 'component-tray',
        data: {
          menuCode: MENU_CODE.componentTray
        },
        canActivate: [PermissionGuard],
        children: [
          {
            path: 'common',
            data: {
              type: TYPES_CODE.common
            },
            loadChildren: '../main/views/component-tray/component-tray.module#ComponentTrayModule'
          },
          {
            path: 'popup',
            data: {
              type: TYPES_CODE.popup
            },
            loadChildren: '../main/views/component-tray/component-tray.module#ComponentTrayModule'
          }
        ]
        // loadChildren: '../main/views/component-tray/component-tray.module#ComponentTrayModule'
      },
      {
        path: 'categories',
        data: {
          menuCode: MENU_CODE.categories
        },
        canActivate: [PermissionGuard],
        loadChildren: '../main/views/categories/categories.module#CategoriesModule'
      },
      {
        path: 'seo-configuration',
        data: {
          menuCode: MENU_CODE.seoConfiguration
        },
        // canActivate: [PermissionGuard],
        loadChildren: '../main/views/seo-configuration/seo-configuration.module#SeoConfigurationModule'
      }
    ]
  },
  {
    path: 'editor',
    component: EditorLayoutComponent,
    resolve: {
      userPermissions: UserPermissionsResolve
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'create',
        children: [
          {
            path: 'publication',
            loadChildren: '../main/views/editor/create-publication/create-publication.module#CreatePublicationModule'
          }
        ]
      },
      {
        path: 'edit',
        children: [
          {
            path: 'common-component/:componentCode',
            data: {
              type: TYPES_CODE.common
            },
            loadChildren: '../main/views/editor/common-component/common-component.module#CommonComponentModule'
          },
          {
            path: 'popup-component/:componentCode',
            data: {
              type: TYPES_CODE.popup
            },
            loadChildren: '../main/views/editor/common-component/common-component.module#CommonComponentModule'
          },
          {
            path: 'publication/:idPage',
            loadChildren: '../main/views/editor/publication/publication.module#PublicationModule'
          }
        ]
      },
      {
        path: 'see',
        children: [
          {
            path: 'publication/:idPage',
            data: {
              seePublication: true
            },
            loadChildren: '../main/views/editor/publication/publication.module#PublicationModule'
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class RoutesModule { }
