import { NgModule } from '@angular/core';

import { TopScrollbarDirective } from './top-scrollbar.directive';

@NgModule({
    imports: [],
    declarations: [TopScrollbarDirective ],
    exports: [TopScrollbarDirective ]
})
export class TopScollbarModule { }
