export const getTimeStamp = (): string => {
  const date = new Date();

  return (date.getTime() + Math.floor((Math.random() * 100) + 1)).toString();
};

export const removeEmpty = <T>(obj: any): T =>
  Object.keys(obj)
    .filter(k => obj[k] != null) // Remove undef. and null.
    .reduce(
      (newObj, k) =>
        typeof obj[k] === 'object'
          ? { ...newObj, [k]: removeEmpty(obj[k]) } // Recurse.
          : { ...newObj, [k]: obj[k] }, // Copy value.
      {}
    ) as T;
