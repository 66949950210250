import { Component, HostBinding, Input } from '@angular/core';
import { coerceBooleanProp } from '@bvl-core/ui/common/helpers';

@Component({
  selector: 'bvl-card-cell',
  templateUrl: './card-cell.component.html'
})
export class CardCellComponent {

  @HostBinding('attr.class') attrClass = 'g-admin-card-info';

  @Input() label: string;

  protected _borderRight: boolean;
  @Input()
  get borderRight(): boolean {
    return this._borderRight;
  }
  set borderRight(value: boolean) {
    this._borderRight = coerceBooleanProp(value);
    if (this._borderRight) {
      this.attrClass +=  ` ${this.attrClass}-border`;
    }
  }

  constructor() { }

}
