import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoginLayoutModule, MainLayoutModule } from '@bvl-admin/layouts';
import { EditorLayoutModule } from '@bvl-admin/layouts/editor-layout/editor-layout.module';
import { RoutesModule } from '@bvl-admin/routes/routes.module';
import { MODAL_CONFIG, SPINNER_CONFIG, SWAL_CONFIG } from '@bvl-admin/settings/constants/general.constant';
import { ApiModule } from '@bvl-core/shared/helpers/api';
import { AuthenticationModule } from '@bvl-core/shared/helpers/authentication';
import { ModalModule } from '@bvl-core/shared/helpers/modal';
import { SpinnerModule } from '@bvl-core/shared/helpers/spinner';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ApiModule,
    LoginLayoutModule,
    MainLayoutModule,
    RoutesModule,
    SweetAlert2Module.forRoot(SWAL_CONFIG),
    AuthenticationModule,
    EditorLayoutModule,
    ModalModule.forRoot(MODAL_CONFIG),
    SpinnerModule.forRoot(SPINNER_CONFIG)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
