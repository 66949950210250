import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ConfigurationService } from '@bvl-admin/services';
import { IUserPermissionsResponse } from '@bvl-admin/statemanagement/models/configuration.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsResolve implements Resolve<any> {

  constructor(
    private _configurationService: ConfigurationService
  ) { }

  resolve(): Observable<IUserPermissionsResponse> {
    return this._configurationService.userPermissions(true);
  }
}
