import { Component } from '@angular/core';
import { FormControlsBase, providerFormControlsBase } from '@bvl-core/ui/common';

@Component({
  selector: 'bvl-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css'],
  providers: [providerFormControlsBase(BvlTextareaComponent)]
})
export class BvlTextareaComponent extends FormControlsBase {

  constructor() {
    super();
  }

}
