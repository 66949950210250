import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WithoutResultsDirective } from './without-results.directive';
import { WithoutResultsComponent } from './without-results/without-results.component';

@NgModule({
  declarations: [WithoutResultsComponent, WithoutResultsDirective],
  imports: [
    CommonModule
  ],
  entryComponents: [WithoutResultsComponent],
  exports: [WithoutResultsDirective]
})
export class WithoutResultsModule { }
