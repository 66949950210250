import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    private _authenticationService: AuthenticationService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userToken: string = this._authenticationService.getUserToken();

    if (userToken) {
      const tokenReq = req.clone({ setHeaders: { Authorization: `Bearer ${userToken}` } });

      return next.handle(tokenReq);
    } else {
      return next.handle(req);
    }
  }

}
