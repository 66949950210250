import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  Injector,
  Optional,
  Renderer2,
  RendererFactory2
} from '@angular/core';
import { AngularUtil } from './../../../helpers/util';
import { SpinnerConfig } from './../models/spinner-config';
import { IContainer } from './../models/spinner.interface';
import { SpinnerComponent } from './../spinner/spinner.component';

// @dynamic
@Injectable()
export class SpinnerService {

  private _renderer2: Renderer2;
  private _spinnerConfig: SpinnerConfig;
  private _spinnerRef: ComponentRef<SpinnerComponent>;
  private _spinnerRefs: Array<ComponentRef<SpinnerComponent>>;
  private _bodyTagNames: Array<string>;

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private _document: Document,
    private _rendererFactory2: RendererFactory2,
    @Optional() spinnerConfig: SpinnerConfig
  ) {
    this._renderer2 = this._rendererFactory2.createRenderer(null, null);
    const defaultSpinnerConfig = new SpinnerConfig();
    this._spinnerConfig = { ...defaultSpinnerConfig, ...(spinnerConfig || {}) };
    this._spinnerRefs = [];
    this._bodyTagNames = [];
  }

  private _getContainer(elementRef: any): IContainer {
    return (elementRef)
            ? { element: elementRef.nativeElement, class: 'shared-spinner-container' }
            : { element: this._document.body, class: 'shared-spinner-open' };
  }

  private _isBody(container: IContainer): boolean {
    return container.element.tagName === 'BODY';
  }

  showSpinner(elementRef?: any): void {
    const container = this._getContainer(elementRef);
    if (this._isBody(container)) { this._bodyTagNames.push(container.element.tagName); }

    if (!AngularUtil.hasClassName(container.element, 'shared-spinner-open')) {
      // 1. Create a component reference from the component
      this._spinnerRef = this._componentFactoryResolver
                          .resolveComponentFactory(SpinnerComponent)
                          .create(this._injector);
      // instance configuration
      this._spinnerRef.instance.config = this._spinnerConfig;
      this._spinnerRef.changeDetectorRef.detectChanges();
      // 2. Attach component to the appRef so that it's inside the ng component tree
      this._applicationRef.attachView(this._spinnerRef.hostView);
      // 3. Get DOM element from component
      const spinnerElement = this._spinnerRef.location.nativeElement;
      // set class by container
      this._renderer2.addClass(container.element, container.class);
      // 4. Append DOM element to the body or containerElement
      container.element.appendChild(spinnerElement);
      // add spinnerRef
      this._spinnerRefs.push(this._spinnerRef);
    }
  }

  private _canHideSpinner(container: any): boolean {
    const isBody = this._isBody(container);
    if (isBody) { this._bodyTagNames.splice(0, 1); }

    return (!isBody || !this._bodyTagNames.length) && !!this._spinnerRefs.length;
  }

  hideSpinner(elementRef?: any): void {
    const container = this._getContainer(elementRef);
    if (this._canHideSpinner(container)) {
      // get spinnerRef
      const indexSpinnerRef = this._spinnerRefs.findIndex(fv => {
        return container.element.contains(fv.location.nativeElement);
      });
      const spinnerRef = this._spinnerRefs[indexSpinnerRef];
      // 1. remove DOM spinnerElement from container
      container.element.removeChild(spinnerRef.location.nativeElement);
      // 2. detach spinnerRef inside the ng component tree
      this._applicationRef.detachView(spinnerRef.hostView);
      // 3. destroy spinnerRef
      spinnerRef.destroy();
      // removeClass
      this._renderer2.removeClass(container.element, container.class);
      // remove item spinnerRef
      this._spinnerRefs.splice(indexSpinnerRef, 1);
    }
  }

}
