import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BvlLinkComponent } from './link.component';

@NgModule({
  declarations: [BvlLinkComponent],
  imports: [
    CommonModule
  ],
  exports: [BvlLinkComponent]
})
export class BvlLinkModule { }
