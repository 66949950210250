import { Component, Input, OnInit } from '@angular/core';
import { coerceBooleanProp } from '@bvl-core/ui/common/helpers';

@Component({
  selector: 'bvl-accordion-content',
  templateUrl: './accordion-content.component.html'
})
export class BvlAccordionContentComponent implements OnInit {

  protected _collpase: boolean;
  @Input()
  get collapse(): boolean {
    return this._collpase;
  }
  set collapse(value: boolean) {
    this._collpase = coerceBooleanProp(value);
  }

  constructor() {
    this._collpase = true;
  }

  ngOnInit(): void { }

}
