import { Injectable } from '@angular/core';
import { MENU } from '@bvl-admin/settings/constants/menu.constant';
import { STORAGE_KEY } from '@bvl-admin/settings/constants/storage-key.constant';
import { IMenu } from '@bvl-admin/statemanagement/models/general.interface';
import { StorageService } from '@bvl-core/shared/helpers/util';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menu: Array<IMenu>;

  constructor(
    private _configurationService: ConfigurationService,
    private _storageService: StorageService
  ) { }

  generateMenu(): Array<IMenu> {
    let menu = this.getMenu();
    if (!menu || !menu.length) {
      const userPermissions = this._configurationService.getUserPermissions();
      menu = MENU.filter((fv, fk) => {
        return userPermissions.permissionList.find((sv, sk) => {
          return fv.code === sv.permissionCode;
        });
      });
      this.setMenu(menu);
    }

    return menu;
  }

  getMenu(): Array<IMenu> {
    return this._storageService.getItemObject(STORAGE_KEY.menu);
  }

  setMenu(menu: Array<IMenu>): void {
    this._storageService.setItem(STORAGE_KEY.menu, menu);
  }

}
