import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[bvlSliderItem]'
})
export class SliderItemDirective {

  constructor(
    public tpl: TemplateRef<any>
  ) { }

}
