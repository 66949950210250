import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WithoutDataComponent } from './without-data.component';

@NgModule({
  declarations: [WithoutDataComponent],
  imports: [
    CommonModule
  ],
  exports: [WithoutDataComponent]
})
export class WithoutDataModule { }
