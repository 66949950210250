import { Injectable } from '@angular/core';
import { StorageService } from './../../helpers/util';
import { USER_TOKEN_KEY } from './authentication.constant';

@Injectable()
export class AuthenticationService {

  constructor(
    private _storageService: StorageService
  ) { }

  setUserToken(token: string): void {
    this._storageService.setItem(USER_TOKEN_KEY, token);
  }

  getUserToken(): string {
    return this._storageService.getItem(USER_TOKEN_KEY);
  }

  isAuthenticated(): boolean {
    return !!this._storageService.getItem(USER_TOKEN_KEY);
  }

}
