export const PageLang = {
  titles: {
    chooseTemplateYouWantAndStartEdit: 'Escoge la plantilla que deseas y empieza a editar.'
  },
  labels: {
    searchPages: 'Buscar páginas'
  },
  descriptions: {
    staticPageCanNotEdit: 'Esta página es estática, no se puede editar.'
  },
  links: {
    createPublication: 'Crear publicación'
  }
};
