import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { MediaChange, ObservableMedia } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

import { coerceBooleanProp, DateUtil, GaUnsubscribeBase, getStorageLanguage, LANGUAGES } from '../extra';
import { IToolbarByTypeFilter, IToolbarDateFilter, IToolbarNemonics, ToolbarCapability } from './toolbar';

export let nextToolbarId = 0;

@Component({
  selector: 'bvl-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class ToolbarComponent extends GaUnsubscribeBase implements OnInit, OnDestroy, OnChanges {

  id = `bvl-toolbar-${nextToolbarId++}`;

  private _mediaWatcher: Subscription;
  private _activeFilters: Array<{ type: ToolbarCapability; value: any }> = [];
  private _lastEmitedSearch = '';

  @Input() capabilities: Array<ToolbarCapability> = [];
  @Input() byTypeFilter: IToolbarByTypeFilter;
  @Input() dateFilter: IToolbarDateFilter;
  @Input() nemonicsData: IToolbarNemonics;
  @Input() periods: IToolbarByTypeFilter;
  @Input() yearRange: boolean;
  @Input() yearLabel: string;
  @Input() alignClass: string;
  @Input() onlyOneFilter = false;
  @Input() periodByYear = false;
  @Input() placeholder = '';
  protected _toolBarFilterIsVisible = true;
  @Input()
  get toolBarFilterIsVisible(): boolean {
    return this._toolBarFilterIsVisible;
  }
  set toolBarFilterIsVisible(value: boolean) {
    this._toolBarFilterIsVisible = coerceBooleanProp(value);
  }

  @Output() activeFilters = new EventEmitter<any>();

  toolbarCapabilities: Array<ToolbarCapability>;
  searchTerm = '';
  searchFocused = false;
  searchInputShowed = true;
  period: number | null = null;
  selectedType: number | null = null;
  sortByLocation = false;
  location: Coordinates;
  startDate: Date;
  endDate: Date;
  fromDate: Date;
  toDate: Date;
  nemonics: Array<string>;
  nemonic: string;
  years = this._setYearRange();
  year = new Date().getFullYear();
  TEXT = LANGUAGES[getStorageLanguage()];
  periodAccount: any;
  periodBy = [
    {
      name: `-`,
      value: ''
    },
    {
      name: `${this.TEXT.general.first} ${this.TEXT.general.trimester}`,
      value: '1'
    },
    {
      name: `${this.TEXT.general.second} ${this.TEXT.general.trimester}`,
      value: '2'
    },
    {
      name: `${this.TEXT.general.third} ${this.TEXT.general.trimester}`,
      value: '3'
    },
    {
      name: `${this.TEXT.general.fourth} ${this.TEXT.general.trimester}`,
      value: '4'
    }
  ];

  constructor(
    public _element: ElementRef,
    public media: ObservableMedia,
    public datePipe: DatePipe
  ) {
    super(_element);
  }

  ngOnInit(): void {
    this.selectedType = this.byTypeFilter && this.byTypeFilter.options ? +this.byTypeFilter.options[0].id : 0;
    this.period = this.periods && this.periods.options ? +this.periods.options[0].value : 0;
    this._setToolbarCapabilities();
    this._mediaWatcher = this.media.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'xs' || change.mqAlias === 'sm') {
        if (!this.searchTerm.length) {
          this.searchInputShowed = false;
        }
      } else {
        this.searchInputShowed = true;
      }
    });
    if (this.dateFilter) {
      this._setDates();
    }
    this.periodAccount = this.periodBy[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nemonicsData && changes.nemonicsData.currentValue[0] !== undefined) {
      this.nemonics = changes.nemonicsData.currentValue[0];
      this.nemonic = changes.nemonicsData.currentValue[1] ? changes.nemonicsData.currentValue[1] : this.nemonics[0];
    }
  }

  onNemonicChange(value: string): void {
    this._addActiveFilter('filterByNemonic', value);
  }

  onSearch(onlyIfEmpty = false): void {
    if (onlyIfEmpty && this.searchTerm.length) {
      return;
    }
    if (!this.searchTerm && !this._lastEmitedSearch) {
      return;
    }
    if (this.searchTerm === this._lastEmitedSearch) {
      return;
    }
    this._lastEmitedSearch = this.searchTerm;

    this._addActiveFilter('search', this.searchTerm);
  }
  getSelectedLabel(id, object): string {
    const findObject = object.find(item => item.id === id);

    return findObject ? findObject.label : '';
  }
  openDatePicker(id): void {
    const datePicker = this[id].element.nativeElement.querySelectorAll(`input[ngbdatepicker]`)[0];
    datePicker.click();
  }
  dateChange(value, model): void {
    this._addActiveFilter('filterByDate', [
      this.datePipe.transform(this.startDate, 'yyyy-MM-dd'),
      this.datePipe.transform(this.endDate, 'yyyy-MM-dd')
    ]);
  }
  onTypeChange(): void {
    if (this.selectedType === null) {
      this._resetType();
    } else {
      this._addActiveFilter('type', this.selectedType);
    }
  }
  onPeriodChange(period: number): void {
    this._addActiveFilter('period', period);
  }
  onPeriodAccountChange(periodAccount: any): void {
    this._addActiveFilter('periodAccount', periodAccount.value);
  }
  onYearChange(year: number): void {
    this._addActiveFilter('year', year);
  }

  isCapableOf(capability): boolean {
    if (this.toolbarCapabilities && this.toolbarCapabilities.length) {
      return this.toolbarCapabilities.indexOf(capability) !== -1;
    }

    return false;
  }

  setFocusToElement(elementRef): void {
    elementRef.focus();
  }

  toggleSearch(): void {
    this.searchInputShowed = !this.searchInputShowed;
  }

  resetSearch(): void {
    this.searchTerm = '';
    this.onSearch(true);
  }

  ngOnDestroy(): void {
    this._mediaWatcher.unsubscribe();
  }

  toggleLocation(): void {
    this.sortByLocation = !this.sortByLocation;
    if (this.sortByLocation) {
      this.resetSearch();
      this._getUserCoordinates();
    } else {
      this._resetLocation();
    }
  }

  areDatesInSameMonth(date1: Date, date2: Date): boolean {
    date1 = new Date(date1.getFullYear(), date1.getMonth());
    date2 = new Date(date2.getFullYear(), date2.getMonth());

    return date1.getTime() === date2.getTime();
  }

  onDateChange(init, end): void {
    this._addActiveFilter('filterByDate', DateUtil.setDefaulDate([init, end]));
  }

  private _setYearRange(): Array<number> {
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => +start + i * step);

    return range(currentYear, currentYear - 20, -1);
  }

  private _resetLocation(): void {
    this.sortByLocation = false;
    this.location = null;
    this._removeActiveFilter('sortByLocation');
  }

  private _resetType(): void {
    this._removeActiveFilter('type');
  }

  private _setToolbarCapabilities(): void {
    this.toolbarCapabilities = this.capabilities;
    this.toolbarCapabilities =
      this.byTypeFilter && this.byTypeFilter.options && (this.byTypeFilter.options as any).length
        ? [...this.toolbarCapabilities, 'type']
        : this.toolbarCapabilities.filter(cap => cap !== 'type');

    this.toolbarCapabilities = this.dateFilter
      ? [...this.toolbarCapabilities, 'filterByDate']
      : this.toolbarCapabilities.filter(cap => cap !== 'filterByDate');
  }

  private _addActiveFilter(type: ToolbarCapability, value: string | number | Coordinates | Array<string>): void {
    this._activeFilters = this.onlyOneFilter
      ? { type, value } as any
      : [...this._activeFilters.filter(f => f.type !== type), { type, value }];

    this.activeFilters.emit(this._activeFilters);
  }

  private _removeActiveFilter(type: ToolbarCapability): void {
    const previousActiveFilters = this._activeFilters;

    if (this.onlyOneFilter) {
      this._activeFilters = {} as any;
    } else {
      this._activeFilters = this._activeFilters.filter(f => f.type !== type);

      if (previousActiveFilters.length === this._activeFilters.length) {
        return;
      }
    }

    this.activeFilters.emit(this._activeFilters);
  }

  private _getUserCoordinates(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this._setPosition.bind(this), err => { });
    } else {
      this.sortByLocation = false;
      this.location = null;
      this._removeActiveFilter('sortByLocation');
    }
  }

  private _setPosition(position: Position): void {
    this.location = position.coords;
    this._addActiveFilter('sortByLocation', this.location);
  }

  private _setDates(): void {
    this.startDate = this.dateFilter.startDate;
    this.endDate = this.dateFilter.endDate;
    this.fromDate = this.dateFilter.defaultFrom || this.dateFilter.startDate;
    this.toDate = this.dateFilter.defaultTo || this.dateFilter.endDate;
  }
}
