import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'bvl-card-wrapper',
  templateUrl: './card-wrapper.component.html'
})
export class BvlCardWrapperComponent {

  @HostBinding('attr.class') attrClass = 'g-admin-card-wrapper';

  constructor() { }

}
