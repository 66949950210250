import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  TemplateRef} from '@angular/core';

@Component({
  selector: 'bvl-popover-window',
  templateUrl: './popover.component.html'
})
export class PopoverComponent {
  @Input() id: string;
  @Input() title: undefined | string | TemplateRef<any>;
  @Input() url: undefined | string;
  @Input() popoverClass = '';
  @HostBinding('class')
  get hostClasses(): string {
    return [
      this.popoverClass,
      'popover'
    ].join(' ');
  }
  @HostBinding('attr.role') role = 'tooltip';

  constructor(private _elementRef: ElementRef<HTMLElement>) {}

  isTitleTemplate(): any { return this.title instanceof TemplateRef; }

  isEventFrom(event: Event): boolean {
    return this._elementRef.nativeElement.contains(event.target as HTMLElement);
  }
}
