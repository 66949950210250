import { Component, OnInit } from '@angular/core';
import { BVL } from '@bvl-admin/settings/constants/general.constant';

@Component({
  selector: 'admin-login-layout',
  templateUrl: './login-layout.component.html',
  styles: []
})
export class LoginLayoutComponent implements OnInit {

  BVL = BVL;

  constructor() { }

  ngOnInit(): void { }

}
