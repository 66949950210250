import { ITrayItemButton } from '@bvl-admin/statemanagement/models/page.interface';
import { EDITOR_TYPES } from './editor.constant';
import { ACTION_TYPES, STATES_CODE, TYPES_CODE } from './general.constant';
import { ROLES_CODE } from './role.constants';

export const PAGE_TYPES_CODE = {
  page: 1,
  publication: 2
};

export const PAGE_TYPES = {
  page: {
    code: PAGE_TYPES_CODE.page,
    icon: 'la-file'
  },
  publication: {
    code: PAGE_TYPES_CODE.publication,
    icon: 'la-newspaper-o'
  }
};

export const TRAY_ITEM_BUTTONS: Array<ITrayItemButton> = [
  {
    description: 'Ver',
    icon: 'la-eye',
    actionType: ACTION_TYPES.see,
    permissions: [
      {
        rolesCode: [
          ROLES_CODE.admin
        ],
        pageTypes: [
          PAGE_TYPES_CODE.page,
          PAGE_TYPES_CODE.publication
        ],
        types: [
          TYPES_CODE.static,
          TYPES_CODE.dynamic
        ],
        statesCode: [
          STATES_CODE.published,
          STATES_CODE.new,
          STATES_CODE.inEdition,
          STATES_CODE.onHold,
          STATES_CODE.observed,
          STATES_CODE.inCorrection
        ]
      },
      {
        rolesCode: [
          ROLES_CODE.editor
        ],
        pageTypes: [
          PAGE_TYPES_CODE.page,
          PAGE_TYPES_CODE.publication
        ],
        types: [
          TYPES_CODE.dynamic
        ],
        statesCode: [
          STATES_CODE.published,
          STATES_CODE.new,
          STATES_CODE.inEdition,
          STATES_CODE.onHold,
          STATES_CODE.observed,
          STATES_CODE.inCorrection
        ]
      }
    ]
  },
  {
    description: 'Editar',
    icon: 'la-edit',
    actionType: ACTION_TYPES.edit,
    permissions: [
      {
        rolesCode: [
          ROLES_CODE.admin
        ],
        pageTypes: [
          PAGE_TYPES_CODE.page,
          PAGE_TYPES_CODE.publication
        ],
        types: [
          TYPES_CODE.dynamic
        ],
        statesCode: [
          STATES_CODE.published,
          STATES_CODE.new,
          STATES_CODE.inEdition
        ]
      },
      {
        rolesCode: [
          ROLES_CODE.editor
        ],
        pageTypes: [
          PAGE_TYPES_CODE.page,
          PAGE_TYPES_CODE.publication
        ],
        types: [
          TYPES_CODE.dynamic
        ],
        statesCode: [
          STATES_CODE.published,
          STATES_CODE.new,
          STATES_CODE.inEdition,
          STATES_CODE.observed,
          STATES_CODE.inCorrection
        ]
      }
    ]
  },
  {
    description: 'Publicar',
    icon: 'la-calendar-check-o',
    actionType: ACTION_TYPES.publish,
    permissions: [
      {
        rolesCode: [
          ROLES_CODE.admin
        ],
        pageTypes: [
          PAGE_TYPES_CODE.page,
          PAGE_TYPES_CODE.publication
        ],
        types: [
          TYPES_CODE.dynamic
        ],
        statesCode: [
          STATES_CODE.inEdition,
          STATES_CODE.onHold,
          STATES_CODE.observed
        ]
      }
    ]
  },
  {
    description: 'Eliminar',
    icon: 'la-trash',
    actionType: ACTION_TYPES.delete,
    permissions: [
      {
        rolesCode: [
          ROLES_CODE.admin
        ],
        pageTypes: [
          PAGE_TYPES_CODE.publication
        ],
        types: [
          TYPES_CODE.dynamic
        ],
        statesCode: [
          STATES_CODE.published
        ]
      }
    ]
  }
];

export const EDITOR_HEADER_BUTTONS: { primary: Array<ITrayItemButton>, secundary: Array<ITrayItemButton> } = {
  primary: [
    {
      description: 'Solicitar publicación',
      icon: 'la-calendar-check-o',
      actionType: ACTION_TYPES.requestPublication,
      permissions: [
        {
          rolesCode: [
            ROLES_CODE.editor
          ],
          statesCode: [
            STATES_CODE.new,
            STATES_CODE.inEdition,
            STATES_CODE.inCorrection
          ],
          editorTypes: [
            EDITOR_TYPES.publication.code
          ]
        }
      ]
    },
    {
      description: 'Publicar',
      icon: 'la-calendar-check-o',
      actionType: ACTION_TYPES.publish,
      permissions: [
        {
          rolesCode: [
            ROLES_CODE.admin
          ],
          statesCode: [
            STATES_CODE.new,
            STATES_CODE.inEdition,
            STATES_CODE.onHold
          ],
          editorTypes: [
            EDITOR_TYPES.publication.code
          ]
        }
      ]
    }
  ],
  secundary: [
    {
      description: 'Regresar',
      icon: 'la-arrow-left',
      actionType: ACTION_TYPES.back,
      permissions: [
        {
          rolesCode: [
            ROLES_CODE.admin,
            ROLES_CODE.editor
          ],
          statesCode: [
            STATES_CODE.new,
            STATES_CODE.inEdition,
            STATES_CODE.inCorrection,
            STATES_CODE.onHold,
            STATES_CODE.observed,
            STATES_CODE.published
          ],
          editorTypes: [
            EDITOR_TYPES.publication.code,
            EDITOR_TYPES.seePublication.code
          ]
        }
      ]
    },
    {
      description: 'Guardar',
      icon: 'la-save',
      actionType: ACTION_TYPES.save,
      permissions: [
        {
          rolesCode: [
            ROLES_CODE.admin,
            ROLES_CODE.editor
          ],
          statesCode: [
            STATES_CODE.new,
            STATES_CODE.inEdition,
            STATES_CODE.inCorrection
          ],
          editorTypes: [
            EDITOR_TYPES.publication.code
          ]
        }
      ]
    },
    {
      description: 'Cancelar cambios',
      icon: 'la-calendar-times-o',
      actionType: ACTION_TYPES.cancel,
      permissions: [
        {
          rolesCode: [
            ROLES_CODE.admin,
            ROLES_CODE.editor
          ],
          statesCode: [
            STATES_CODE.new,
            STATES_CODE.inEdition,
            STATES_CODE.inCorrection
          ],
          editorTypes: [
            EDITOR_TYPES.publication.code
          ]
        }
      ]
    },
    {
      description: 'Observar',
      icon: 'la-exclamation-triangle',
      actionType: ACTION_TYPES.observe,
      permissions: [
        {
          rolesCode: [
            ROLES_CODE.admin
          ],
          statesCode: [
            STATES_CODE.onHold
          ],
          editorTypes: [
            EDITOR_TYPES.publication.code,
            EDITOR_TYPES.seePublication.code
          ]
        }
      ]
    },
    {
      description: 'Revertir',
      icon: 'la-share',
      actionType: ACTION_TYPES.revert,
      permissions: [
        {
          rolesCode: [
            ROLES_CODE.editor
          ],
          statesCode: [
            STATES_CODE.inCorrection
          ],
          editorTypes: [
            EDITOR_TYPES.publication.code
          ]
        }
      ]
    },
    {
      description: 'Histórico',
      icon: 'la-history',
      actionType: ACTION_TYPES.historic,
      permissions: [
        {
          rolesCode: [
            ROLES_CODE.admin,
            ROLES_CODE.editor
          ],
          statesCode: [
            STATES_CODE.inEdition
          ],
          editorTypes: [
            EDITOR_TYPES.publication.code
          ]
        }
      ]
    }
  ]
};

export const DASHBOARD = [
  {
    title: 'Últimas versiones publicadas',
    stateCode: STATES_CODE.published
  },
  {
    title: 'Páginas en edición',
    stateCode: STATES_CODE.inEdition
  },
  {
    title: 'Páginas en espera de aprobación',
    stateCode: STATES_CODE.onHold
  },
  {
    title: 'Páginas observadas',
    stateCode: STATES_CODE.observed
  }
];

export enum PARENT_PATH_TYPE {
  CATEGORY = 'category',
  PAGE = 'page'
}
