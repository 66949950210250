import { environment } from '@bvl-admin/environments/environment';

export class FileEndpoint {

  public static image = `${environment.API_URL}/aws/s3/images/`;
  public static imageFilters = `${environment.API_URL}/aws/s3/images/filters`;
  public static imageDates = `${environment.API_URL}/aws/s3/images/dates`;
  public static imageMutable = `${environment.API_URL}/aws/s3/images/{id}`;

  public static video = `${environment.API_URL}/aws/s3/videos/`;
  public static videoFilters = `${environment.API_URL}/aws/s3/videos/filters`;
  public static videoDates = `${environment.API_URL}/aws/s3/videos/dates`;
  public static videoMutable = `${environment.API_URL}/aws/s3/videos/{id}`;

  public static documents = `${environment.API_URL}/aws/s3/documents/`;
  public static documentsFilters = `${environment.API_URL}/aws/s3/documents/filters`;
  public static documentsDates = `${environment.API_URL}/aws/s3/documents/dates`;
  public static documentsMutable = `${environment.API_URL}/aws/s3/documents/{id}`;

  public static icons = `${environment.API_URL}/aws/s3/icons/`;
  public static iconsFilters = `${environment.API_URL}/aws/s3/icons/filters`;
  public static iconsDates = `${environment.API_URL}/aws/s3/icons/dates`;
  public static iconsMutable = `${environment.API_URL}/aws/s3/icons/{id}`;

}
