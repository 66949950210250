import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { DomChangeDirectiveModule } from "../extra";
import { SliderItemDirective } from "./slider-item.directive";
import { SliderComponent } from "./slider.component";

@NgModule({
  declarations: [SliderComponent, SliderItemDirective],
  imports: [CommonModule, DomChangeDirectiveModule],
  exports: [SliderComponent, SliderItemDirective],
})
export class SliderModule {}
