import { IModalConfig } from './../../helpers/modal';

export enum LANGUAGE_TYPES_CODE {
  es = 'es',
  en = 'en'
}

export const CHART_OPTIONS_LANG = {
  decimalPoint: '.',
  thousandsSep: ','
};

export const PENtoUSD = 0.298191;

export const GA_EVENT_NAME = 'ga_event';
export const DOCUMENT_GA_EVENT = `document:${GA_EVENT_NAME}`;
export const EVENTS_CONTROLS = ['click', 'enter', 'focus', 'blur', 'change'];

export enum YES_NOT_CODE {
  NO = '0',
  YES = '1'
}

export const MODAL_CONFIG: IModalConfig = {
  closeIconClass: 'icon-close',
  cancelButtonClass: 'btn btn-outline',
  confirmButtonClass: 'btn btn-primary'
};

export const FILES_PATH = 'https://documents.bvl.com.pe';
