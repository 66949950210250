import { Component, Input, OnInit } from '@angular/core';
import { FormControlsBase, providerFormControlsBase } from '@bvl-core/ui/common';

@Component({
  selector: 'bvl-switch-value',
  templateUrl: './switch-value.component.html',
  providers: [providerFormControlsBase(BvlSwitchValueComponent)]
})
export class BvlSwitchValueComponent extends FormControlsBase implements OnInit {

  protected _model: boolean;
  @Input()
  get model(): boolean {
    return this._model;
  }
  set model(value: boolean) {
    this._model = value;
    const viewValue = (this._model)
                        ? this._valueOn
                        : this._valueOff;
    this.propagateChange(viewValue);
  }

  protected _valueOn: any;
  @Input()
  get valueOn(): any {
    return this._valueOn;
  }
  set valueOn(value: any) {
    this._valueOn = value;
  }

  protected _valueOff: any;
  @Input()
  get valueOff(): any {
    return this._valueOff;
  }
  set valueOff(value: any) {
    this._valueOff = value;
  }

  constructor() {
    super();
  }

  ngOnInit(): void { }

  /* Takes the value  */
  writeValue(value: any): void {
    this._model = (value !== undefined && value !== null && this._valueOn === value);
  }

}
