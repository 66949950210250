import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '@bvl-admin/services';
import { ModalService } from '@bvl-core/shared/helpers/modal';
import { NotificationService } from '@bvl-core/shared/helpers/notification';
import { LayoutBase } from '../layout-base';

@Component({
  selector: 'admin-main-layout',
  templateUrl: './main-layout.component.html',
  styles: []
})
export class MainLayoutComponent extends LayoutBase implements OnInit {

  constructor(
    protected _notificationService: NotificationService,
    protected _route: ActivatedRoute,
    protected _configurationService: ConfigurationService,
    protected _modalService: ModalService
  ) {
    super(
      _notificationService,
      _route,
      _configurationService,
      _modalService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
