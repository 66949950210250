import { LOCALE_ID } from '@angular/core';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

import { I18N_ES_PE_FACTORY } from '../services/i18n-es-pe.service';
import { PARSE_FORMAT_FACTORY } from '../services/parse-format.service';

export const PROVIDER_NGB_NATIVE_ADAPTER = {
  provide: NgbDateAdapter,
  useClass: NgbDateNativeAdapter
};

export const PROVIDER_PARSE_FORMAT = {
  provide: NgbDateParserFormatter,
  useFactory: PARSE_FORMAT_FACTORY
};

export const PROVIDER_I18N_ES_PE = {
  provide: NgbDatepickerI18n,
  useFactory: I18N_ES_PE_FACTORY,
  deps: [LOCALE_ID]
};
