import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { BvlFormAutocompleteComponent } from './form-autocomplete.component';

@NgModule({
  declarations: [BvlFormAutocompleteComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbTypeaheadModule
  ],
  exports: [BvlFormAutocompleteComponent]
})
export class BvlFormAutocompleteModule { }
