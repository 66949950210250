import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18nEsPeModule } from '@bvl/library';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { PROVIDER_I18N_ES_PE, PROVIDER_NGB_NATIVE_ADAPTER, PROVIDER_PARSE_FORMAT } from './constants/provider.constants';
import { BvlDatepickerComponent } from './datepicker.component';

@NgModule({
  declarations: [BvlDatepickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbDatepickerModule,
    I18nEsPeModule
  ],
  exports: [BvlDatepickerComponent],
  providers: [
    PROVIDER_NGB_NATIVE_ADAPTER,
    PROVIDER_PARSE_FORMAT,
    PROVIDER_I18N_ES_PE
  ]
})
export class BvlDatepickerModule { }
