import { CommonModule } from '@angular/common';
import { Input, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DomChangeDirectiveModule } from '../extra';
import { FiltersComponent } from './filters.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DomChangeDirectiveModule
  ],
  declarations: [FiltersComponent],
  exports: [FiltersComponent]
})
export class FiltersModule {
  @Input() value: string;
}
