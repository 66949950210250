import { Injectable } from '@angular/core';
import { UserEndpoint } from '@bvl-admin/endpoints';
import { ConfigurationEndpoint } from '@bvl-admin/endpoints/configuration.endpoint';
import { STORAGE_KEY } from '@bvl-admin/settings/constants/storage-key.constant';
import {
  IConfiguration,
  IKeysConfiguration,
  IMetadata,
  IObservableConfiguration,
  ISiteIdentityRequest,
  ISocialNetworking,
  IUserPermissionsResponse,
  IUserProfile
} from '@bvl-admin/statemanagement/models/configuration.interface';
import { ApiService } from '@bvl-core/shared/helpers/api';
import { StorageService } from '@bvl-core/shared/helpers/util';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private configurationSource: IConfiguration;

  constructor(
    private _apiService: ApiService,
    private _storageService: StorageService
  ) { }

  userPermissions(showSpin: boolean = false): Observable<IUserPermissionsResponse> {
    const userPermissions: IUserPermissionsResponse = this.getUserPermissions();

    return (userPermissions)
      ? of(userPermissions)
      : this._apiService.get(UserEndpoint.userPermissions, { preloader: showSpin });
  }

  getUserPermissions(): IUserPermissionsResponse {
    return this._storageService.getItemObject(STORAGE_KEY.userPermissions);
  }

  setUserPermissions(userPermissions: IUserPermissionsResponse): void {
    this._storageService.setItem(STORAGE_KEY.userPermissions, userPermissions);
  }

  getUserProfile(): IUserProfile {
    return this._storageService.getItemObject(STORAGE_KEY.userProfile);
  }

  setUserProfile(userProfile: IUserProfile): void {
    this._storageService.setItem(STORAGE_KEY.userProfile, userProfile);
  }

  getConfiguration(showSpin = true, force?: boolean): IObservableConfiguration {

    if (this.configurationSource && !force) {
      return of(this.configurationSource);
    }

    return this._apiService.get<IObservableConfiguration>(ConfigurationEndpoint.configuration, { preloader: showSpin })
      .pipe(
        map(response => {
          this.configurationSource = (response || {}) as IConfiguration;

          return this.configurationSource;
        })
      );
  }

  saveSiteIdentity(siteIdentity: ISiteIdentityRequest, showSpin = true): IObservableConfiguration {
    return this._apiService.post(ConfigurationEndpoint.saveSiteIdentity, siteIdentity, { preloader: showSpin });
  }

  saveSocialNetworking(socialNetworking: ISocialNetworking, showSpin = true): IObservableConfiguration {
    return this._apiService.post(ConfigurationEndpoint.saveSocialNetworking, socialNetworking, { preloader: showSpin });
  }

  saveMetadata(metadata: IMetadata, showSpin = true): IObservableConfiguration {
    return this._apiService.post(ConfigurationEndpoint.saveMetadata, metadata, { preloader: showSpin });
  }

  saveKeys(keys: IKeysConfiguration, showSpin = true): IObservableConfiguration {
    return this._apiService.post(ConfigurationEndpoint.saveKeys, keys, { preloader: showSpin });
  }

  setConfiguration(configuration: IConfiguration): void {
    this.configurationSource = configuration;
  }

}
