import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'beforeDate'
})
export class BeforeDatePipe implements PipeTransform {
  transform(dates: Array<Date>, limitDate: Date): Array<Date> {
    return dates.filter(date => date.getTime() <= limitDate.getTime());
  }
}
