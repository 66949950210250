import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GreatPriceComponent } from './great-price.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [GreatPriceComponent],
  exports: [GreatPriceComponent]
})
export class GreatPriceModule { }
