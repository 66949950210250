import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { DateUtil } from './date';

// @dynamic
export class ValidatorUtil {

  public static validateAbstractControl(control: AbstractControl): boolean {
    return !control.valid && (control.dirty || control.touched);
  }

  public static validateControl(form: FormGroup, controlName: string): boolean {
    const control = form.controls[controlName];

    return control && !control.valid && (control.dirty || control.touched);
  }

  public static validateForm(form: FormGroup): void {
    for (const control in form.controls) {
      if (form.controls.hasOwnProperty(control)) {
        form.controls[control].markAsTouched({ onlySelf: true });
        if ((form.controls[control] as any).controls) { this.validateForm(form.controls[control] as any); }
      }
    }
  }

  public static minChecksRequired(min: number = 1): ValidatorFn {
    return ((formArray: FormArray) => {
      const checks = formArray.controls.reduce((prev, next) => {
        return next.value
                ? prev + parseInt(next.value, 10)
                : prev;
      }, 0);

      return (checks >= min)
                ? null
                : { required: true };
    });
  }

  public static date(format: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      return (DateUtil.validateFormat(control.value, format))
                ? null
                : { invalidFormat: true };
    };
  }

  public static rangeDate(fromControlName: string, toControlName: string): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const fromControl = formGroup.get(fromControlName),
            toControl = formGroup.get(toControlName);

      if (!fromControl.value && !toControl.value) {
        return null;
      }

      return (!fromControl.value || !toControl.value || fromControl.value > toControl.value)
                ? { invalidRange: true }
                : null;
    };
  }

  public static onlyNumber(): ValidatorFn {
    const ONLY_NUMBER_REGEX = /^[0-9 ]+$/;

    return (control: FormControl): ValidationErrors | null => {
      return (!control.value || ONLY_NUMBER_REGEX.test(control.value))
                ? null
                : { onlyNumber: true };
    };
  }

  public static onlyLetter(): ValidatorFn {
    const ONLY_LETTER_REGEX = /^[a-zA-ZñÑá-úÁ-Ú ]*$/;

    return (control: FormControl): ValidationErrors | null => {
      return (!control.value || ONLY_LETTER_REGEX.test(control.value))
                ? null
                : { onlyLetter: true };
    };
  }

  public static alphanumeric(): ValidatorFn {
    const ALPHANUMERIC_REGEX = /^[0-9a-zA-Z]+$/;

    return (control: FormControl): ValidationErrors | null => {
      return (!control.value || ALPHANUMERIC_REGEX.test(control.value))
                ? null
                : { alphanumeric: true };
    };
  }

  public static validatorsByDocumentType(documentType: string): Array<ValidatorFn> {
    const validators: Array<ValidatorFn> = [],
          validate: { fieldType: ValidatorFn, minLength: number, maxLength: number } = {
            fieldType: null,
            minLength: null,
            maxLength: null
          };

    switch (documentType) {
      case 'DNI':
        validate.fieldType = this.onlyNumber();
        validate.minLength = 8;
        validate.maxLength = 8;
        break;
      case 'CE':
        validate.fieldType = this.onlyNumber();
        validate.minLength = 13;
        validate.maxLength = 13;
        break;
      case 'PAS':
        validate.fieldType = this.alphanumeric();
        validate.minLength = 20;
        validate.maxLength = 20;
        break;
      case 'RUC':
        validate.fieldType = this.onlyNumber();
        validate.minLength = 11;
        validate.maxLength = 11;
        break;
      default:
        break;
    }

    if (validate.fieldType) {
      validators.push(validate.fieldType);
      validators.push(Validators.minLength(validate.minLength));
      validators.push(Validators.maxLength(validate.maxLength));
    }

    return validators;
  }

  public static confirmPasswordForm(
    passwordControlName: string,
    confirmPasswordControlName: string): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      return (formGroup.controls[passwordControlName].value === formGroup.controls[confirmPasswordControlName].value)
              ? null
              : { confirmPassword: true };
    };
  }

  public static confirmPassword(abstractControl: AbstractControl, isPassword: boolean = false): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      if (!control.value || !abstractControl.value || control.value === abstractControl.value) { return null; }
      if (isPassword) {
        abstractControl.updateValueAndValidity();

        return null;
      } else {
        return { confirmPassword: true };
      }
    };
  }

  public static passwordPolicy(): ValidatorFn {
    // La contraseña debe tener 8 caracteres como mínimo, un número, una mayúscula, una minúscula y un caracter especial
    const PASSWORD_POLICY = /(?=(?:.*[0-9]){1})(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1})(?=(?:.*[\W]){1})\S{8}/;

    return (control: FormControl): ValidationErrors | null => {
      return (!control.value || PASSWORD_POLICY.test(control.value))
                ? null
                : { passwordPolicy: true };
    };
  }

  public static phoneNumber(): ValidatorFn {
    const NUMBER_PHONE_REGEX = /^[0-9\-]+$/;

    return (control: FormControl): ValidationErrors | null => {
      return (!control.value || NUMBER_PHONE_REGEX.test(control.value))
                ? null
                : { phoneNumber: true };
    };
  }

}
