import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bvl-video',
  templateUrl: './video.component.html'
})
export class BvlVideoComponent implements OnInit {

  @Input() videoUri: string;
  @Input() mimeType: string;
  @Input() autoplay: boolean;

  constructor() { }

  ngOnInit(): void { }

}
