export const FileLang = {
  errors: {
    image: {
      format: 'El archivo seleccionado no tiene un formato válido',
      size: 'La imagen seleccionada debe ser menor a',
      exist: 'La imagen que intenta subir ya se encuentra registrada',
      fail: 'Error al subir la imagen seleccionada.',
      failDelete: 'Error al eliminar la imagen seleccionada.'
    },
    file: {
      format: 'El archivo seleccionado no tiene un formato válido',
      size: 'El archivo seleccionado debe ser menor a',
      exist: 'El archivo seleccionado ya fue agregado',
      fail: 'Error al subir el archivo seleccionado.'
    }
  }
};
