import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '@bvl-admin/components/header/header.module';
import { MenuModule } from '@bvl-admin/components/menu/menu.module';
import { ModalModule } from '@bvl-core/shared/helpers/modal';
import { NotificationModule } from '@bvl-core/shared/helpers/notification';
import { BvlNotifyModule } from '@bvl-core/ui/lib/components';
import { MainLayoutComponent } from './main-layout.component';

@NgModule({
  declarations: [MainLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    NotificationModule,
    BvlNotifyModule,
    MenuModule,
    HeaderModule,
    ModalModule
  ]
})
export class MainLayoutModule { }
