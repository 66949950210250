import { Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[domChange]'
})
export class DomChangeDirective implements OnInit {
  @Output() domChange = new EventEmitter();

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.domChange.emit(this.elementRef);
  }
}
