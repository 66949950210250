import { ModuleWithProviders, NgModule } from '@angular/core';

import { ConfigurationService } from './configuration.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: []
})
export class ProfileProvidersModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ProfileProvidersModule,
      providers: [ConfigurationService]
    };
  }
}
