import { environment } from '@bvl-admin/environments/environment';

export class ConfigurationEndpoint {
  public static configuration = `${environment.API_URL}/configurations`;
  public static saveSiteIdentity = `${environment.API_URL}/configurations/general`;
  public static saveSocialNetworking = `${environment.API_URL}/configurations/social-networking`;
  public static saveMetadata = `${environment.API_URL}/configurations/metadata-general`;

  public static saveKeys = `${environment.API_URL}/configurations/keys`;
}
