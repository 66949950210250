import { isPlatformServer } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { coerceBooleanProp, GaUnsubscribeBase } from '../extra';

@Component({
  selector: 'bvl-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SectionHeaderComponent extends GaUnsubscribeBase implements OnInit, OnDestroy {

  prevTitle: string;
  private _noMargins: boolean;
  private _isLink: boolean;

  @Input() title: string;
  @Input() sup: string;
  @Input() align = 'left';
  @Input()
  get isLink(): boolean {
    return this._isLink;
  }
  set isLink(value: boolean) {
    this._isLink = coerceBooleanProp(value);
  }
  @Input() textLink: string;
  @Input() urlLink: string;

  @Input()
  get noMargins(): any {
    return this._noMargins;
  }
  set noMargins(value: any) {
    this._noMargins = coerceBooleanProp(value);
  }
  private _noLine: boolean;
  @Input()
  get noLine(): boolean {
    return this._noLine;
  }
  set noLine(value: boolean) {
    this._noLine = coerceBooleanProp(value);
  }
  @Input() emitChange = true;
  @Input() color = '#5c5c5c';

  constructor(
    @Inject(PLATFORM_ID) private _platformId,
    protected _element: ElementRef
  ) {
    super(_element);
  }

  setEventsGA(): void { }

  ngOnInit(): void {
    this._emitCustomEvent('init');
  }

  ngOnDestroy(): void {
    this._emitCustomEvent('destroy');
  }

  private _emitCustomEvent(action: string): void {
    if (isPlatformServer(this._platformId)) {
      return void 0;
    }
    if (this.emitChange) {
      const event = new CustomEvent('section_header', {
        detail: {
          title: this.title,
          action
        }
      });
      document.dispatchEvent(event);
    }
  }
}
