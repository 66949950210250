import { IRuleSeo } from '@bvl-admin/statemanagement/models/seo.interface';

export const DISTINCTFUNC = (value: string, index: number, self: Array<string>) => self.indexOf(value) === index;

// tslint:disable-next-line:newline-per-chained-call
export const SPLITFUNC = (keywords: string) => keywords.trim().replace(/\s\s+/g, ' ').split(' ');

export const RULES_SEO_VALID = {
  pageTitleMaxLength: 60,
  pageTitleMinLength: 30,
  metaDescriptionMaxlength: 160,
  metaDescriptionMinLength: 100,
  contentMinWords: 900
};

export const RULES_SEO_CODE = {
  existPageTitle: 'existPageTitle',
  focusKeywordIsUedInPageTitle: 'focusKeywordIsUedInPageTitle',
  focusKeywordIsUsedBeginningInPageTitle: 'focusKeywordIsUsedBeginningInPageTitle',
  pageTitleLength: 'pageTitleLength',
  existMetaDescription: 'existMetaDescription',
  focusKeywordIsUedInMetaDescription: 'focusKeywordIsUedInMetaDescription',
  metaDescriptionLength: 'metaDescriptionLength',
  existH1InContent: 'existH1InContent',
  focusKeywordIsUedInH1: 'focusKeywordIsUedInH1',
  countWordsOk: 'countWordsOk',
  focusKeywordIsUedInFirstParagraph: 'focusKeywordIsUedInFirstParagraph',
  focusKeywordIsUedDentityPercent: 'focusKeywordIsUedDentityPercent',
  existImage: 'existImage',
  focusKeywordIsUedInImageName: 'focusKeywordIsUedInImageName',
  focusKeywordIsUedInImageTag: 'focusKeywordIsUedInImageTag',
  existsLinkInternal: 'existsLinkInternal',
  existsLinkExternal: 'existsLinkExternal'
};

// tslint:disable:max-line-length
export const RULES_SEO: Array<IRuleSeo> = [
  {
    code: 'existPageTitle',
    description: `Has ingresado un título de página`,
    descriptionError: `No has introducido un título de página`,
    type: 'Title'
  },
  {
    code: 'focusKeywordIsUedInPageTitle',
    description: `La palabra clave de enfoque "{{focusKeyword}}". Se utiliza en el título de la página`,
    descriptionError: `La palabra clave de enfoque "{{focusKeyword}}". No se utiliza en el título de la página`,
    type: 'Title'
  },
  {
    code: 'focusKeywordIsUsedBeginningInPageTitle',
    description: `La palabra clave de enfoque "{{focusKeyword}}" se usa al principio del título de la página`,
    descriptionError: `Coloque la palabra clave de enfoque al principio del título de la página`,
    type: 'Title'
  },
  {
    code: 'pageTitleLength',
    description: `La longitud del título de la página es perfecta, {{aviable}} caracteres disponibles. ({{length}} de {{maxlength}} caracteres utilizados)`,
    descriptionError: `El título de la página es demasiado corto o largo, {{aviable}} caracteres disponibles. ({{length}} de {{maxlength}} caracteres utilizados)`,
    type: 'Title'
  },
  {
    code: 'existMetaDescription',
    description: `Has ingresado un Meta description`,
    descriptionError: `No has introducido un Meta description`,
    type: 'Description'
  },
  {
    code: 'focusKeywordIsUedInMetaDescription',
    description: `La palabra clave de enfoque "{{focusKeyword}}" se usa en la Meta description`,
    descriptionError: `La palabra clave de enfoque "{{focusKeyword}}" no aparece en la Meta description`,
    type: 'Description'
  },
  {
    code: 'metaDescriptionLength',
    description: `La longitud de la Meta description es perfecta, {{aviable}} caracteres disponibles. ({{length}} de {{maxlength}} caracteres utilizados)`,
    descriptionError: `La longitud de la Meta description no es correcta, {{aviable}} caracteres disponibles. ({{length}} de {{maxlength}} caracteres utilizados)`,
    type: 'Description'
  },
  {
    code: 'existH1InContent',
    description: `Existe un H1`,
    descriptionError: `Debes agregar un H1`,
    type: 'Content'
  },
  {
    code: 'focusKeywordIsUedInH1',
    description: `La palabra clave de enfoque "{{focusKeyword}}" aparece en el H1`,
    descriptionError: `La palabra clave de enfoque "{{focusKeyword}}" no aparece en el H1`,
    type: 'Content'
  },
  {
    code: 'countWordsOk',
    description: `Your text contains ({{words}}) words`,
    descriptionError: `Your text doesn't contain enough words, a minimum of 300 words is recommended`,
    type: 'Content'
  },
  {
    code: 'focusKeywordIsUedInFirstParagraph',
    description: `The focus keyword "{{focusKeyword}}" doesn't appear in first paragraph of the text`,
    descriptionError: `The focus keyword "{{focusKeyword}}" doesn't appear in first paragraph of the text`,
    type: 'Content'
  },
  {
    code: 'focusKeywordIsUedDentityPercent',
    description: `Your keyword density ({{percent}}%) is pretty perfect, focus keyword "{{focusKeyword}}" used 1 time(s)`,
    descriptionError: `You should use the focus keyword keyword "{{focusKeyword}}" more often, to improve the keyword density (0%)`,
    type: 'Content'
  },
  {
    code: 'existImage',
    description: `Se tiene una imagen`,
    descriptionError: `Debes añadir una imagen.`,
    type: 'Content'
  },
  {
    code: 'focusKeywordIsUedInImageName',
    description: `La palabra clave de enfoque "{{focusKeyword}}" aparece en el nombre de la imagen`,
    descriptionError: `La palabra clave de enfoque "{{focusKeyword}}" no aparece en el nombre de la imagen`,
    type: 'Content'
  },
  {
    code: 'focusKeywordIsUedInImageTag',
    description: `La palabra clave de enfoque "{{focusKeyword}}" aparece en la etiqueta Alt de la imagen`,
    descriptionError: `La palabra clave de enfoque "{{focusKeyword}}" no aparece en el atributo alt de la imagen`,
    type: 'Content'
  },
  {
    code: 'existsLinkInternal',
    description: `Tienes suficientes enlaces internos. ¡Buen trabajo!`,
    descriptionError: `Agregue enlaces relevantes para mejorar la experiencia del usuario y la estructura interna de enlaces`,
    type: 'Content'
  },
  {
    code: 'existsLinkExternal',
    description: `Tienes suficientes enlaces externos. ¡Buen trabajo!`,
    descriptionError: `No hay enlaces externos en esta página. ¡Añade alguno!`,
    type: 'Content'
  }
];
