import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { SCREEN_RESOLUTIONS } from './resize.constant';

const _window = (): any => {
  return window;
};

@Injectable({
  providedIn: 'root'
})
export class ResizeService {

  currentWidth: number;
  currentHeigh: number;
  private _resizeEvent: BehaviorSubject<number>;

  constructor() {
    this.currentWidth = this.getNativeWindow().innerWidth;
    this.currentHeigh = this.getNativeWindow().innerHeight;
    this._resizeEvent = new BehaviorSubject<number>(this.currentWidth);
    fromEvent(this.getNativeWindow(), 'resize')
      .subscribe((event: any) => {
        this.currentWidth = event.target.innerWidth;
        this.currentHeigh = event.target.innerHeight;
        this._resizeEvent.next(this.currentWidth);
      });
  }

  getNativeWindow(): any {
    return _window();
  }

  getResizeEvent(): BehaviorSubject<number> {
    return this._resizeEvent;
  }

  getScreenType(width?: number): string {
    if (!width) { width = this.currentWidth; }
    const screen = SCREEN_RESOLUTIONS.find(sr => width >= sr.min && width <= sr.max);

    return screen
            ? screen.type
            : '';
  }

  is(screenType: string): boolean {
    return screenType === this.getScreenType();
  }

}
