import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { GaUnsubscribeBase } from '../extra';

@Component({
  selector: 'bvl-pagination',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PaginationComponent extends GaUnsubscribeBase implements OnInit, OnChanges {
  pages: Array<number> = [];
  private _pages: Array<number> = [];
  @Input() customClass: string;
  @Input() currentPage = 1;
  @Input() data: any;
  @Input() width: 'small' | 'large' = 'small';
  @Input() max: number;
  @Input() totalPages: number;
  @Input() paginateByService = false;
  @Output() emitChangePage = new EventEmitter<any>();

  constructor(
    protected _element: ElementRef
  ) {
    super(_element);
  }

  ngOnInit(): void {
    this.setPages();
  }

  ngOnChanges(changes): void {
    if (changes.totalPages !== this.totalPages || changes.data !== this.data) {
      this.setPages();
      this.currentPage = 1;
      if (!this.paginateByService) {
        this.totalPages = this._pages.length - 1;
      }
    }
  }

  changePage(accion: string | number): void {
    let slicedData: Array<{}> = [];
    this._setScroll();
    switch (typeof accion) {
      case 'string':
        if (accion === 'next') {
          this.currentPage = this.currentPage + 1;
          if (this.paginateByService) {
            this.emitChangePage.emit(this.currentPage);

            if (this.totalPages > 5 && this.currentPage === this.pages[this.pages.length - 1]) {
              this.setPages(this.currentPage - 2);
            }

            return;
          }
          if (this.totalPages > 5 && this.currentPage === this.pages[this.pages.length - 1]) {
            this.setPages(this.currentPage - 2);
          }
        } else {
          this.currentPage = this.currentPage - 1;
          if (this.paginateByService) {
            this.emitChangePage.emit(this.currentPage);

            if (this.currentPage < this.pages[0]) {
              this.setPages(this.currentPage);
            }

            return;
          }
          if (this.currentPage < this.pages[0]) {
            this.setPages(this.currentPage);
          }
        }
        break;
      case 'number':
        this.currentPage = accion;
        if (this.currentPage === this.totalPages) {
          this.setPages(this.currentPage - 3);
        } else if (this.totalPages > 5 && this.currentPage === this.pages[this.pages.length - 1]) {
          this.setPages(this.currentPage - 2);
        }
        if (this.paginateByService) {
          this.emitChangePage.emit(this.currentPage);

          return;
        }
        break;
      default:
        return;
    }
    if (!this.paginateByService) {
      const init = (this.currentPage - 1) * this.max;
      slicedData = this.data.slice(init, init + this.max);
      this.emitChangePage.emit(slicedData);
    }
  }
  setPages(newIndex?: number): void {
    if (newIndex && newIndex !== 1) {
      this.pages = [];
      for (let index = newIndex - 1; index < newIndex + 4; index++) {
        if (index > 0 && index <= this.totalPages) {
          this.pages.push(index);
        }
      }
    } else {
      let allPages = this.paginateByService ? this.totalPages : this.data.length / this.max;
      this.pages = [];
      this._pages = [];
      if (allPages % 1 !== 0) {
        allPages = Math.trunc(allPages + 1);
        this._pages.push(this._pages.length);
      }
      for (let index = 0; index < allPages; index++) {
        if (index < 5 && index >= 0) {
          this.pages.push(index + 1);
        }
        this._pages.push(index);
      }
      if (!this.totalPages) {
        if (this.pages.length < this._pages.length) {
          this.totalPages = this._pages.length - 1;
        }
      }
    }
  }
  private _setScroll(): void {
    const selector: HTMLElement = document.querySelector('[top-paginate]');
    if (selector) {
      window.scrollTo(0, selector.offsetTop);
    }
  }
}
