import { Component, Input, OnInit } from '@angular/core';
import { FormControlsBase, providerFormControlsBase } from '@bvl-core/ui/common';

@Component({
  selector: 'bvl-radio-button',
  templateUrl: './radio-button.component.html',
  providers: [providerFormControlsBase(BvlRadioButtonComponent)]
})
export class BvlRadioButtonComponent extends FormControlsBase implements OnInit {

  protected _value: string;
  @Input()
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
  }

  constructor() {
    super();
  }

  ngOnInit(): void { }

}
