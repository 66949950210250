import { Component, Input, OnInit } from '@angular/core';

import { WITHOUT_DATA_TYPE } from './without-data.constant';

@Component({
  selector: 'bvl-without-data',
  templateUrl: './without-data.component.html'
})
export class WithoutDataComponent implements OnInit {

  WITHOUT_DATA_TYPE = WITHOUT_DATA_TYPE;

  @Input() type: string;
  @Input() icon: string;
  @Input() message: string;

  constructor() {
    this.type = this.WITHOUT_DATA_TYPE.chart;
    this.icon = 'icon-bar-chart';
    this.message = 'En este momento, no hay datos disponibles';
  }

  ngOnInit(): void { }

}
