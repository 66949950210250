import { Component, Input, OnInit } from '@angular/core';
import { NOTIFICATION_TYPES } from '@bvl-core/shared/helpers/notification';

@Component({
  selector: 'bvl-notify',
  styleUrls: ['./notify.component.scss'],
  templateUrl: './notify.component.html'
})
export class BvlNotifyComponent implements OnInit {

  NOTIFICATION_TYPES = NOTIFICATION_TYPES;

  protected _type: string;
  @Input()
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value || '';
  }

  protected _active: boolean;
  @Input()
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value;
  }

  constructor() { }

  ngOnInit(): void { }

}
