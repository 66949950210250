import { Component, Input } from '@angular/core';
import { IPopover } from '../popover/popover';

@Component({
  selector: 'bvl-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent {
  private _class = 'label label-sm';
  @Input() label: string;
  @Input() popover?: IPopover;
  @Input()
  get class(): string {
    return this._class;
  }
  set class(classes: string) {
    this._class = [
      this._class,
      classes,
      !!this.popover ? 'with-popover' : ''
    ].join(' ');
  }
}
