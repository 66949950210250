import { Component } from '@angular/core';

@Component({
  selector: 'bvl-card-header',
  templateUrl: './card-header.component.html'
})
export class BvlCardHeaderComponent {

  constructor() { }

}
