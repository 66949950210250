import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '@bvl-admin/components/header/header.module';
import { ModalModule } from '@bvl-core/shared/helpers/modal';
import { NotificationModule } from '@bvl-core/shared/helpers/notification';
import { BvlNotifyModule } from '@bvl-core/ui/lib/components';
import { EditorLayoutComponent } from './editor-layout.component';

@NgModule({
  declarations: [EditorLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    HeaderModule,
    NotificationModule,
    BvlNotifyModule,
    ModalModule
  ]
})
export class EditorLayoutModule { }
