import { environment } from '@bvl-admin/environments/environment';
import { IModalConfig } from '@bvl-core/shared/helpers/modal';
import { ISpinnerConfig } from '@bvl-core/shared/helpers/spinner';
import { LANGUAGE_TYPES_CODE } from '@bvl-core/shared/settings/constants/general.constants';

export const ASSETS_PATH = {
  images: 'assets/images/'
};

export const CONTENT_TYPE = {
  auth: 'application/x-www-form-urlencoded',
  json: 'application/json'
};

export const REGEX = {
  ddMMyyyy: /^([0-2][0-9]|3[0-1])(\/|-)(0[1-9]|1[0-2])\2(\d{4})$/,
  // tslint:disable-next-line: max-line-length
  domain: /^(?![^\n]*\.$)(?:https?:\/\/)(?:(?:[2][1-4]\d|25[1-5]|1\d{2}|[1-9]\d|[1-9])(?:\.(?:[2][1-4]\d|25[1-5]|1\d{2}|[1-9]\d|[0-9])){3}(?::\d{4})?|[a-zA-Z0-9\-]+(?:\.[a-zA-Z0-9\-]+){2,})$/,
  space: /^\S+$/,
  twitter: /^@?(\w){1,15}$/
};

export const DATE_FORMAT = {
  ddMMyyyy: 'dd-MM-yyyy'
};

export const BVL = {
  supportPhone: '01 234 5467',
  supportEmail: 'soporte@bvl.com.pe',
  // TODO: Cambiar a valor por servicio
  urlSite: 'https://www.bvl.com.pe/'
};

export const ACTION_TYPES = {
  edit: 'edit',
  cancel: 'cancel',
  delete: 'delete',
  save: 'save',
  collapsed: 'collapsed',
  expanded: 'expanded',
  acept: 'acept',
  historic: 'historic',
  historyMode: 'historyMode',
  observe: 'observe',
  preview: 'preview',
  publish: 'publish',
  back: 'back',
  requestPublication: 'requestPublication',
  revert: 'revert',
  see: 'see',
  revise: 'revise',
  comment: 'comment',
  restore: 'restore',
  select: 'select',
  add: 'add'
};

export const STATES_CODE = {
  published: 1,
  inEdition: 2,
  onHold: 3,
  observed: 4,
  trash: 5,
  new: 6,
  inCorrection: 7
};

export const STATES = [
  {
    code: STATES_CODE.published,
    description: 'Publicada',
    labelClass: 'g-admin-badge-success'
  },
  {
    code: STATES_CODE.inEdition,
    description: 'En edición',
    labelClass: 'g-admin-badge-warning'
  },
  {
    code: STATES_CODE.onHold,
    description: 'En espera',
    labelClass: 'g-admin-badge-pause'
  },
  {
    code: STATES_CODE.observed,
    description: 'Observada',
    labelClass: 'g-admin-badge-danger'
  },
  {
    code: STATES_CODE.trash,
    description: 'Papelera',
    labelClass: 'g-admin-badge-disabled'
  },
  {
    code: STATES_CODE.new,
    description: 'Nuevo',
    labelClass: ''
  },
  {
    code: STATES_CODE.inCorrection,
    description: 'En corrección',
    labelClass: ''
  }
];

export const TYPES_CODE = {
  all: 0,
  static: 1,
  dynamic: 2,
  common: 3,
  popup: 4
};

export const TYPES = [
  {
    code: TYPES_CODE.dynamic,
    description: 'Dinámica'
  },
  {
    code: TYPES_CODE.static,
    description: 'Estática'
  },
  {
    code: TYPES_CODE.common,
    description: 'Común'
  }
];

export const LANGUAGE_TYPES = [
  {
    code: LANGUAGE_TYPES_CODE.es,
    description: 'Español'
  },
  {
    code: LANGUAGE_TYPES_CODE.en,
    description: 'Inglés'
  }
];

export const VIEW_TYPES = {
  user: 'U',
  page: 'P',
  component: 'C'
};

export const DIMENTION_IMAGE = {
  small: {
    complete: 'small',
    abbreviation: 'sm'
  },
  medium: {
    complete: 'medium',
    abbreviation: 'md'
  },
  large: {
    complete: 'large',
    abbreviation: 'lg'
  }
};

export const MODAL_CONFIG: IModalConfig = {
  closeIconClass: 'la la-close',
  cancelButtonClass: 'g-admin-btn g-admin-btn-outline',
  confirmButtonClass: 'g-admin-btn g-admin-btn-primary'
};

export const SPINNER_CONFIG: ISpinnerConfig = {
  spinnerIconClass: 'la la-spinner g-cl-red1'
};

export const SWAL_CONFIG = {
  customContainerClass: 'g-admin-modal-alert',
  customClass: '',
  reverseButtons: true,
  buttonsStyling: false,
  cancelButtonClass: 'g-admin-btn g-admin-btn-outline',
  confirmButtonClass: 'g-admin-btn g-admin-btn-primary'
};

export const WC_BUNDLE_PATH = environment.WC_BUNDLE_PATH;
