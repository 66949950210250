import { DOCUMENT } from '@angular/common';
import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { listenToTriggers, PopupService, positionElements } from '../helpers';
import { PopoverComponent } from './popover.component';

export let nexPopovertId = 0;

@Directive({
  selector: '[bvlPopover]',
  exportAs: 'bvlPopover'
})
export class PopoverDirective implements OnInit, OnDestroy, OnChanges {
  @Input() bvlPopover: string | TemplateRef<any>;
  @Input() popoverTitle: string | TemplateRef<any>;
  @Input() popoverUrl: string;
  @Input() popoverClass: string;
  @Output() shown = new EventEmitter();
  @Output() hidden = new EventEmitter();

  private _id = `bvl-popover-${nexPopovertId++}`;
  private _popupService: PopupService<PopoverComponent>;
  private _componentEl: ComponentRef<PopoverComponent>;
  private _unregisterListenersFn;
  private _zoneSubscription: any;

  private _isDisabled(): boolean {
    return !this.bvlPopover && !this.popoverTitle;
  }

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _renderer: Renderer2,
    private _ngZone: NgZone,
    injector: Injector,
    @Inject(DOCUMENT) private _document: any,
    componentFactoryResolver: ComponentFactoryResolver,
    viewContainerRef: ViewContainerRef
  ) {
    this._popupService = new PopupService<PopoverComponent>(
      PopoverComponent, injector, viewContainerRef, _renderer, componentFactoryResolver
      );
    this._zoneSubscription = _ngZone.onStable.subscribe(() => {
      if (this._componentEl) {
        positionElements(this._elementRef.nativeElement, this._componentEl.location.nativeElement, 'top', true);
      }
    });
  }

  open(): void {
    if (!this._componentEl && !this._isDisabled()) {
      this._componentEl = this._popupService.open(this.bvlPopover);
      this._componentEl.instance.title = this.popoverTitle;
      this._componentEl.instance.popoverClass = this.popoverUrl ? `${this.popoverClass} with-link` : this.popoverClass;
      this._componentEl.instance.id = this._id;
      this._componentEl.instance.url = this.popoverUrl;
      this._document.querySelector('body')
        .appendChild(this._componentEl.location.nativeElement);
      this._componentEl.changeDetectorRef.detectChanges();
      this._componentEl.changeDetectorRef.markForCheck();
      positionElements(this._elementRef.nativeElement, this._componentEl.location.nativeElement, 'top', true);
      this._ngZone.runOutsideAngular(() => {
        let justOpened = true;
        requestAnimationFrame(() => justOpened = false);
        const clicks$ = fromEvent<MouseEvent>(this._document, 'click')
          .pipe(
            takeUntil(this.hidden),
            filter(() => !justOpened),
            filter(event => this._shouldCloseFromClick(event))
            );
        clicks$.subscribe(() => this._ngZone.run(() => this.close()));
      });
    }
    this.shown.emit();
  }

  close(): void {
    if (this._componentEl) {
      this._popupService.close();
      this._componentEl = null;
      this.hidden.emit();
    }
  }

  toggle(): void {
    if (this._componentEl) {
      this.close();
    } else {
      this.open();
    }
  }

  ngOnInit(): void {
    this._unregisterListenersFn = listenToTriggers(
      this._renderer,
      this._elementRef.nativeElement,
      'click',
      this.open.bind(this),
      this.close.bind(this),
      this.toggle.bind(this)
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['bvlPopover'] || changes['popoverTitle']) && this._isDisabled()) {
      this.close();
    }
  }

  ngOnDestroy(): void {
    this.close();
    if (this._unregisterListenersFn) {
      this._unregisterListenersFn();
    }
    this._zoneSubscription.unsubscribe();
  }

  private _shouldCloseFromClick(event: MouseEvent): boolean {
    return event.button !== 2 && !this._isEventFromPopover(event);
  }
  private _isEventFromPopover(event: MouseEvent): boolean {
    const popup = this._componentEl.instance;

    return popup ? popup.isEventFrom(event) : false;
  }
}
