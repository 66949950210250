import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getStorageLanguage, LANGUAGES } from '../extra';
import { checkIfAudio, checkValueIsMedia, IMediaPlayer, YOUTUBE_REGEX } from './media-player';

@Component({
  selector: 'bvl-media-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediaPlayerComponent implements OnInit {

  media: IMediaPlayer = {
    url: '',
    title: ''
  };

  isYoutube;
  isAudio;

  showPlayer = false;
  data: any;

  TEXT = LANGUAGES[
    getStorageLanguage()
      .substring(0, 2)
  ];

  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    document.addEventListener('init-media', ev => {
      const { detail } = (ev) as any;
      const { data } = detail;
      const itsSameData = this.data && JSON.stringify(data) === JSON.stringify(this.data);

      if (data && !itsSameData) {
        this.showPlayer = true;
        this.media = {};
        this.data = data;

        if (checkValueIsMedia(this.data.url)) {

          this.isYoutube = YOUTUBE_REGEX.test(this.data.url);
          this.isAudio = checkIfAudio(this.data.url);

          if (this.isYoutube) {
            this._setVideo();
          }
          if (this.isAudio) {
            this._setAudio();
          }

          this._sendNotification(this.data);
        }
      }
    });
  }

  private _setVideo(): void {
    const videoId = this._getVideoId(this.data.url);

    this.media.url = this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${videoId}?autoplay=1`);
    this.media.title = this.data.title;
  }

  private _setAudio(): void {
    this.media = this.data;
  }

  private _sendNotification(data): void {
    const event = new CustomEvent('media-player', {
      detail: { data }
    });
    document.dispatchEvent(event);
  }

  private _getVideoId(url): string {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[7].length === 11 ? match[7] : false;
  }

  endMedia(): void {
    this.showPlayer = false;
    this.data = {};
    this._sendNotification(false);
  }
}
