export class SEOConfiguration {
  title?: string;
  titleContent?: Array<IVariableTitleContent>;
  titleSnippet?: string;
  description?: string;
  slug?: string;
  focusKeyword?: string;
  secondaryKeywords?: string;
  pageTitle?: string;
  siteName?: string;
  urlSite?: string;
  descriptionSite?: string;
  contentHtml?: Element;
  h1?: number;
  contentText?: string;
  rules?: Array<IRuleSeoConfiguration>;
  social?: ISeoSocialNetworking;
  language?: string;
  canonicalLink?: string;
  get titleLength(): number {
    return (this.title || '').length;
  }
  get descriptionLength(): number {
    return (this.description || '').length;
  }

  get canonical(): string {
    const url = this.canonicalLink || `${this.urlSite}/${this.slug}`.replace(/\/+/g, '/');

    return url;
  }
  noindex?: string;
  noindexSite?: string;
  nofollow?: string;
  avancedRobots?: Array<string>;

  constructor() {
    this.language = 'es';
    this.urlSite = '';
    this.titleContent = [{ content: '', variable: false } as IVariableTitleContent];
    this.titleSnippet = '%Titulo% %Separador% %Titulo del sitio%';
    this.rules = [];
    this.social = {};
  }
}

export interface IVariableTitleContent {
  content: string;
  variable: boolean;
}

export interface IRuleSeoConfiguration {
  code: string;
  description: string;
  value: boolean;
}

export interface IRuleSeo {
  code: string;
  description: string;
  descriptionError: string;
  type: 'Title' | 'Description' | 'Content' | 'General';
}

export interface ISeoSocialNetworking {
  ogType?: string; // website
  ogUrl?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  // ogImageUrl?: string;
  // ogImageSecureUrl?: string;
  // ogImageType?: string; // mime type
  // ogImageWidth?: string;
  // ogImageHeight?: string;
  // ogImageAlt?: string;
  ogLocale?: string; // es_ES
  ogSitename?: string;
  twitterCard?: string; // summary_large_image
  twitterSite?: string; // @username
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
}
