import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    NgZone,
    OnDestroy,
    Renderer2
} from '@angular/core';

/**
 * Directive to truncate the contained text, if it exceeds the element's boundaries
 * and append characters '…'.
 */
@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[topScrollbar]'
})
export class TopScrollbarDirective implements AfterViewInit, OnDestroy {
    private wrapper: HTMLElement;
    private elem: HTMLElement;
    private scrolledElem: HTMLElement;
    private topScrollbarElem: HTMLElement;
    private topScrollbarInnerElem: HTMLElement;
    private applyOnWindowResize = true;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private ngZone: NgZone
    ) { }

    ngAfterViewInit(): void {
        this.elem = this.elementRef.nativeElement;
        this.scrolledElem = this.elem.querySelector('[scrolledElement]');

        if (!this.scrolledElem) {
            return;
        }

        this.wrapper = this.renderer.createElement('div');
        this.renderer.insertBefore(this.elem.parentNode, this.wrapper, this.elem);
        this.renderer.appendChild(this.wrapper, this.elem);
        this.wrapper.style.position = 'relative';

        this.topScrollbarElem = this.renderer.createElement('span');
        this.renderer.addClass(this.topScrollbarElem, 'ngx-top-scrollbar');
        this.renderer.insertBefore(this.elem.parentNode, this.topScrollbarElem, this.elem);
        this._setStyles(this.topScrollbarElem, {
            display: 'block',
            position: 'absolute',
            top: '-4px',
            width: '100%',
            border: '0 none rgba(0, 0, 0, 0)',
            overflow: 'auto hidden',
            height: '8px',
            pointerEvents: 'none',
            zIndex: 1
        });

        this.topScrollbarInnerElem = this.renderer.createElement('span');
        this.renderer.addClass(this.topScrollbarInnerElem, 'ngx-top-scrollbar-inner');
        this.renderer.appendChild(this.topScrollbarElem, this.topScrollbarInnerElem);
        this._setStyles(this.topScrollbarInnerElem, {
            display: 'block',
            height: '8px',
            pointerEvents: 'none'
        });

        const coverLayer = this.renderer.createElement('span');
        this.renderer.addClass(coverLayer, 'ngx-top-scroll-cover');
        this.renderer.insertBefore(this.wrapper, coverLayer, this.elem);
        this._setStyles(coverLayer, {
            position: 'absolute',
            display: 'block',
            top: 0,
            right: 0,
            left: 0,
            height: '8px',
            pointerEvents: 'none'
        });

        this.elem.addEventListener('scroll', () => {
            this.topScrollbarElem.scrollLeft = this.elem.scrollLeft;
        });

        this.addResizeListener(true);
    }

    ngOnDestroy(): void {
        this.removeResizeListener();
    }

    private _setStyles(element: HTMLElement, styles: {[prop: string]: any}): void {
        Object.keys(styles)
        .forEach(prop => {
            this.renderer.setStyle(element, prop, styles[prop]);
        });
    }

    @HostListener('window:resize') onresize(): void {
        this.ngZone.run(() => {
            if (this.applyOnWindowResize) {
                this.adaptToTableWidth();
            }
        });
    }

    private addResizeListener(triggerNow = false): void {
        this.applyOnWindowResize = true;
        if (triggerNow) {
            this.adaptToTableWidth();
        }
    }

    private removeResizeListener(): void {
        this.applyOnWindowResize = false;
    }

    private adaptToTableWidth(): void {
      if (this.topScrollbarInnerElem) {
        this.topScrollbarInnerElem.style.width = `${this.scrolledElem.clientWidth}px`;
      }
    }
}
