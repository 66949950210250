import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { I18nEsPeModule } from '../../extra';
import { PROVIDER_I18N_ES_PE, PROVIDER_NGB_NATIVE_ADAPTER, PROVIDER_PARSE_FORMAT } from './constants/provider.constants';
import { BvlFormDatepickerComponent } from './form-datepicker.component';

@NgModule({
  declarations: [BvlFormDatepickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbDatepickerModule,
    I18nEsPeModule
  ],
  exports: [BvlFormDatepickerComponent],
  providers: [
    PROVIDER_NGB_NATIVE_ADAPTER,
    PROVIDER_PARSE_FORMAT,
    PROVIDER_I18N_ES_PE
  ]
})
export class BvlFormDatepickerModule { }
