import { ElementRef } from '@angular/core';

import { coerceBooleanProp } from '../helpers';
import { Constructor } from './constructor';

export interface CanSpecial {
    special: Special;
}

export interface HasElementRef {
    _elementRef: ElementRef;
}

export type Special = boolean;

export function mixinSpecial<T extends Constructor<HasElementRef>>(base: T): Constructor<CanSpecial> & T {
    return class extends base {
        private _special: Special;

        get special(): Special { return this._special; }
        set special(value: Special) {
            const special = coerceBooleanProp(value);

            if (this._special !== special) {

                if (special) {
                    this._elementRef.nativeElement.classList.add('g-admin-btn-special', 'g-font--italic', 'ml-3');
                } else {
                    this._elementRef.nativeElement.classList.remove('g-admin-btn-special', 'g-font--italic', 'ml-3');
                }

                this._special = special;
            }
        }

        constructor(...args: Array<any>) {
            super(...args);
        }
    };
}
