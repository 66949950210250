import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'bvl-link',
  templateUrl: './link.component.html'
})
export class BvlLinkComponent {

  @ViewChild('link') link: ElementRef;

  protected _color: string;
  @Input()
  get color(): string {
    return this._color;
  }
  set color(value: string) {
    this._renderer2.addClass(this.link.nativeElement, `g-admin-link-${value}`);
    this._color = value;
  }

  constructor(
    private _renderer2: Renderer2
  ) { }

}
