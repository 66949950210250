import { Injectable } from '@angular/core';
import { PermissionEndpoint } from '@bvl-admin/endpoints';
import { IPermissionResponse } from '@bvl-admin/statemanagement/models/permission.interface';
import { ApiService } from '@bvl-core/shared/helpers/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private _apiService: ApiService
  ) { }

  permissionsList(showSpin: boolean = false): Observable<Array<IPermissionResponse>> {
    return this._apiService.get(PermissionEndpoint.permissionsList, { preloader: showSpin });
  }

}
