import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BvlBreadcrumbComponent } from './breadcrumb.component';

@NgModule({
  declarations: [BvlBreadcrumbComponent],
  imports: [
    CommonModule
  ],
  exports: [BvlBreadcrumbComponent]
})
export class BvlBreadcrumbModule { }
