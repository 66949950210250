import { Component, Input } from '@angular/core';

import { IListImage } from '../extra';

@Component({
  selector: 'bvl-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent {
  @Input() image: IListImage;
  @Input() hasLink: boolean;
  @Input() link: boolean;
}
