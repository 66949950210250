import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DomChangeDirectiveModule } from '../extra';
import { SectionHeaderComponent } from './section-header.component';

@NgModule({
  imports: [CommonModule, DomChangeDirectiveModule],
  declarations: [SectionHeaderComponent],
  exports: [SectionHeaderComponent],
  entryComponents: [SectionHeaderComponent]
})
export class SectionHeaderModule { }
