import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { FormControlsBase, providerFormControlsBase } from '../form-controls.base';

@Component({
  selector: 'bvl-form-autocomplete',
  templateUrl: './form-autocomplete.component.html',
  providers: [providerFormControlsBase(BvlFormAutocompleteComponent)]
})

export class BvlFormAutocompleteComponent extends FormControlsBase implements OnInit {

  @HostBinding('attr.class') attrClass = 'w-100';
  @ViewChild('autoComplete') autoComplete: ElementRef;
  @ViewChild('instance') instance: NgbTypeahead;

  @Input() startLength: number;
  @Input() dataList: Array<any>;
  @Input() matchField: string;
  @Input() textField: string;

  @Input()
  get model(): any {
    return this._model;
  }
  set model(value: any) {
    this._model = value;
    this.propagateChange(this._model);
  }

  @Output() selectItem: EventEmitter<any>;

  list: Array<any>;
  iconSearch: boolean;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor() {
    super();
    this._activeText(true);
    this.startLength = 0;
    this.dataList = [];
    this.selectItem = new EventEmitter<any>();
  }

  ngOnInit(): void { }

  private _activeText(active: boolean): void {
    this.disabled = !active;
    this.iconSearch = active;
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
      .pipe(
      map(term => {
        if (term.length >= this.startLength) {
          return (this.dataList || []).filter(item => {
            const matchField = (item[this.matchField] && item[this.matchField].toLowerCase()) || '';

            return matchField.includes(term.toLowerCase());
          });
        }

        return [];
      }));
  }

  formatter = (item: any) => item[this.textField];

  oSelectItem(event): void {
    this._activeText(false);
    this.selectItem.next(event.item);
  }

  delete(): void {
    this._activeText(true);
    this.model = null;
    setTimeout(() => {
      this.autoComplete.nativeElement.focus();
    }, 0);
  }

  /* Takes the value  */
  writeValue(value: any): void {
    if (value !== undefined) {
      this._activeText(true);
      this.model = value;
    }
  }

}
