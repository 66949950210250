import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BvlButtonComponent } from './button.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [BvlButtonComponent],
  exports: [BvlButtonComponent]
})
export class BvlButtonModule { }
