export const RoleLang = {
  titles: {
    createRole: 'Crear rol',
    assignPermissions: 'Ahora elige los permisos que le vas a otorgar'
  },
  descriptions: {
    createRoleCms: 'Crea roles para tus usuarios, otorga los permisos necesarios para que puedan utilizar el cms'
  },
  labels: {
    role: 'Rol',
    permissions: 'Permisos'
  },
  buttons: {
    createRole: 'Crear nuevo rol'
  },
  links: {
    addRole: 'Agregar rol'
  }
};
