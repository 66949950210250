import { Component, Input } from '@angular/core';
import { getStorageLanguage, LANGUAGES } from '../extra';

@Component({
  selector: 'bvl-movements-summary',
  templateUrl: './movements-summary.component.html',
  styleUrls: ['./movements-summary.component.scss']
})
export class MovementsSummaryComponent {
  @Input() risingValues = 0;
  @Input() downsidingValues = 0;
  @Input() maintainedValues = 0;

  TEXT = LANGUAGES[getStorageLanguage()];
}
