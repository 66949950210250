import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DomChangeDirectiveModule } from '../extra/utils/google';
import { AccordionComponent } from './accordion.component';

@NgModule({
  imports: [
    CommonModule,
    DomChangeDirectiveModule
  ],
  declarations: [AccordionComponent],
  exports: [AccordionComponent]
})
export class AccordionModule { }
