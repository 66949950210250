import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from '@angular/animations';
import { AfterViewInit, Component, ContentChildren, ElementRef, Input, OnInit, QueryList, ViewChild } from '@angular/core';
import { merge, Subject, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { AngularUtil, GaUnsubscribeBase, ResizeService } from '../extra';
import { SliderItemDirective } from './slider-item.directive';
import { INTERVAL, TRANSITION_TIME } from './slider.constant';
import { IStartConfig } from './slider.interface';

@Component({
  selector: 'bvl-slider',
  templateUrl: './slider.component.html'
})
export class SliderComponent extends GaUnsubscribeBase implements OnInit, AfterViewInit {

  @ContentChildren(SliderItemDirective) itemList: QueryList<SliderItemDirective>;

  @ViewChild('sliderWrapper') private _sliderWrapper: ElementRef<HTMLDivElement>;

  private _interval: number;
  @Input()
  set interval(value: number) {
    this._interval = (value) ? value : INTERVAL;
    this.startConfig.interval = TRANSITION_TIME + this._interval;
  }
  get interval(): number {
    return this._interval;
  }

  @Input() autoPlay: boolean;

  currentItem: number;
  startConfig: IStartConfig;

  private _itemWidth: number;
  private _player: AnimationPlayer;
  private _start$ = new Subject<IStartConfig>();
  private _stop$ = new Subject<void>();

  constructor(
    protected _element: ElementRef,
    private _animationBuilder: AnimationBuilder,
    private _resizeService: ResizeService
  ) {
    super(_element);
    this.startConfig = { isNext: true, interval: TRANSITION_TIME + INTERVAL };
    this.autoPlay = true;
    this.currentItem = 0;
  }

  ngOnInit(): void {
    this._resizeService.getResizeEvent()
      .subscribe(() => {
        this._itemWidth = this._getItemWidth(this._sliderWrapper.nativeElement.firstElementChild);
      });
  }

  ngAfterViewInit(): void {
    this._itemWidth = this._getItemWidth(this._sliderWrapper.nativeElement.firstElementChild);

    this._start$
        .pipe(
          takeUntil(this.unsubscribeDestroy$),
          filter(() => {
            return !!this.itemList.length;
          }),
          switchMap((response: IStartConfig) => {
            return timer(response.interval)
              .pipe(
                takeUntil(merge(this._stop$, this.unsubscribeDestroy$)),
                map(() => response)
              );
          })
        )
        .subscribe((response: IStartConfig) => {
          if (response.isNext) {
            this.next();
          } else {
            this.prev();
          }
        });

    if (this.autoPlay) {
      this._start$.next(this.startConfig);
    }
  }

  private _getItemWidth(element: any): number {
    const offsetElement = element && AngularUtil.offset(element);

    return (offsetElement)
              ? offsetElement.width
              : 0;
  }

  private _buildAnimation(offset): AnimationFactory {
    return this._animationBuilder.build([
      animate(`${TRANSITION_TIME}ms ease-in`, style({ transform: `translateX(-${offset}px)` }))
    ]);
  }

  playSlider(): void {
    const offset = this.currentItem * this._itemWidth;
    const myAnimation: AnimationFactory = this._buildAnimation(offset);
    this._player = myAnimation.create(this._sliderWrapper.nativeElement);
    this._player.play();
  }

  next(): void {
    const isLast = ((this.currentItem + 1) === this.itemList.length);
    if (!isLast) {
      this.currentItem = (this.currentItem + 1) % this.itemList.length;
      this.playSlider();
    }

    if (this.autoPlay) {
      const config = {
        isNext: !isLast,
        interval: (!isLast)
                    ? this.startConfig.interval
                    : 0
      };
      this._start$.next(config);
    }
  }

  prev(): void {
    const isFirst = (this.currentItem === 0);
    if (!isFirst) {
      this.currentItem = ((this.currentItem - 1) + this.itemList.length) % this.itemList.length;
      this.playSlider();
    }

    if (this.autoPlay) {
      const config = {
        isNext: isFirst,
        interval: (isFirst)
                    ? 0
                    : this.startConfig.interval
      };
      this._start$.next(config);
    }
  }

  payer(index: number): void {
    if (index !== this.currentItem) {
      if (index > this.currentItem) {
        this.currentItem = index - 1;
        this.next();
      } else {
        this.currentItem = index + 1;
        this.prev();
      }
    }
  }

}
