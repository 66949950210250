import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { takeUntil } from 'rxjs/operators';

import { GaUnsubscribeBase } from '../extra/utils/google';

@Component({
  selector: 'bvl-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [
    trigger('expand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', borderTop: '1px solid #979797'})),
      transition('expanded <=> collapsed', animate('.2s cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class AccordionComponent extends GaUnsubscribeBase implements OnChanges {
  private _variant: 'default' | 'dark' = 'default';
  expanded = false;
  active = true;
  @Input() activeOn?: string;
  @Input() iconCollapsed = 'icon-plus';
  @Input() iconExpanded = 'icon-minus';
  @Input() id: any = 0;

  @Input()
  get variant(): any { return this._variant; }
  set variant(value: any) {
    this._variant = ['default', 'dark'].indexOf(value) > -1 ? value : 'default';
  }

  @Output() state = new EventEmitter<any>();

  constructor(
    protected _elementRef: ElementRef,
    private _media: ObservableMedia
  ) {
    super(_elementRef);
  }

  ngOnChanges(): void {
    if (this.activeOn) {
      this._media
      .asObservable()
      .pipe(takeUntil(this.unsubscribeDestroy$))
      .subscribe(() => {
        this.expanded = !this._media.isActive(this.activeOn);
        this.active = !this.expanded;
        this._emitState();
      });
    }
  }

  toggle(): void {
    this.expanded = !this.expanded;
    this._emitState();
  }

  private _emitState(): void {
    this.state.emit(this.expanded ? 'expanded' : 'collapsed');
  }
}
