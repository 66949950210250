import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardCellComponent } from './card-cell/card-cell.component';
import { BvlCardContentComponent } from './card-content/card-content.component';
import { BvlCardHeaderComponent } from './card-header/card-header.component';
import { BvlCardWrapperComponent } from './card-wrapper/card-wrapper.component';
import { BvlCardComponent } from './card/card.component';

@NgModule({
  declarations: [
    BvlCardComponent,
    BvlCardHeaderComponent,
    BvlCardContentComponent,
    BvlCardWrapperComponent,
    CardCellComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BvlCardComponent,
    BvlCardHeaderComponent,
    BvlCardContentComponent,
    BvlCardWrapperComponent,
    CardCellComponent
  ]
})
export class BvlCardModule { }
