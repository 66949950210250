import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BvlAlertErrorComponent } from './alert-error.component';

@NgModule({
  declarations: [BvlAlertErrorComponent],
  imports: [
    CommonModule
  ],
  exports: [BvlAlertErrorComponent]
})
export class BvlAlertErrorModule { }
