import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DomChangeDirective } from './dom-change.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DomChangeDirective],
  exports: [DomChangeDirective]
})
export class DomChangeDirectiveModule {
}
