import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { fromEvent, Observable, Subscription } from 'rxjs';

import { LANGUAGES } from '../extra/settings/constants';
import { GaUnsubscribeBase } from '../extra/utils/google';
import { getStorageLanguage } from '../extra/utils/storage-language';
import { checkIfAudio, checkValueIsMedia, YOUTUBE_REGEX } from '../media-player/media-player';
import { ConfigurationService } from '../profile/configuration.service';
import { IUserProfile } from '../profile/profile.model';
import { WatchListService } from '../watchlist-providers/watchlist.services';
import { EntryAction, EntryStyle, IEntry, IPlayListRequest } from './entry';

let time;

@Component({
  selector: 'bvl-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntryComponent extends GaUnsubscribeBase implements OnInit {

  private _userProfile: IUserProfile;

  obs: Observable<Event>;
  subscription: Subscription;
  actionsShown = false;
  isMedia = false;
  isVideo = false;
  isAudio = false;
  mediaLoading = false;
  showMedia = false;
  isMobile = window.innerWidth < 767;

  @Input() entry: IEntry;
  @Input() actions?: Array<EntryAction> = [];
  @Input() variant?: EntryStyle = 'default';
  @Input() env?: string;

  @Output() changeEntry: EventEmitter<any>;

  TEXT = LANGUAGES[getStorageLanguage()];

  constructor(
    private _configService: ConfigurationService,
    private _watchListService: WatchListService,
    protected _elementRef: ElementRef,
    private _sanitizer: DomSanitizer
  ) {
    super(_elementRef);
    this._userProfile = this._configService.getUserProfile();
    this.changeEntry = new EventEmitter<any>();
  }

  setEventsGA(): void { }

  ngOnInit(): void {
    if (this.env) {
      this._watchListService.setEnviroment(this.env);
    }
    if (this.actions.length) {
      this.obs = fromEvent(document, 'click');
    }

    const url = this.setUrl(this.entry.url);

    this.isMedia = checkValueIsMedia(url);
    this.isVideo = YOUTUBE_REGEX.test(url);
    this.isAudio = checkIfAudio(url);
  }

  canDo(action): boolean {
    return this.actions.indexOf(action) !== -1;
  }

  showingActions(): void {
    this.actionsShown = true;
    this.subscription = this.obs.subscribe(event => {
      if (!this._elementRef.nativeElement.contains(event.target)) {
        this.hiddingActions();
      }
    });
  }

  hiddingActions(): void {
    this.actionsShown = false;
    this.subscription.unsubscribe();
  }
  updateVideoState(video): void {
    if (this._userProfile !== null && this.env) {
      const body: IPlayListRequest = {
        idUser: this._userProfile.cognitoUsername,
        title: video.title,
        url: video.url,
        category: video.label,
        author: video.author,
        add: !video.saved
      };

      this._watchListService.updatePlayList(body)
        .subscribe(() => {
          video.saved = !video.saved;
          this.hiddingActions();
          this.changeEntry.emit(video);
        });
    } else {
      location.href = `${location.origin}/login`;
    }
  }
  setUrl(url): string {
    if (url && typeof url === 'string') {
      const findProtocol = url.match(/(https|http)/);

      return findProtocol !== null ? url : `https://${url}`;
    }

    return '#';
  }

  initTimeout = () => {
    if (window.innerWidth > 767) {
      if (!this.showMedia) {
        this.mediaLoading = true;
        time = setTimeout(() => {
          this.showMedia = true;
          this.notifyMediaPlayer();
          this.initMediaListener();
        }, 2050);
      }
    }
  }

  sanitize = (url: string) => this._sanitizer.bypassSecurityTrustUrl(url);

  clearTimeout = () => {
    if (window.innerWidth > 767) {
      this.mediaLoading = false;
      clearTimeout(time);
    }
  }

  notifyMediaPlayer(): void {
    const event = new CustomEvent('init-media', {
      detail: { data: this.entry }
    });

    document.dispatchEvent(event);
  }

  initMediaListener(): void {
    document.addEventListener('media-player', ev => {
      const { detail } = (ev) as any;
      const { data } = detail;

      if (!data) {
        this.showMedia = false;
      } else {
        const itsSameData = this.entry && JSON.stringify(data) === JSON.stringify(this.entry);

        if (!itsSameData) {
          this.showMedia = false;
        }
      }

    });
  }

}
