export const EDITOR_TYPES = {
  commonComponent: {
    code: 1
  },
  publication: {
    code: 2
  },
  seePublication: {
    code: 3
  }
};
