import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args?: number): any {
    const limit = args || 20;
    if (value && value.length > limit) {
      return `${value.substring(0, limit)}...`;
    } else {
      return value;
    }
  }
}
