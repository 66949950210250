interface IOffsetElement {
  top: number;
  left: number;
  height: number;
  width: number;
  bottom?: number;
}

// @dynamic
export class AngularUtil {

  public static isUndefined(value: any): boolean {
    return typeof value === 'undefined';
  }

  public static isString(value: any): boolean {
    return typeof value === 'string';
  }

  public static isArray(value: any): boolean {
    return Array.isArray(value);
  }

  public static isObject(value: any): boolean {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
  }

  public static isFunction(value: any): boolean {
    return typeof value === 'function';
  }

  public static clone(value: any): any {
    return JSON.parse(JSON.stringify(value));
  }

  public static toJson(value: any): string {
    return JSON.stringify(value);
  }

  public static fromJson(value: any): any {
    return JSON.parse(value);
  }

  public static toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  public static isNumber(value: any): value is number {
    return !isNaN(AngularUtil.toInteger(value));
  }

  public static isInteger(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  }

  public static isDefined(value: any): boolean {
    return value !== undefined && value !== null;
  }

  public static padNumber(value: number): string {
    if (AngularUtil.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }

  public static hasClassName(element: any, className: string): boolean {
    return element && element.className && new RegExp(`(^|\\s)${className}(\\s|$)`).test(element.className);
  }

  public static offset(element: any, round = true): IOffsetElement {
    const bcrElement = element.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    const offsetElement = {
      top: +bcrElement.top + +scrollTop,
      left: +bcrElement.left + +scrollLeft,
      height: bcrElement.height || element.offsetHeight,
      width: bcrElement.width || element.offsetWidth,
      bottom: bcrElement.bottom
    };

    if (round) {
      offsetElement.top = Math.round(offsetElement.top);
      offsetElement.left = Math.round(offsetElement.left);
      offsetElement.height = Math.round(offsetElement.height);
      offsetElement.width = Math.round(offsetElement.width);
    }

    return offsetElement;
  }

  public static formatNumberToString(number: Number, decimal?: Number): string {
    return number.toFixed(decimal as number)
      .replace(/(\d)(?:(?=(\d{3})+(?=\.))|(?=(?:\d{3})+\b))/g, '$1,');
  }

}

export const coerceBooleanProp = (value: any): boolean =>
  (value !== null && value !== undefined && `${value}`.toLowerCase() !== 'false' && `${value}`.toLowerCase() !== 'null');

export const coerceNumberProp = (value: any): number =>
  (!isNaN(parseFloat(value)) && !isNaN(Number(value)) ? Number(value) : undefined);
