import { Component } from '@angular/core';

@Component({
  selector: 'bvl-tab-content',
  templateUrl: './tab-content.component.html'
})
export class BvlTabContentComponent {

  constructor() { }

}
