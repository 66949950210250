import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { BvlAutocompleteComponent } from './autocomplete.component';

@NgModule({
  declarations: [BvlAutocompleteComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbTypeaheadModule
  ],
  exports: [BvlAutocompleteComponent]
})
export class BvlAutocompleteModule { }
