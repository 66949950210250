import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BvlRadioButtonComponent } from './radio-button.component';

@NgModule({
  declarations: [BvlRadioButtonComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [BvlRadioButtonComponent]
})
export class BvlRadioButtonModule { }
