import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { BvlPaginatorComponent } from './paginator.component';

@NgModule({
  declarations: [BvlPaginatorComponent],
  imports: [
    CommonModule,
    NgbPaginationModule
  ],
  exports: [BvlPaginatorComponent]
})
export class BvlPaginatorModule { }
