import { Component, HostListener, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bvl-networks-bar',
  templateUrl: './networks-bar.component.html',
  styleUrls: ['./networks-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class NetworksBarComponent {
  inAdmin: boolean;

  private _paddingTop = +window
    .getComputedStyle(document.querySelector('main > .page-section'), null)
    .getPropertyValue('padding-top')
    .replace('px', '');

  @HostListener('window:scroll', ['$event']) windowScroll(event: Event): void {

    const windowScroll = event.target['scrollingElement']
      ? event.target['scrollingElement'].scrollTop
      : (event.target as any).documentElement.scrollTop;

    this._toggleFixed(windowScroll);

  }

  constructor() {
    this.inAdmin = document.body.classList.contains('admin');
  }

  share(network): void {
    const pageTitle = document.querySelector('title');
    const metaDescription = document.querySelector('meta[name="description"]');
    const title = pageTitle ? (pageTitle as any).text : '';
    const content = metaDescription ? (metaDescription as any).content : '';

    const networkShareUrl = (() => {
      switch (network) {
        case 'linkedin':
          return `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(document.URL)}&title=${title}&summary=${content}`;
        case 'twitter':
          return `https://twitter.com/intent/tweet?url=${encodeURIComponent(document.URL)}&text=${title} - ${content}`;
        case 'facebook':
          return `http://www.facebook.com/sharer.php?u=${encodeURIComponent(document.URL)}&=title=${title}&summary=${content}`;
        default:
          return `mailto:email@address.com?subject=Comparto esta pagina de BVL - ${title}&body=${document.URL}`;
      }
    })();

    window.open(networkShareUrl, '', '_blank, width=500, height=500, resizable=yes, scrollbars=yes');
  }

  private _toggleFixed(windowScroll: number = document.documentElement.scrollTop): void {
    const menu: HTMLElement = document.querySelector('.networks-bar');
    const scrollByContent = windowScroll - this._paddingTop;
    const isMenuAtBottom = scrollByContent >= (this._getContentHeight() - menu.offsetHeight) - (menu.offsetHeight / 4);
    menu.classList[isMenuAtBottom ? 'add' : 'remove']('bottom');
  }

  private _getContentHeight = (): number => document.getElementById('page-content').offsetHeight - this._getHeaderHeight() + 80;

  private _getHeaderHeight(): number {
    const header: HTMLElement = document.querySelector(`
      ${this.inAdmin
        ? '.g-admin-editor-header'
        : '#header'}`
    );
    const subMenu: HTMLElement = document.querySelector('.networks-bar');
    const headerHeight = header.offsetHeight + (this.inAdmin
      ? 100
      : subMenu.offsetHeight);

    if (header) {
      if (subMenu) {
        return headerHeight + this._paddingTop;
      }

      return headerHeight + this._paddingTop;
    } else {
      return this._paddingTop;
    }
  }
}
