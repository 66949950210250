import { ElementRef } from '@angular/core';

import { Constructor } from './constructor';

export interface ICanSize {
    size: Size;
}

export interface IHasElementRef {
    _elementRef: ElementRef;
}

export type Size = 'sm' | 'md' | 'lg' | undefined;

export const mixinSize = <T extends Constructor<IHasElementRef>>(
    base: T,
    defaultSize?: Size
): Constructor<ICanSize> & T => {

    return class extends base {
        private _size: Size;

        get size(): Size { return this._size; }
        set size(value: Size) {
            const size = value || defaultSize;

            if (size !== this._size) {

                if (this._size) {
                    this._elementRef.nativeElement.classList.remove(`size-${this._size}`);
                }
                if (size) {
                    this._elementRef.nativeElement.classList.add(`size-${size}`);
                }

                this._size = size;
            }
        }

        constructor(...args: Array<any>) {
            super(...args);

            this.size = defaultSize;
        }
    };
};
