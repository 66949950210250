import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BvlSwitchComponent } from './switch.component';

@NgModule({
  declarations: [BvlSwitchComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [BvlSwitchComponent]
})
export class BvlSwitchModule { }
