import { Component, Input, OnInit } from '@angular/core';
import { FormControlsBase, providerFormControlsBase } from '@bvl-core/ui/common';

@Component({
  selector: 'bvl-select',
  templateUrl: './select.component.html',
  providers: [providerFormControlsBase(BvlSelectComponent)]
})
export class BvlSelectComponent extends FormControlsBase implements OnInit {

  protected _model: any;
  @Input()
  get model(): any {
    return this._model;
  }
  set model(value: any) {
    this._setModelAndOldModel(value);
  }

  protected _dataList: Array<any>;
  @Input()
  get dataList(): Array<any> {
    return this._dataList;
  }
  set dataList(value: Array<any>) {
    this._dataList = value || [];
    /** Necessary for the first charge */
    if (this._dataList.length && this._oldModel && this._model[this.valueField] !== this._oldModel[this.valueField]) {
      this._setModelAndOldModel(this._oldModel);
    }
  }

  @Input() valueField: string;
  @Input() textField: string;

  private _oldModel: any;

  constructor() {
    super();
  }

  private _getValue(value: any): any {
    return (value)
              ? this.dataList.find(fv => fv[this.valueField] === value[this.valueField]) || ''
              : '';
  }

  private _setModelAndOldModel(value: any): void {
    this._model = this._getValue(value);
    this._oldModel = this._model;
    this.propagateChange(this._model);
  }

  ngOnInit(): void { }

  /* Takes the value  */
  writeValue(value: any): void {
    this._model = this._getValue(value);
    /** Necessary for the first charge */
    this._oldModel = (this._model)
                        ? this._model
                        : value;

    this.propagateChange(this._model);
  }

}
