import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BvlInputComponent } from './input.component';

@NgModule({
  declarations: [BvlInputComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [BvlInputComponent]
})
export class BvlInputModule { }
