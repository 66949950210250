import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { BvlFormRadioComponent } from './form-radio.component';

@NgModule({
  declarations: [BvlFormRadioComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [BvlFormRadioComponent]
})
export class BvlFormRadioModule { }
