import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

import { GaUnsubscribeBase } from '../extra';
import { Breakpoint } from '../extra/typings';
import { IFilter } from './filter';

let filterGroupUniqueId = 0;

@Component({
  selector: 'bvl-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent extends GaUnsubscribeBase implements OnInit {

  mediaQueryWatcher: Subscription;
  name = `filter-group-${filterGroupUniqueId++}`;
  @Input() title?: string;
  @Input() iconTitle?: string;
  @Input() filters: Array<IFilter>;
  @Input() active?: IFilter;
  @Input() showSelectOn?: Breakpoint;
  @Output() filterChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    protected _element: ElementRef,
    public media: ObservableMedia
  ) {
    super(_element);
  }

  setEventsGA(): void { }

  ngOnInit(): void {
    if (!this.active) {
      this.active = this.filters.some(filter => !!filter.active)
        ? this.filters.find(filter => filter.active)
        : this.filters.find(filter => filter.id === null);
    }
  }

  _onFilterChange(filterId: number): void {
    this.filterChanged.emit(filterId);
  }
}
