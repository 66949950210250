import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: '[collapse]',
  template: `<ng-content></ng-content>`,
  animations: [
    trigger('collapse', [
      state('collapsed', style({
        overflow: 'hidden',
        height: '0',
        minHeight: '0'
      })),
      state('expanded', style({
        height: '*'
      })),
      transition('expanded <=> collapsed', animate('.2s cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
// tslint:disable-next-line: component-class-suffix
export class CollapseDirective {

  @HostBinding('@collapse') state;

  protected _collapse;
  @Input()
  get collapse(): boolean {
    return this._collapse;
  }
  set collapse(value: boolean) {
    const st = (value)
                ? 'collapsed'
                : 'expanded';
    this.state = st;
    this._collapse = value;
  }

  constructor() { }

}
