import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { IBackgroundImage } from '../../component';
import { ResizeService, SCREEN_BREAKPOINTS } from '../../extra';

@Directive({
  selector: '[bvlBackgroundImgUrl]'
})
export class BackgroundImgUrlDirective implements OnInit {

  @Input() bvlBackgroundImgUrl: IBackgroundImage;

  constructor(
    private _elementRef: ElementRef,
    private _renderer2: Renderer2,
    private _resizeService: ResizeService
  ) { }

  ngOnInit(): void {
    const sizeImage = (this._resizeService.is(SCREEN_BREAKPOINTS.xs))
                        ? 'small' :
                       (this._resizeService.is(SCREEN_BREAKPOINTS.sm) || this._resizeService.is(SCREEN_BREAKPOINTS.md))
                        ? 'medium'
                        : 'large';

    const imageProp = this.bvlBackgroundImgUrl && this.bvlBackgroundImgUrl[sizeImage]
                        ? `url( ${this.bvlBackgroundImgUrl[sizeImage].imageUri} )`
                        : '';
    this._renderer2.setStyle(this._elementRef.nativeElement, 'background-image', imageProp);
  }

}
