import { Component, Input } from '@angular/core';

@Component({
  selector: 'bvl-great-price',
  templateUrl: './great-price.component.html',
  styleUrls: ['./great-price.component.scss']
})
export class GreatPriceComponent {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() price: string;
  @Input() salePrice: string;
  @Input() btnText: string;

  url = '#';

}
