import { Injectable } from '@angular/core';
import { PageEndpoint } from '@bvl-admin/endpoints';
import { ACTION_TYPES, LANGUAGE_TYPES, STATES_CODE, TYPES_CODE } from '@bvl-admin/settings/constants/general.constant';
import { PAGE_TYPES_CODE } from '@bvl-admin/settings/constants/page.constant';
import { STORAGE_KEY } from '@bvl-admin/settings/constants/storage-key.constant';
import {
  ICategory,
  IPageApprovalPaginationRequest,
  IPageAutocompleteResponse,
  IPagePaginationRequest,
  IPagePaginationResponse,
  IPageResponse,
  ISavePageRequest,
  ISiteMenuResponse
} from '@bvl-admin/statemanagement/models/page.interface';
import { ITemplateResponse } from '@bvl-admin/statemanagement/models/template.interface';
import { ApiService } from '@bvl-core/shared/helpers/api';
import { SpinnerService } from '@bvl-core/shared/helpers/spinner';
import { StorageService } from '@bvl-core/shared/helpers/util';
import { forkJoin, Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(
    private _apiService: ApiService,
    private _spinnerService: SpinnerService,
    private _storageService: StorageService
  ) { }

  siteMenu(showSpin: boolean = false): Observable<Array<ISiteMenuResponse>> {
    return this._apiService.get(PageEndpoint.siteMenu, { preloader: showSpin });
  }

  approvalsList(params: IPageApprovalPaginationRequest, showSpin: boolean = false): Observable<IPagePaginationResponse> {
    return this._apiService.post(PageEndpoint.approvalsList, params, { preloader: showSpin });
  }

  pagesList(params: IPagePaginationRequest, showSpin: boolean = false): Observable<IPagePaginationResponse> {
    return this._apiService.post(PageEndpoint.pagesList, params, { preloader: showSpin });
  }

  dashboard(showSpin: boolean = false): Observable<Array<IPagePaginationResponse>> {
    const pipes = [];
    if (showSpin) {
      this._spinnerService.showSpinner();
      pipes.push(finalize(() => { this._spinnerService.hideSpinner(); }));
    }

    const paramsPagesList = (state: number) => {
      return {
        namePage: '',
        status: [state],
        userList: [],
        startDate: '',
        endDate: '',
        size: 2,
        page: 1
      } as IPagePaginationRequest;
    };

    const pagesList = (state): Observable<IPagePaginationResponse> => {
      const params = paramsPagesList(state);

      return this.pagesList(params);
    };

    const observable = forkJoin(
      [
        pagesList(STATES_CODE.published),
        pagesList(STATES_CODE.inEdition),
        pagesList(STATES_CODE.onHold),
        pagesList(STATES_CODE.observed)
      ]
    );

    return (showSpin)
            ? this._apiService.formatObservablePipe(observable, pipes)
            : observable;
  }

  templateList(showSpin: boolean = false): Observable<Array<ITemplateResponse>> {
    return this._apiService.get(PageEndpoint.templateList, { preloader: showSpin });
  }

  templateByCode(templateCode: string, showSpin: boolean = false): Observable<ITemplateResponse> {
    const params = { templateCode };

    return this._apiService.get(PageEndpoint.templateByCode, { params, preloader: showSpin });
  }

  savePage(params: ISavePageRequest, showSpin: boolean = false): Observable<IPageResponse> {
    return this._apiService.post(PageEndpoint.savePage, params, { preloader: showSpin });
  }

  createPage(template: ITemplateResponse, showSpin: boolean = false): Observable<IPageResponse> {
    const params = {
      name: null,
      pageType: PAGE_TYPES_CODE.publication,
      type: TYPES_CODE.dynamic,
      status: STATES_CODE.new,
      languages: LANGUAGE_TYPES.map(languageType => {
        return {
          language: languageType.code,
          metadata: {},
          content: {
            html: template.html,
            refComponents: template.refComponents
          }
        };
      })
    } as ISavePageRequest;

    return this.savePage(params, showSpin);
  }

  categoriesList(showSpin: boolean = false): Observable<Array<ICategory>> {
    return this._apiService.get(PageEndpoint.categoriesList, { preloader: showSpin });
  }

  pagePublishedById(idPage: string, showSpin: boolean = false): Observable<IPageResponse> {
    const params = { idPage };

    return this._apiService.get(PageEndpoint.pagePublishedById, { params, preloader: showSpin });
  }

  pageById(idPage: string, pageState: number, showSpin: boolean = false): Observable<IPageResponse> {
    const pageEditor = this.getPageEditor();
    const params = { idPage };

    return (pageEditor && pageEditor.idPage === idPage && pageEditor.status === pageState)
            ? of(pageEditor)
            : (pageState === STATES_CODE.published)
              ? this.pagePublishedById(idPage, showSpin)
              : this._apiService.get(PageEndpoint.pageById, { params, preloader: showSpin });
  }

  getPage(): IPageResponse {
    return this._storageService.getItemObject(STORAGE_KEY.page);
  }

  setPage(page: IPageResponse): void {
    this._storageService.setItem(STORAGE_KEY.page, page);
  }

  getPageEditor(): IPageResponse {
    return this._storageService.getItemObject(STORAGE_KEY.pageEditor);
  }

  setPageEditor(pageEditor: IPageResponse): void {
    this._storageService.setItem(STORAGE_KEY.pageEditor, pageEditor);
  }

  editPage(idPage: string, showSpin: boolean = false): Observable<any> {
    const params = { idPage };

    return this._apiService.get(PageEndpoint.editPage, { params, preloader: showSpin });
  }

  publishPage(idPage: string, showSpin: boolean = false): Observable<any> {
    const params = { idPage };

    return this._apiService.post(PageEndpoint.publishPage, params, { preloader: showSpin });
  }

  requestPublication(idPage: string, showSpin: boolean = false): Observable<any> {
    const params = { idPage };

    return this._apiService.post(PageEndpoint.requestPublication, params, { preloader: showSpin });
  }

  publicationTypePage(publicationType: string, idPage: string, showSpin: boolean = false): Observable<any> {
    return (ACTION_TYPES.publish === publicationType)
              ? this.publishPage(idPage, showSpin)
              : this.requestPublication(idPage, showSpin);
  }

  cancelPageChanges(idPage: string, showSpin: boolean = false): Observable<any> {
    const params = { idPage };

    return this._apiService.post(PageEndpoint.cancelPageChanges, params, { preloader: showSpin });
  }

  observePage(idPage: string, showSpin: boolean = false): Observable<any> {
    const params = { idPage };

    return this._apiService.post(PageEndpoint.observePage, params, { preloader: showSpin });
  }

  revertPage(idPage: string, showSpin: boolean = false): Observable<any> {
    const params = { idPage };

    return this._apiService.post(PageEndpoint.revertPage, params, { preloader: showSpin });
  }

  pageHistory(idPage: string, showSpin: boolean = false): Observable<Array<IPageResponse>> {
    const params = { idPage };

    return this._apiService.get(PageEndpoint.pageHisotry, { params, preloader: showSpin });
  }

  restorePageHisotry(id: string, showSpin: boolean = false): Observable<IPageResponse> {
    const params = { id };

    return this._apiService.get(PageEndpoint.restorePageHisotry, { params, preloader: showSpin });
  }

  getPagesAutocopleteByPath(value: string = '', showSpin: boolean = false): Observable<IPageAutocompleteResponse> {
    return this._apiService.get(PageEndpoint.getPagesAutocopleteByPath, { preloader: showSpin, params: { value } });
  }

  getPagesAutocopleteByPathAndTitle(value: string = '', showSpin: boolean = false): Observable<IPageAutocompleteResponse> {
    return this._apiService.get(PageEndpoint.getPagesAutocopleteByPathAndTitle, { preloader: showSpin, params: { value } });
  }

  deletePage(idPage: string, showSpin: boolean = false): Observable<any> {
    const params = { idPage };

    return this._apiService.get(PageEndpoint.deletePage, { params, preloader: showSpin });
  }

}
