import { Component, Input } from '@angular/core';
import { coerceBooleanProp } from '@bvl-core/ui/common/helpers';

@Component({
  selector: 'bvl-card',
  templateUrl: './card.component.html'
})
export class BvlCardComponent {

  protected _accordion: boolean;
  @Input()
  get accordion(): boolean {
    return this._accordion;
  }
  set accordion(value: boolean) {
    this._accordion = coerceBooleanProp(value);
  }

  protected _disabled: boolean;
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProp(value);
  }

  constructor() {
    this._disabled = false;
  }

}
