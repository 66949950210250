export class StringUtil {

  public static firstLettersString(value: string): string {
    let firstLetters = '';
    if (value) {
      firstLetters = `${value} `.replace(/([a-zA-Z]{0,} )/g, match => {
        return (match.trim()[0]);
      });
    }

    return firstLetters;
  }

  public static replaceAll(value: string, valueSearch: string, valueReplace: string): string {
    return value.replace(new RegExp(valueSearch, 'g'), valueReplace);
  }

  public static withoutDiacritics(value: string): string {
    // Ref: https://en.wikipedia.org/wiki/Combining_Diacritical_Marks
    return (value || '').normalize('NFD')
      .replace(new RegExp('[\\u0300-\\u036f]', 'g'), '');
  }

  public static slugify(value: string): string {
    return value.toString()
      .toLowerCase()                              // LowerCase
      .replace(new RegExp('\\s+', 'g'), '-')      // Replace spaces with -
      .replace(new RegExp('[^\\w\\-]+', 'g'), '') // Remove all non-word chars
      .replace(new RegExp('\\-\\-+', 'g'), '-')   // Replace multiple - with single -
      .replace(new RegExp('^-+/'), '')            // Trim - from start of text
      .replace(new RegExp('-+$'), '');            // Trim - from end of text
  }

  public static isAnyFileExtension(value: string): boolean {
    const ANY_EXTENSION_REGEX = /\.[\w]+$/gi;

    return ANY_EXTENSION_REGEX.test(value);
  }

  public static subStringUntilCharacter(value: string, character: string): string {
    const CHAR_REGEX = new RegExp(character, 'gi');
    const end = value.search(CHAR_REGEX);

    return (end > -1)
      ? value.slice(0, end)
      : value;
  }

  public static removeSpecialChars(value: string): string {
    const SPECIAL_CHARS_REGEX = /[^èéòàùì\w\s]/gi;

    return value.replace(SPECIAL_CHARS_REGEX, '');
  }

  public static getFileName(filePath: string, withExtension = false): string {
    const FILE_NAME_REGEX = (withExtension)
      ? /[ \w.]+$/gi
      : /[\w]+?(?=\.[\w]+$)/gi;
    const fileName = filePath.match(FILE_NAME_REGEX);

    return (fileName)
      ? fileName[0]
      : '';
  }

}

export const StringSlugify = (value: string): string => {
  return value.toString()
    .toLowerCase()                              // LowerCase
    .replace(new RegExp('\\s+', 'g'), '-')      // Replace spaces with -
    .replace(new RegExp('[^\\w\\-]+', 'g'), '') // Remove all non-word chars
    .replace(new RegExp('\\-\\-+', 'g'), '-')   // Replace multiple - with single -
    .replace(new RegExp('^-+/'), '')            // Trim - from start of text
    .replace(new RegExp('-+$'), '');            // Trim - from end of text
};
