import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bvl-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteComponent implements OnChanges {
  private _data: any;
  @Input()
  get data(): any {
    return this._data;
  }
  set data(value) {
    this._data = value;
  }
  @Input() active: boolean;
  @Input() placeholder: string;
  @Output() itemSelected = new EventEmitter<any>();
  results: Array<any> = [];
  search = '';
  focused = 0;

  constructor() {}

  ngOnChanges(change: SimpleChanges): void {
    if (change.active && change.active.currentValue) {
      this._setFocus();
    }
  }

  filterResults(input: string): void {
    this.results = [];
    if (input.length > 1) {
      this.data.forEach(item => {
        const nemo = item.nemonico.toLowerCase();
        if (nemo.indexOf(input.toLowerCase()) !== -1) {
          this.results.push(item);
        }
      });
    }
    this.results.forEach((item, i) => { item.id = i; });
  }
  emitItemSeleted(item): void {
    this.active = false;
    this.results = [];
    this.search = '';
    this.itemSelected.emit({ item, active: this.active});
  }
  private _setFocus(): void {
    const input: HTMLInputElement = document.querySelector('.autocomplete .input input');
    input.autofocus = true;
  }

  @HostListener('keydown', ['$event']) keyDownAutocomplete(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown' && this.focused !== this.results.length - 1) {
      this.focused++;
    } else if (event.key === 'ArrowUp' && this.focused > 0) {
      this.focused--;
    }
    if (event.key === 'Enter') {
      const selected = this.results.find(item => item.id === this.focused);
      this.emitItemSeleted(selected.nemonico);
    }
  }
}
