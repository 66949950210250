import { ModuleWithProviders, NgModule } from '@angular/core';

import { WatchListService } from './watchlist.services';

@NgModule({
  imports: [],
  declarations: [],
  exports: []
})
export class WatchlistProvidersModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: WatchlistProvidersModule,
      providers: [WatchListService]
    };
  }
}
