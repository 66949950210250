import { Component, Input, OnInit } from '@angular/core';
import { FormControlsBase, providerFormControlsBase } from '@bvl-core/ui/common';

@Component({
  selector: 'bvl-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [providerFormControlsBase(BvlCheckboxComponent)]
})
export class BvlCheckboxComponent extends FormControlsBase implements OnInit {

  protected _model: boolean;
  @Input()
  get model(): boolean {
    return this._model;
  }
  set model(value: boolean) {
    this._model = value;
    const viewValue = (this._model)
                        ? this.value || this._model
                        : false;
    this.propagateChange(viewValue);
  }

  protected _value: any;
  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    this._value = value;
  }

  constructor() {
    super();
  }

  ngOnInit(): void { }

}
