import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ISpinnerConfig } from './../models/spinner.interface';

@Component({
  selector: 'shared-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit {

  config: ISpinnerConfig;

  constructor() { }

  ngOnInit(): void { }

}
