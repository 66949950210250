import { Component, Input } from '@angular/core';

@Component({
  selector: 'bvl-without-results',
  templateUrl: './without-results.component.html',
  styleUrls: ['./without-results.component.css']
})
export class WithoutResultsComponent {

  protected _message: string;
  @Input()
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }

  constructor() { }

}
