import { Component, Input } from '@angular/core';

import { FormControlsBase, providerFormControlsBase } from '../form-controls.base';

@Component({
  selector: 'bvl-form-radio',
  templateUrl: './form-radio.component.html',
  styleUrls: ['./form-radio.component.scss'],
  providers: [providerFormControlsBase(BvlFormRadioComponent)]
})
export class BvlFormRadioComponent extends FormControlsBase {

  protected _value: any;
  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    this._value = value;
  }

  constructor() {
    super();
  }

}
