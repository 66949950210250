import { Pipe, PipeTransform } from '@angular/core';

import { IEntry, IEntryCategory } from '../entry';

@Pipe({
  name: 'matchesCategory'
})
export class MatchesCategoryPipe implements PipeTransform {
  transform(entries: Array<IEntry>, category: IEntryCategory): Array<IEntry> {
    return (category && category.id) ? entries.filter(entry => entry.category.id === category.id) : entries;
  }
}
