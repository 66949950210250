import { Component, ContentChild, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { coerceBooleanProp } from '@bvl-core/ui/common/helpers';
import { BvlAccordionContentComponent } from '../accordion-content/accordion-content.component';
import { BvlAccordionHeaderComponent } from '../accordion-header/accordion-header.component';

@Component({
  selector: 'bvl-accordion-group',
  templateUrl: './accordion-group.component.html'
})
export class BvlAccordionGroupComponent implements OnInit {

  @ContentChild(BvlAccordionHeaderComponent) accordionHeader: BvlAccordionHeaderComponent;
  @ContentChild(BvlAccordionContentComponent) accordionContent: BvlAccordionContentComponent;

  protected _active: boolean;
  @Input()
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = coerceBooleanProp(value);
    const actionType = (this._active)
                        ? 'addClass'
                        : 'removeClass';
    this._renderer2[actionType](this._elementRef.nativeElement, 'active');
  }

  constructor(
    private _elementRef: ElementRef,
    private _renderer2: Renderer2
  ) {
    this._renderer2.addClass(this._elementRef.nativeElement, 'g-admin-acordion-list-item');
  }

  ngOnInit(): void { }

}
