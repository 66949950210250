import { NgModule } from '@angular/core';

import { AfterDatePipe } from './after-date.pipe';
import { BeforeDatePipe } from './before-date.pipe';
import { GroupByPipe } from './group-by.pipe';
import { MatchesCategoryPipe } from './matches-category.pipe';
import { MatchesEntryTypePipe } from './matches-entry-type.pipe';
import { SafePipe } from './safe.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  declarations: [
    AfterDatePipe,
    BeforeDatePipe,
    GroupByPipe,
    MatchesCategoryPipe,
    MatchesEntryTypePipe,
    SafePipe,
    SlugifyPipe,
    TruncatePipe
  ],
  exports: [
    AfterDatePipe,
    BeforeDatePipe,
    GroupByPipe,
    MatchesCategoryPipe,
    MatchesEntryTypePipe,
    SafePipe,
    SlugifyPipe,
    TruncatePipe
  ]
})
export class PipesModule { }
