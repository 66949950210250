import { ComponentRef } from '@angular/core';
import { Subject } from 'rxjs';

import { ContentRef } from '../../../../helpers';
import { BUTTON_ACTIONS } from '../constants/modal.constants';
import { ModalComponent } from '../modal/modal.component';
import { IModalConfig } from './modal.interface';

export class ModalRef {
  private _resolve: (result?: any) => void;
  private _reject: (reason?: any) => void;

  /**
   * The instance of component used as modal's content.
   * Undefined when a TemplateRef is used as modal's content.
   */
  get componentInstance(): any {
    if (this._contentRef.componentRef) {
      return this._contentRef.componentRef.instance;
    }
  }

  /**
   * A Promise that is resolved when the modal is closed and rejected when the modal is dismissed.
   */
  result: Promise<any>;
  /**
   * A Observable that is resolved when the activeModal subscribe confirm().
   */
  confirmSubject: Subject<any>;

  constructor(
    private _modalCmptRef: ComponentRef<ModalComponent>,
    private _contentRef: ContentRef
  ) {
    /**
     * Subsribe output buttonActionEvent of modalComponent(modal/modal.component)
     */
    this.confirmSubject = new Subject<any>();
    this._modalCmptRef.instance.buttonActionEvent.subscribe((actionType: any) => {
      if (actionType === BUTTON_ACTIONS.acept) {
        this.confirmSubject.next(actionType);
      } else {
        this.dismiss(actionType);
      }
    });
    /**
     * Create the Promise to return the result of the button actions
     */
    this.result = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
    this.result.then(null, () => { });
  }

  /**
   * Closes the modal with an optional 'result' value.
   * The 'ModalRef.result' promise will be resolved with provided value.
   */
  close(result?: any): void {
    if (this._modalCmptRef) {
      this._resolve(result);
    }
  }

  /**
   * Dismisses the modal with an optional 'reason' value.
   * The 'ModalRef.result' promise will be rejected with provided value.
   */
  dismiss(reason?: any): void {
    if (this._modalCmptRef) {
      this._reject(reason);
    }
  }

  confirm(): Subject<any> {
    return this.confirmSubject;
  }

  /**
   * Remove and destroy the modalCmpRef
   * Destroy the contentRef
   */
  removeModalElements(): void {
    const modalNativeEl = this._modalCmptRef.location.nativeElement;
    modalNativeEl.parentNode.removeChild(modalNativeEl);
    this._modalCmptRef.destroy();

    if (this._contentRef && this._contentRef.viewRef) {
      this._contentRef.viewRef.destroy();
    }

    this._modalCmptRef = null;
    this._contentRef = null;
  }

  setConfig(config?: IModalConfig): void {
    if (this._modalCmptRef) {
      this._modalCmptRef.instance.config = config || {};
    }
  }

  setPayload(payload: any): void {
    if (this.componentInstance) {
      this.componentInstance.modalPayload = payload;
    }
  }
}
