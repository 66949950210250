export const addGoogleScript = (key: string) => {
  const scriptTag = document.createElement(`script`);
  scriptTag.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${key}`);
  scriptTag.setAttribute('type', 'text/javascript');
  document.body.appendChild(scriptTag);
};

export const addGAScript = (key: string) => {
  const scriptTagHead = document.createElement(`script`);
  const scriptTagBody = document.createElement(`noscript`);

  scriptTagHead.innerHTML = `
    (function(w,d,s,l,i){
      w[l] = w[l]||[];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event:'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0];
      var j = d.createElement(s);
      var dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${key}');
  `;

  scriptTagBody.innerHTML = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=${key}"
		height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;
  document.head.appendChild(scriptTagHead);
  document.body.appendChild(scriptTagBody);
};
