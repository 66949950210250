import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bvl-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ParagraphComponent {
  @Input() description: string;
  @Input() align = 'left';
}
