import { Injectable } from '@angular/core';
import { GeneralEndpoint } from '@bvl-admin/endpoints';
import { IStateResponse } from '@bvl-admin/statemanagement/models/general.interface';
import { ApiService } from '@bvl-core/shared/helpers/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    private _apiService: ApiService
  ) { }

  states(showSpin: boolean = false): Observable<Array<IStateResponse>> {
    return this._apiService.get(GeneralEndpoint.states, { preloader: showSpin });
  }

}
