import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bvl-paginator',
  templateUrl: './paginator.component.html'
})
export class BvlPaginatorComponent {

  protected _page: number | string;
  @Input()
  get page(): number | string {
    return this._page;
  }
  set page(value: number | string) {
    this._page = value;
  }

  protected _maxSize: number | string;
  @Input()
  get maxSize(): number | string {
    return this._maxSize;
  }
  set maxSize(value: number | string) {
    this._maxSize = value;
  }

  protected _pageSize: number | string;
  @Input()
  get pageSize(): number | string {
    return this._pageSize;
  }
  set pageSize(value: number | string) {
    this._pageSize = value;
  }

  protected _collectionSize: number | string;
  @Input()
  get collectionSize(): number | string {
    return this._collectionSize;
  }
  set collectionSize(value: number | string) {
    this._collectionSize = value;
  }

  @Output() pageChange: EventEmitter<number>;

  constructor() {
    this._page = 1;
    this._maxSize = 4;
    this._pageSize = 10;
    this.pageChange = new EventEmitter<number>(true);
  }

  oPageChange(event: any): void {
    this.pageChange.emit(event);
  }

}
