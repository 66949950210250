import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bvl-accordion-header',
  templateUrl: './accordion-header.component.html'
})
export class BvlAccordionHeaderComponent implements OnInit {

  @Output() oToggle: EventEmitter<boolean>;

  constructor() {
    this.oToggle = new EventEmitter<boolean>();
  }

  ngOnInit(): void { }

}
