import { ElementRef } from '@angular/core';

import { coerceBooleanProp } from '../helpers';
import { Constructor } from './constructor';

export interface ICanBorder {
  outline: boolean;
}

export interface IHasElementRef {
  _elementRef: ElementRef;
}

export type Bordered = boolean;

export const mixinBordered = <T extends Constructor<IHasElementRef>>(base: T): Constructor<ICanBorder> & T => {
  return class extends base {
    private _outline: any;

    get outline(): Bordered {
      return this._outline;
    }
    set outline(value: Bordered) {
      const outline = coerceBooleanProp(value);
      if (this._outline !== outline) {
        if (outline) {
          this._elementRef.nativeElement.classList.add('g-admin-btn-outline');
        } else {
          this._elementRef.nativeElement.classList.remove('g-admin-btn-outline');
        }

        this._outline = outline;
      }
    }

    constructor(...args: Array<any>) {
      super(...args);
    }
  };
};
