import { ISiteMenu } from './menu.interface';

export const getItemMenu = (item: ISiteMenu): ISiteMenu => {
  item.collapse = true;
  item.children = [];

  return item;
};

export const menuTree = (list: Array<ISiteMenu>): Array<ISiteMenu> => {
  return list.map(fv => {
    return getItemMenu(fv);
  })
  .reduce((previous, current) => {
    if (current.parentId) {
      const item = list.find(curr => curr.id === current.parentId);
      if (item) { item.children.push(current); }
    } else {
      previous.push(current);
    }

    return previous;
  }, []);
};
