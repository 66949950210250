import { Injectable } from '@angular/core';
import { ComponentEndpoint } from '@bvl-admin/endpoints';
import { TYPES_CODE } from '@bvl-admin/settings/constants/general.constant';
import {
  IComponentPaginationRequest,
  IComponentPaginationResponse,
  IComponentResponse,
  IComponentValueResponse,
  ISaveComponentValueRequest
} from '@bvl-admin/statemanagement/models/component.interface';
import { ApiService } from '@bvl-core/shared/helpers/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentService {

  private _componentsEditor: Array<IComponentResponse>;

  constructor(
    private _apiService: ApiService
  ) { }

  componentsPagination(params: IComponentPaginationRequest, showSpin: boolean = false): Observable<IComponentPaginationResponse> {
    return this._apiService.post(ComponentEndpoint.componentsPagination, params, { preloader: showSpin });
  }

  componentByCode(componentCode: string, showSpin: boolean = false): Observable<IComponentResponse> {
    const params = { componentCode };

    return this._apiService.get(ComponentEndpoint.componentByCode, { params, preloader: showSpin });
  }

  componentValue(type: number, componentCode: string, showSpin: boolean = false): Observable<IComponentValueResponse> {
    const params = { componentCode };

    return this._apiService.get((type === TYPES_CODE.common)
                                  ? ComponentEndpoint.componentValue
                                  : ComponentEndpoint.componentValueById, { params, preloader: showSpin });
  }

  componentListByType(type: number, showSpin: boolean = false): Observable<Array<IComponentResponse>> {
    return this._apiService.get(ComponentEndpoint.componentListByType, { params: { type }, preloader: showSpin });
  }

  saveComponentValue(type: number, params: ISaveComponentValueRequest, showSpin: boolean = false): Observable<IComponentValueResponse|any> {
    return this._apiService.post((type === TYPES_CODE.common)
                                  ? ComponentEndpoint.saveComponentValue
                                  : ComponentEndpoint.saveComponentValueById, params, { preloader: showSpin });
  }

  /** INFO: Mantener en sesión los componentes para el editor */
  setComponentsEditor(components: Array<IComponentResponse>): void {
    this._componentsEditor = components || [];
  }

  getComponentsEditor(): Array<IComponentResponse> {
    return this._componentsEditor || [];
  }

}
