export const LoginLang = {
  labels: {
    user: 'Usuario',
    password: 'Contraseña',
    rememberUser: 'Recordar usuario'
  },
  buttons: {
    enter: 'Entrar'
  },
  messages: {
    invalidUser: 'La contraseña y/o el usuario no coinciden.'
  }
};
