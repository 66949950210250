import { Component, Input } from '@angular/core';

@Component({
  selector: 'bvl-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BvlBreadcrumbComponent {

  @Input() list: Array<string>;

  constructor() { }

}
