import { Component, OnInit } from '@angular/core';
import { MenuService } from '@bvl-admin/services';
import { IMenu } from '@bvl-admin/statemanagement/models/general.interface';

@Component({
  selector: 'admin-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  menu: Array<IMenu>;

  constructor(
    private _menuService: MenuService
  ) { }

  ngOnInit(): void {
    this.menu = this._menuService.generateMenu();
  }

}
