import { Component, Input, OnInit } from '@angular/core';

import { coerceBooleanProp } from '../extra';

@Component({
  selector: 'bvl-tab',
  template: `<div class="tabs-content" [hidden]="!active">
              <ng-content></ng-content>
            </div>`
})
export class TabComponent implements OnInit {

  @Input() title: string;

  protected _active: boolean;
  @Input()
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = coerceBooleanProp(value);
  }

  constructor() {
    this.active = false;
  }

  ngOnInit(): void { }

}
