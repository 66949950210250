import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { WithoutResultsComponent } from './without-results/without-results.component';

@Directive({
  selector: '[withoutResults]'
})
export class WithoutResultsDirective implements OnDestroy, OnChanges {

  @Input() withoutResults: boolean;
  @Input() worMessage: string;

  private _componentPortal: ComponentPortal<WithoutResultsComponent>;
  private _domPortalHost: DomPortalHost;

  constructor(
    private _elemenRef: ElementRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _applicationRef: ApplicationRef,
    private _injector: Injector
  ) {
    // Create ComponentPortal that can be attached to a DomPortalHost
    this._componentPortal = new ComponentPortal(WithoutResultsComponent);
    // Create a DomPortalHost with _elemenRef.nativeElement as its anchor element
    this._domPortalHost = new DomPortalHost(
      this._elemenRef.nativeElement,
      this._componentFactoryResolver,
      this._applicationRef,
      this._injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const action = (this.withoutResults)
                      ? 'attach'
                      : 'detach';
    // Attach/Detach the ComponentPortal to the DomPortalHost.
    const componentPortal = this._domPortalHost[action](this._componentPortal);
    if (componentPortal) {
      componentPortal.instance.message = (this.worMessage)
                                                ? this.worMessage
                                                : 'No se encontraron resultados.';
      componentPortal.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this._domPortalHost.detach();
  }

}
