import { ElementRef } from '@angular/core';

import { Constructor } from './constructor';

export interface ICanTheme {
  theme: ThemePalette;
}

export interface IHasElementRef {
  _elementRef: ElementRef;
}

export type ThemePalette = 'primary' | 'outline' | undefined;
export type ColorPalette = 'primary' | undefined;

export const mixinTheme = <T extends Constructor<IHasElementRef>>(
  base: T,
  defaultTheme?: ThemePalette
): Constructor<ICanTheme> & T => {

  return class extends base {
    private _theme: ThemePalette;

    get theme(): ThemePalette { return this._theme; }
    set theme(value: ThemePalette) {
      const themePalette = value || defaultTheme;

      if (themePalette !== this._theme) {

        if (this._theme) {
          this._elementRef.nativeElement.classList.remove(`g-admin-btn-${this._theme}`);
        }
        if (themePalette) {
          this._elementRef.nativeElement.classList.add(`g-admin-btn-${themePalette}`);
        }

        this._theme = themePalette;
      }
    }

    private _color: ColorPalette;

    get color(): ColorPalette { return this._color; }
    set color(value: ColorPalette) {
      if (value) {
        this._elementRef.nativeElement.classList.add(`g-admin-btn-${this._theme}-${value}`);
        this._color = value;
      }
    }

    constructor(...args: Array<any>) {
      super(...args);

      this.theme = defaultTheme;
    }
  };
};
