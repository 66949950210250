import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { coerceBooleanProp } from '@bvl-core/ui/common/helpers';

@Component({
  selector: 'bvl-tab',
  template: `<div [hidden]="!active">
              <ng-content></ng-content>
            </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvlTabComponent implements OnInit {

  @Input() header: string;

  protected _value: any;
  @Input()
  get value(): any {
    return this._value || this.header;
  }
  set value(value: any) {
    this._value = value;
  }

  protected _active: boolean;
  @Input()
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = coerceBooleanProp(value);
    this._changeDetectorRef.detectChanges();
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.header = '';
    this._value = '';
    this._active = false;
  }

  ngOnInit(): void { }

}
