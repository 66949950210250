export interface IScriptModel {
  src: string;
  type: SCRIPT_TYPE;
  attributes?: { [key: string]: any };
}

export enum SCRIPT_TYPE {
  JS = 'js',
  CSS = 'css'
}
