import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NOTIFICATION_TYPES } from './notification-types.constants';
import { AlertNotify, ErrorNotify, INotify, MessageNotify, SuccessNotify, WarningNotify } from './notification.interface';

@Injectable()
export class NotificationService {

  private _notifies: Array<INotify>;
  notifiesBehaviorSubject: BehaviorSubject<Array<INotify>>;

  /**
   * Inicializador del servicios de Notificaciones
   */
  constructor() {
    this._notifies = new Array<AlertNotify>();
    this.notifiesBehaviorSubject = new BehaviorSubject<Array<AlertNotify>>(this._notifies);
  }

  /**
   * Agregar una notificación de Éxito
   * @param message Descripción de notificación de Éxito
   */
  addSuccess(message: string): void {
    this._add(new SuccessNotify(message));
  }

  /**
   * Agregar una notificación de Alerta
   * @param message Descripción de notificación de Alerta
   */
  addAlert(message: string): void {
    this._add(new AlertNotify(message));
  }

  /**
   * Agreagr una notificación tipo Mensaje
   * @param message Descripción de notificación tipo Mensaje
   */
  addMessage(message: string): void {
    this._add(new MessageNotify(message));
  }

  /**
   * Agregar una notificación tipo Advertencia
   * @param message Descripción de notificación tipo Advertencia
   */
  addWarning(message: string, data?: Object): void {
    this._add(new WarningNotify(message, data));
  }

  /**
   * Agregar una notificación de tipo Error
   * @param message Descripción de notificación tipo Error
   */
  addError(message: string, data?: Object): void {
    this._add(new ErrorNotify(message, data));
  }

  /**
   * Retorna todas las notificaciones
   */
  getNotifies(): BehaviorSubject<Array<INotify>> {
    return this.notifiesBehaviorSubject;
  }

  /**
   * Retorna todas las notificaciones tipo Alerta
   */
  getAlerts(): Array<AlertNotify> {
    return this._notifies.filter((n: INotify) => n.type === NOTIFICATION_TYPES.alert);
  }

  /**
   * Retorna la última notificación de Alerta
   */
  getLastAlert(): ErrorNotify {
    const errors = this.getAlerts();

    return errors[errors.length - 1];
  }

  /**
   * Retorna todas las notificaciones tipo Mensaje
   */
  getMessages(): Array<MessageNotify> {
    return this._notifies.filter((n: INotify) => n.type === NOTIFICATION_TYPES.message);
  }

  /**
   * Retorna todas las notificaciones tipo Advertencia
   */
  getWarnings(): Array<WarningNotify> {
    return this._notifies.filter((n: INotify) => n.type === NOTIFICATION_TYPES.warning);
  }

  /**
   * Retorna todas las notificaciones tipo Error
   */
  getErrors(): Array<ErrorNotify> {
    return this._notifies.filter((n: INotify) => n.type === NOTIFICATION_TYPES.error);
  }

  /**
   * Retorna la última notificación de Error
   */
  getLastError(): ErrorNotify {
    const errors = this.getErrors();

    return errors[errors.length - 1];
  }

  /**
   * Agrega un mensaje de advertencia por no tener permiso para realizar alguna acción
   */
  notPermission(): void {
    this.addWarning('You do not have permission to perform this action');
  }

  private _add(notify: INotify): void {
    this._notifies = [...this._notifies, notify];
    this.getNotifies()
      .next(this._notifies);
  }
}
