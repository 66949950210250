import { Injectable } from '@angular/core';
import { EventGoogleAnalytics } from '../../models/seo-ga.model';

declare let dataLayer: any;

// tslint:disable-next-line:only-arrow-functions
function _dataLayer(): any {
  return dataLayer || [];
}

@Injectable()
export class GAService {

  constructor() {

  }

  add(data: EventGoogleAnalytics): void {
    _dataLayer()
      .push(data);
  }

}
