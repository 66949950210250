import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { MenuService } from '@bvl-admin/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(
    private _menuService: MenuService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const menu = this._menuService.getMenu(),
          menuCode = next.data.menuCode;

    const permission = menu.find((fv, fk) => {
      return fv.code === menuCode;
    });

    return !!permission;
  }

}
