module.exports = {
  prod: {
    code: "prod",
    production: true,
    API_URL: "https://loadbalancer.bvl.com.pe",
    API_URL_2: "https://dataondemand.bvl.com.pe",
    WC_BUNDLE_PATH: "assets/web-components",
    CULQI_PUBLIC_KEY: "pk_live_wj0R0TJga6NGsUdN",
    AWS_AMPLIFY_CONFIG: {
      aws_project_region: "us-east-1",
      aws_cognito_identity_pool_id:
        "us-east-1:1fa2d157-8523-464e-9c39-0677669baf90",
      aws_cognito_region: "us-east-1",
      aws_user_pools_id: "us-east-1_6vXIBar5n",
      aws_user_pools_web_client_id: "3s2d949kitbet7tjm6tmaknc08",
      oauth: {},
    },
    RECAPTCHA_KEY: "6LcqWMcUAAAAAL-4TUqqi06t-A6wTZVi__AZVaGg",
  },
  dev: {
    code: "dev",
    production: false,
    API_URL: "https://loadbalancer.bvl-develop.com",
    API_URL_2: "https://dataondemand.bvl-develop.com",
    WC_BUNDLE_PATH: "assets/web-components/dev",
    CULQI_PUBLIC_KEY: "pk_test_kI7Rs2cG8iJhI3gl",
    AWS_AMPLIFY_CONFIG: {
      aws_project_region: "us-east-1",
      aws_cognito_identity_pool_id:
        "us-east-1:8e5e3a8b-cc3f-4c99-ba1e-87b7864cf10f",
      aws_cognito_region: "us-east-1",
      aws_user_pools_id: "us-east-1_D44N71nwH",
      aws_user_pools_web_client_id: "182a0cpr4vvqeqpgc95fr815ev",                                     
      oauth: {},
    },
    RECAPTCHA_KEY: "6LcqWMcUAAAAAL-4TUqqi06t-A6wTZVi__AZVaGg",
  },
  qa: {
    code: "qa",
    production: false,
    API_URL: "https://loadbalancer.bvl-release.com",
    API_URL_2: "https://dataondemand.bvl-release.com",
    WC_BUNDLE_PATH: "assets/web-components/qa",
    CULQI_PUBLIC_KEY: "pk_test_YGpTKMjuUl6GNYGs",
    AWS_AMPLIFY_CONFIG: {
      aws_project_region: "us-east-1",
      aws_cognito_identity_pool_id:
        "us-east-1:a6c11a3f-3311-4a6a-8d20-a51bd6fe8a42",
      aws_cognito_region: "us-east-1",
      aws_user_pools_id: "us-east-1_2Rx16q6jQ",
      aws_user_pools_web_client_id: "1j4t7vqkgoar45lo6cav8hi7sm",
      oauth: {},
    },
    RECAPTCHA_KEY: "6LcqWMcUAAAAAL-4TUqqi06t-A6wTZVi__AZVaGg",
  },
};
