import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DomChangeDirectiveModule } from '../extra';
import { PipesModule } from '../pipes/public-api';
import { ToolTipComponent } from './tooltip.component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    DomChangeDirectiveModule
  ],
  declarations: [ToolTipComponent],
  exports: [ToolTipComponent]
})
export class ToolTipModule { }
