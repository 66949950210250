import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImgSrcsetModule } from '../directives';
import { TestimonyComponent } from './testimony.component';

@NgModule({
  imports: [
    CommonModule,
    ImgSrcsetModule
  ],
  declarations: [TestimonyComponent],
  exports: [TestimonyComponent]
})
export class TestimonyModule { }
