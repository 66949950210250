import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ToggleByPositionModule } from '../directives';
import { DomChangeDirectiveModule } from '../extra';
import { BvlFormDatepickerModule } from '../form-controls/form-datepicker';
import { PipesModule } from '../pipes/pipes.module';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    ToggleByPositionModule,
    BvlFormDatepickerModule,
    DomChangeDirectiveModule
  ],
  providers: [DatePipe],
  declarations: [ToolbarComponent],
  exports: [ToolbarComponent]
})
export class ToolbarModule { }
