import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControlsBase, providerFormControlsBase } from '@bvl-core/ui/common';

@Component({
  selector: 'bvl-input',
  templateUrl: './input.component.html',
  providers: [providerFormControlsBase(BvlInputComponent)]
})
export class BvlInputComponent extends FormControlsBase implements OnInit {

  protected _type: string;
  @Input()
  get type(): string {
    return this._type;
  }
  set type(type: string) {
    this._type = (type || 'text').toLowerCase();
    this.isPasswordType = this._isType('password');
    this.isSearchType = this._isType('search');
    if (this.isSearchType) { this._type = 'text'; }
  }

  protected _accept;
  @Input()
  get accept(): string {
    return this._accept;
  }
  set accept(accept: string) {
    this._accept = accept || null;
  }

  protected _multipled;
  @Input()
  get multipled(): boolean {
    return this._multipled;
  }
  set multipled(multipled: boolean) {
    this._multipled = multipled;
  }

  protected _pattern;
  @Input()
  get pattern(): string {
    return this._pattern;
  }
  set pattern(pattern: string) {
    this._pattern = pattern;
  }

  protected _maxlength;
  @Input()
  get maxlength(): number {
    return this._maxlength;
  }
  set maxlength(maxlength: number) {
    this._maxlength = maxlength;
  }

  @Output() onchange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onfocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onblur: EventEmitter<any> = new EventEmitter<any>();

  isPasswordType: boolean;
  viewPassword: boolean;
  isSearchType: boolean;

  constructor() {
    super();
    this._type = 'text';
    this.isPasswordType = false;
    this.viewPassword = false;
    this.isSearchType = false;
  }

  ngOnInit(): void { }

  private _isType(type: string): boolean {
    return type === this._type;
  }

  togglePasswordType(): void {
    this.viewPassword = this._isType('password');
    this._type = (this.viewPassword)
                  ? 'text'
                  : 'password';
  }

  onChangeInput(event: any): void {
    this.onchange.emit(event);
  }

  onFocusInput(event: any): void {
    this.onfocus.emit(event);
  }

  onBlurInput(event: any): void {
    this.onblur.emit(event);
  }

}
