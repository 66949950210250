import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { STORAGE_KEY, STORAGE_TYPE, StorageService } from '../extra';
import { IUserProfile } from './profile.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  userProfileBehaviorSubject: BehaviorSubject<IUserProfile>;

  constructor(
    private _storageService: StorageService
  ) {
    this._storageService.setConfig({ storageType: STORAGE_TYPE.local });
    this.userProfileBehaviorSubject = new BehaviorSubject<IUserProfile>(null);
  }

  setUserProfile(userProfile: IUserProfile): void {
    this._storageService.setItem(STORAGE_KEY.userProfile, userProfile);
    this.userProfileBehaviorSubject.next(userProfile);
  }

  getUserProfile(): IUserProfile {
    return this._storageService.getItemObject(STORAGE_KEY.userProfile);
  }

}
